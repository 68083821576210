import { Helmet } from 'react-helmet-async';
import './orgDir.css'
import { useEffect, useState } from 'react';
// @mui
import './myOrg.css'
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'

// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createOrgDir, deleteOrgDir, getOrgDirs, recoverOrgDir, updateOrgDir, clearError, getMyOrg } from '../../redux/actions/orgDirAction';
import { CREATE_ORG_DIR_RESET } from '../../redux/constants/orgDirConstant';
import { allUsers } from '../../redux/actions/userAction';
// import { round } from 'lodash';


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function OrgDir() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();




    const { loading: getmemLoading, org: orgs, error: getErr } = useSelector((state) => state.getMyOrg)
    const { user } = useSelector((state) => state.users)

    useEffect(() => {
        dispatch(getMyOrg(userId))
    }, [dispatch, userId])

    useEffect(() => {
        if (getErr) {
            toast.warning(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])






    // -----------------------------new Member Modal Code --------------------------------------


    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.createOrgDir)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            dispatch({ type: CREATE_ORG_DIR_RESET });
            dispatch(getOrgDirs(keyword, page))
            
            if(userId){
                dispatch(getMyOrg(userId))
            }
            setUpdateOwnerPic(null)
            setUpdateOrgLogo(null)
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [orgName, setOrgName] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [orgWebsite, setOrgWebsite] = useState('');
    const [orgCountry, setorgCountry] = useState('');
    const [products, setProducts] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [orgDescription, setOrgDescription] = useState('');

    
    const [telephone, setTelephone] = useState('');
    const [GSTIN, setGSTIN] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [status, setStatus] = useState('');

    const [orgLogoFile, setOrgLogo] = useState('');
    const [orgLogoFilePreview, setOrgLogoFilePreview] = useState('');
    // owner details  /  Contact Person
    const [ownerPic, setOwnerPic] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerPhone, setOwnerPhone] = useState('');
    const [LinkedIn, setLinkedIn] = useState('');
    const handleOwnerPicChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setOwnerPic(e.target.result);

                }
            } else {
                event.target.value = null;
                setOwnerPic(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setOwnerPic(null);
            alert("Please select an profile picture.");
        }

    };

    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newMemberSubmitHandler = (e) => {
        e.preventDefault()
        if (userId) {
            dispatch(createOrgDir({ userId, orgName, orgCountry, products, orgDescription, orgEmail, orgWebsite, orgLogoFile, businessType, ownerName, ownerEmail, ownerPhone, ownerPic, LinkedIn,telephone,GSTIN,membershipId,status }))
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setOrgLogo(e.target.result);
                    setOrgLogoFilePreview(e.target.result);

                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setOrgLogo(null);
                setOrgLogoFilePreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setOrgLogo(null);
            setOrgLogoFilePreview(null);
            alert("Please select an profile picture.");
        }

    };

    useEffect(() => {

        setUserId(user?._id)


    }, [user])


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();
    const [UpdateorgName, setUpdateOrgName] = useState('');
    const [UpdateorgEmail, setUpdateOrgEmail] = useState('');
    const [UpdateorgWebsite, setUpdateOrgWebsite] = useState('');
    const [UpdateorgCountry, setUpdateorgCountry] = useState('');
    const [Updateproducts, setUpdateProducts] = useState('');
    const [UpdatebusinessType, setUpdateBusinessType] = useState('');
    const [UpdateorgDescription, setUpdateOrgDescription] = useState('');
    // owner details /  Contact Person

    const [UpdateOwnerPic, setUpdateOwnerPic] = useState('');
    const [UpdateOwnerPicPreview, setUpdateOwnerPicPreview] = useState('');

    const [updatetelephone, setupdateTelephone] = useState('');
    const [updateGSTIN, setupdateGSTIN] = useState('');
    const [updatemembershipId, setupdateMembershipId] = useState('');
    const [updatestatus, setupdateStatus] = useState('');

    const [UpdateOwnerName, setUpdateOwnerName] = useState('');
    const [UpdateOwnerEmail, setUpdateOwnerEmail] = useState('');
    const [UpdateOwnerPhone, setUpdateOwnerPhone] = useState('');
    const [UpdateLinkedIn, setUpdateLinkedIn] = useState('');

    const [UpdateorgLogoFile, setUpdateOrgLogo] = useState('');
    const [updateOrgLogoPreview, setUpdateOrgLogoPreview] = useState('');

    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateOrgSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { orgName: UpdateorgName, orgCountry: UpdateorgCountry, orgDescription: UpdateorgDescription, orgEmail: UpdateorgEmail, orgLogoFile: UpdateorgLogoFile, orgWebsite: UpdateorgWebsite, businessType: UpdatebusinessType, products: Updateproducts, ownerName: UpdateOwnerName, ownerEmail: UpdateOwnerEmail, ownerPhone: UpdateOwnerPhone, ownerPic: UpdateOwnerPic, LinkedIn: UpdateLinkedIn , telephone:updatetelephone, status:updatestatus,GSTIN:updateGSTIN, membershipId:updatemembershipId}
        dispatch(updateOrgDir(memberDetails, updateItem?._id))
    }

    const updateHandleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateOrgLogo(e.target.result);
                    setUpdateOrgLogoPreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateOrgLogo(null);
                setUpdateOrgLogoPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateOrgLogo(null);
            setUpdateOrgLogoPreview(null);
            alert("Please select an profile picture.");
        }

    };
    const updateHandleOwnerPicChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateOwnerPic(e.target.result);
                    setUpdateOwnerPicPreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateOwnerPic(null);
                setUpdateOwnerPicPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateOwnerPic(null);
            setUpdateOwnerPicPreview(null);
            alert("Please select an profile picture.");
        }

    };


    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateOrgName(item?.orgName)
        setUpdateOrgEmail(item?.orgEmail)
        setUpdateOrgWebsite(item?.orgWebsite)
        setUpdateProducts(item?.products)
        setUpdateorgCountry(item?.orgCountry)
        setUpdateBusinessType(item?.businessType)
        setUpdateOrgLogoPreview(item.orgLogo)

        setUpdateOrgDescription(item?.orgDescription)

        setupdateTelephone(item.telephone)
        setupdateMembershipId(item.membershipId)
        setupdateStatus(item.status)
        setupdateGSTIN(item.GSTIN)
        // setUpdateProfile(item.profileUrl)

        setUpdateOwnerName(item.ownerName)
        setUpdateOwnerPicPreview(item.ownerProfile)
        setUpdateOwnerPhone(item.ownerPhone)
        setUpdateOwnerEmail(item.ownerEmail)
        setUpdateLinkedIn(item.LinkedIn)
        // setUpdateProfile(item.profileUrl)

        updateMemberModalHandleOpen()
    }



    return (
        <>
            <Helmet>
                <title> My Organization - eoai </title>
            </Helmet>

            <Container>
                {
                    (!orgs && !getmemLoading) &&
                    <Card direction="row" alignItems="center" justifyContent="center"  >
                        <Stack direction="column" alignItems="center" justifyContent="center" p={10} spacing={1}>
                            <Typography variant="h6" color="initial"> Please Add your Organization Details</Typography>
                            <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                                Add Organization
                            </Button>
                        </Stack>
                    </Card>
                }

                <Card>
                    {
                        orgs &&
                        <>
                            <div className='tableTop'>
                                <div hidden={!getmemLoading ? 'true' : ''}>
                                    <CircularProgress color="primary" />
                                </div>
                                <div>My Organization Details</div>
                            </div>
                            <Divider />


                            <div className="orgBox">
                                <Stack direction={'row'} padding={6}>
                                    <div className="imgBoxLogoSide">
                                        {/* <img src={orgs?.orgLogo ? orgs?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" /> */}
                                        <img src={orgs?.ownerProfile ? orgs?.ownerProfile : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div>

                                    <div className="orgContentBox">
                                        <div>
                                            <img src={orgs?.orgLogo ? orgs?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} width={70} alt="Member" />

                                            <h5> {orgs?.orgName}</h5> <br />
                                        </div>
                                        <br />
                                        <div>
                                            <p>Membership ID  </p>
                                            <p> {orgs?.membershipId ? orgs?.membershipId : '---'}</p>

                                        </div>
                                        <div>
                                            <p>Organization Name</p>
                                            <p> {orgs?.orgName ? orgs?.orgName : '---'}</p>

                                        </div>
                                        <div>
                                            <p>Description  </p>
                                            <p> {orgs?.orgDescription ? orgs?.orgDescription : '---'}</p>

                                        </div>
                                        <div>
                                            <p>Nature of Business  </p>
                                            <p> {orgs?.businessType}</p>
                                        </div>
                                        <div>
                                            <p>Key Products  </p>
                                            <p> {orgs?.products}</p>
                                        </div>

                                        <div>
                                            <p> Location  </p>
                                            <p> {orgs?.orgCountry}</p>
                                        </div>

                                        <div>
                                            <p>Website  </p>
                                            <p> {orgs?.orgWebsite}</p>
                                        </div>
                                        <div>
                                            <p>Email  </p>
                                            <p> {orgs?.orgEmail}</p>
                                        </div>
                                        
                                        <div>
                                            <p>Contact  </p>
                                            <p> {orgs?.telephone ? orgs?.telephone : '---'}</p>

                                        </div>
                                        <div>
                                            <p>GSTIN  </p>
                                            <p> {orgs?.GSTIN ? orgs?.GSTIN : '---'}</p>

                                        </div>
                                        {/* <div>
                                            <p>Status  </p>
                                            <p> {orgs?.status ? orgs?.status : '---'}</p>

                                        </div> */}
                                        <br />
                                        <Typography color="initial"> Contact Person</Typography>

                                        <div>
                                            <p>Name  </p>
                                            <p> {orgs?.ownerName ? orgs?.ownerName : '---'}</p>

                                        </div>

                                        <div>
                                            <p>Email  </p>
                                            <p> {orgs?.ownerEmail ? orgs?.ownerEmail : '---'}</p>

                                        </div>
                                        <div>
                                            <p>Phone  </p>
                                            <p> {orgs?.ownerPhone ? orgs?.ownerPhone : '---'}</p>

                                        </div>
                                        <div>
                                            <p>LinkedIn  </p>
                                            <p> {orgs?.LinkedIn ? orgs?.LinkedIn : '---'}</p>

                                        </div>
                                    </div>
                                </Stack>

                                <div className="actionBox">
                                    {/* <p className='center'> Active  :  <div className={orgs?.deleteAt ? 'unlive' : 'live'}> </div></p> */}
                                    {/* <div className="dateBox">
                                        <p>Create : {orgs?.createAt}</p>
                                    </div> */}
                                    <div className="editBtn">

                                        {
                                            (<>
                                                <button onClick={e => editHandle(orgs)}>
                                                    <MdModeEdit />
                                                </button>
                                            </>)
                                        }


                                    </div>

                                </div>

                            </div>
                        </>

                    }
                </Card>
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                New Organization Directory
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={orgName}
                                    onChange={e => setOrgName(e.target.value)}
                                    required label="organization Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={orgEmail}
                                    onChange={e => setOrgEmail(e.target.value)}
                                     label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    value={orgCountry}
                                    onChange={e => setorgCountry(e.target.value)}
                                     label="Location" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={orgWebsite}
                                    onChange={e => setOrgWebsite(e.target.value)}
                                     label="Website" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="companyName"
                                    size='small'
                                    value={orgDescription}
                                    onChange={e => setOrgDescription(e.target.value)}
                                    label="Description" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={businessType}
                                    onChange={e => setBusinessType(e.target.value)}
                                     label="Nature of Business" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={products}
                                    onChange={e => setProducts(e.target.value)}
                                     label="Key Products" />
                            </Stack>
                             {/* optional */}
                             <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={telephone}
                                    onChange={e => setTelephone(e.target.value)}
                                    label="Contact" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={GSTIN}
                                    onChange={e => setGSTIN(e.target.value)}
                                    label="GSTIN" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={membershipId}
                                    onChange={e => setMembershipId(e.target.value)}
                                    label="Membership Id" />
                            </Stack>
                            {/* <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={status}
                                    onChange={e => setStatus(e.target.value)}
                                    label="Status" />
                            </Stack> */}

                            <p>Select Logo</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    required
                                    type="file"
                                    // accept=""
                                    onChange={handleFileChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    orgLogoFilePreview &&
                                    <img src={orgLogoFilePreview} width={100} height={65} alt="" />
                                }
                            </Stack>
                            <Divider sx={{ margin: '1vmax 0' }} />
                            <Typography color="initial"> Contact Person (Optional)</Typography>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={ownerName}
                                    onChange={e => setOwnerName(e.target.value)}
                                    label="Name" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={ownerEmail}
                                    onChange={e => setOwnerEmail(e.target.value)}
                                    label=" Email" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    type='number'
                                    value={ownerPhone}
                                    onChange={e => setOwnerPhone(e.target.value)}
                                    label=" Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={LinkedIn}
                                    onChange={e => setLinkedIn(e.target.value)}
                                    label="LinkedIn Url" />
                            </Stack>

                            <p>Select  Owner Profile </p>
                            <Stack spacing={3} direction={'row'} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"

                                    type="file"
                                    // accept=""
                                    onChange={handleOwnerPicChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    ownerPic &&
                                    <img src={ownerPic} width={100} style={{ borderRadius: '0.4rem' }} alt="" />
                                }
                            </Stack>


                            {/* <Stack spacing={3} margin='1vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-member'}>Board Member</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update  Organization Directory
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateOrgSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={UpdateorgName}
                                    onChange={e => setUpdateOrgName(e.target.value)}
                                    required label="Organization Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    type='email'
                                    value={UpdateorgEmail}
                                    onChange={e => setUpdateOrgEmail(e.target.value)}
                                    label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    // type='number'
                                    value={UpdateorgCountry}
                                    onChange={e => setUpdateorgCountry(e.target.value)}
                                     label="Location" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={UpdateorgWebsite}
                                    onChange={e => setUpdateOrgWebsite(e.target.value)}
                                    label="Website" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name=""
                                    size='small'
                                    type=''
                                    value={UpdateorgDescription}
                                    onChange={e => setUpdateOrgDescription(e.target.value)}
                                    label="Description" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdatebusinessType}
                                    onChange={e => setUpdateBusinessType(e.target.value)}
                                    label="Nature of Business" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={Updateproducts}
                                    onChange={e => setUpdateProducts(e.target.value)}
                                     label="Key Products" />
                            </Stack>

 {/* optional */}
 <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatetelephone}
                                    onChange={e => setupdateTelephone(e.target.value)}
                                    label="Contact" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updateGSTIN}
                                    onChange={e => setupdateGSTIN(e.target.value)}
                                    label="GSTIN" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatemembershipId}
                                    onChange={e => setupdateMembershipId(e.target.value)}
                                    label="Membership Id" />
                            </Stack>
                            {/* <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatestatus}
                                    onChange={e => setupdateStatus(e.target.value)}
                                    label="Status" />
                            </Stack> */}



                            <p>Select Logo</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    // required
                                    type="file"
                                    // accept=""
                                    onChange={updateHandleFileChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    updateOrgLogoPreview &&
                                    <img src={updateOrgLogoPreview} width={100} height={65} alt="" />
                                }
                            </Stack>
                            <Typography color="initial"> Contact Person</Typography>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdateOwnerName}
                                    onChange={e => setUpdateOwnerName(e.target.value)}
                                    label="Name" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdateOwnerEmail}
                                    onChange={e => setUpdateOwnerEmail(e.target.value)}
                                    label=" Email" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    type='number'
                                    value={UpdateOwnerPhone}
                                    onChange={e => setUpdateOwnerPhone(e.target.value)}
                                    label=" Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    // type='h'
                                    value={UpdateLinkedIn}
                                    onChange={e => setUpdateLinkedIn(e.target.value)}
                                    label="LinkedIn Url" />
                            </Stack>

                            <p>Select  Owner profile pic</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    type="file"
                                    // accept=""
                                    onChange={updateHandleOwnerPicChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    UpdateOwnerPicPreview &&
                                    <img src={UpdateOwnerPicPreview} width={100} height={65} alt="" />
                                }
                            </Stack>


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    Update
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>





        </>
    );
}
