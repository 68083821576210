import { Helmet } from 'react-helmet-async';
// import './ExeCommitee.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
 
    TextField,
    
    Grid,
    CircularProgress,
    Divider,
    


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppWidget } from '../sections/@dashboard/app';


import { getExeMembers, clearError } from '../redux/actions/exeCommitteeAction';
import {getDate} from '../utils/getDate'

// mock


// ----------------------------------------------------------------------




export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExeMembers(keyword, page))
    }, [dispatch, page])

    const { loading: getmemLoading, members, totalBoardMembers, totalMember, totalSplInviteeMembers, totalZonalMembers, error: getErr } = useSelector((state) => state.getExeCommittiee)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getExeMembers(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

   



 
    return (
        <>
            <Helmet>
                <title> Executive Committee - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Board Member" total={totalBoardMembers === 0 ? '0' : totalBoardMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Member" total={totalMember === 0 ? '0' : totalMember} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Special Invitee" total={totalSplInviteeMembers === 0 ? '0' : totalSplInviteeMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Zonal" total={totalZonalMembers === 0 ? '0' : totalZonalMembers} color="info" icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* <Link to={'/user/new'}> */ }
                    {/* <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Member
                    </Button> */}
                    {/* </Link> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Executive Committee</div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            members && members.map((item) => (
                                <div className="member-Box">
                                    <div className="imgBoxl">
                                        <img src={item.profileUrl ? item.profileUrl : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div>

                                    <div className="namePositionBoxx">
                                        <h5><b>Name </b> : {item?.name}</h5>
                                        <p><b>Position </b> : {item?.position}</p>
                                        <h5><b>Company </b> : {item?.companyName}</h5>
                                        {item?.address ? <p><b>Address </b> : {item?.address}</p> : ''}
                                        <p><b>Email </b> : {item?.email}</p>
                                        <p><b>Phone </b> : {item?.phone}</p>
                                    </div>

                                    <div className="actionBox">
                                        <div className="dateBox">
                                            <p>Create : {getDate(item?.createAt)}</p>
                                        </div>
                                        {/* <Stack direction={'row'} alignItems={'center'} spacing={2} margin={'0vmax 0'} > */}
                                            {/* <p className='center'>Active on website
                                              <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p>  */}
                                        {/* </Stack> */}
                                        {/* <div className="icon-Box">

                                            {
                                                item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                                                    <MdSettingsBackupRestore />
                                                </button>) : (<>
                                                    <button onClick={e => editHandle(item)}>
                                                        <MdModeEdit />
                                                    </button>
                                                    <button onClick={e => deleteHandle(item)}>
                                                    <MdDelete />

                                                    </button>
                                                </>)
                                            }


                                        </div> */}

                                    </div>

                                </div>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </Card>
            </Container>





        </>
    );
}
