import { Helmet } from 'react-helmet-async';
import './UserNew.css'
// @mui
import { styled } from '@mui/material/styles';
import {  Container, Typography, Divider, Stack, Button, IconButton, InputAdornment, TextField, CircularProgress, Box, MenuItem, Select, OutlinedInput, useTheme, InputLabel, FormControl } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';


import { IoIosArrowBack } from 'react-icons/io';
import useResponsive from '../../hooks/useResponsive';

// components
import { clearError, createUser, loadUser, login } from '../../redux/actions/userAction';
import logo from '../../images/icons/logo.png'
import inviteUserImg from '../../images/image/invite-user.avif'

import { CREATE_USER_RESET } from '../../redux/constants/userConstant';
// import { setDate } from 'date-fns';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');


    // ----------------------------------------------------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setemail] = useState(null)
    const [expiredDate, setexpiredDate] = useState(null)
    const [membershipStatus, setmembershipStatus] = useState(null)

    const { loading, success, error } = useSelector((state) => state.createUser)

    useEffect(() => {
        if (success) {
            toast.success('invite Send successfully');
            navigate(-1);
            dispatch({ type: CREATE_USER_RESET });
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [success, error, navigate, dispatch])


    const inviteUserSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createUser({ email, role, membershipStatus, expiredDate }))
    }
    const roles = [
        'admin',
        'member',
        'non-member'
    ]
    const [role, setRole] = useState('')

    const handleChange = (event) => {
        setRole(event.target.value);
    };


    return (
        <>
            <Helmet>
                <title> Invite New User  | EOAI DASHBOARD </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    <Link  to={-1} className='BackBtn'><IoIosArrowBack /></Link>
                </div>
                {mdUp && (
                    <StyledSection>

                        <Typography  variant="h3" sx={{ px: 5, mt: 10, mb: 5, textAlign: 'center',display:'flex' , gap:'2rem',alignItems:'center' }}>
                            <img src={logo} width={40} alt="EOAI LOGO" />
                            Welcome New User
                        </Typography>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Invite New User to EOAI Dashboard
                        </Typography>



                        {/* <Divider sx={{ my: 4 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider> */}

                        {/* new user Form  */}
                        <>
                            <form action="" onSubmit={inviteUserSubmitHandler}>
                                <Stack spacing={3} paddingY={4}>

                                    <TextField name="email"
                                        onChange={e => setemail(e.target.value)}
                                        required label="Email address" />
                                    <FormControl>
                                        <InputLabel id="demo-controlled-open-select">Select Role</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            value={role}
                                            label="Role      space"
                                            placeholder='None'
                                            onChange={handleChange}
                                        // color='success'

                                        >
                                            <MenuItem
                                                // key={role}
                                                value={''}
                                                selected
                                                color='success'
                                            >
                                                <em>None</em>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem
                                                    // key={role}
                                                    value={role}
                                                >
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel id="demo-controlled-open-select">Membership Status</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            value={membershipStatus}
                                            label="Ro space space space"
                                            placeholder='None'
                                            onChange={e => setmembershipStatus(e.target.value)}
                                        // color='success'

                                        >
                                            <MenuItem key={role} value={''} selected><em>None</em></MenuItem>
                                            <MenuItem key={role} value={'1'} selected><em>1 Year</em></MenuItem>
                                            <MenuItem key={role} value={'0'} selected><em>Lifetime</em></MenuItem>

                                        </Select>
                                    </FormControl>
                                    <Stack spacing={1}>
                                        <InputLabel id="demo-controlled-open-select">Membership Expired </InputLabel>
                                        <TextField name="date"
                                            type='date'
                                            onChange={e => setexpiredDate(e.target.value)}
                                        />
                                    </Stack>

                                </Stack>

                                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                    <Checkbox name="remember"label="Remember me" /> 
                                    <Link variant="subtitle2" underline="hover">
                                        Forgot password?
                                    </Link>
                                </Stack> */}

                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        Send Invite
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
