import { Helmet } from 'react-helmet-async';
// import './orgDir.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,

    Container,

    TextField,

    Grid,
    CircularProgress,
    Divider,



} from '@mui/material';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaEye } from 'react-icons/fa';

import Pagination from 'react-js-pagination';
// components
import { AppWidget } from '../sections/@dashboard/app';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { getOrgDirs, clearError } from '../redux/actions/orgDirAction';
import { getDate } from '../utils/getDate';


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function OrgDir() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrgDirs(keyword, page))
    }, [dispatch, page])

    const { loading: getmemLoading, orgs, totalOrgs, resultPerPage, error: getErr } = useSelector((state) => state.getOrgDir)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getOrgDirs(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };





    return (
        <>
            <Helmet>
                <title> Organization Directory - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Organization" total={totalOrgs} icon={'ph:users-fill'} />
                        </Grid>
                    </div>


                    {/* </Link> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Organization Directory</div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => {setKeyword(e.target.value);setPage(1)}} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            orgs && orgs.map((item) => (
                                <Link to={`/dashboard/organization/${item._id}`} style={{ textDecoration: 'none' }}>
                                    <div className="member-Box">
                                        <div className="imgBoxLogo">
                                            <img src={item.orgLogo ? item?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                        </div>

                                        <div className="namePositionBoxx">
                                            <h5>{item?.orgName}</h5>
                                            {/* <p>{item?.orgEmail}</p> */}
                                            <h5>{item?.orgCountry}</h5>
                                            {/* <p>{item?.orgWebsite}</p>
                                        <p>{item?.businessType}</p>
                                        <p>{item?.orgDescription}</p>
                                        <p>{item?.products}</p> */}
                                        </div>

                                        <div className="actionBox">
                                            {/* <p className='center'> Active on website :  <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p> */}
                                            <div className="dateBox">
                                                <p>Create : {getDate(item?.createAt)}</p>
                                            </div>
                                            <div className="icon-Box">

                                                <Link to={`/dashboard/organization/${item._id}`}>
                                                    <button >
                                                        <FaEye />
                                                    </button>
                                                </Link>

                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            ))
                        }


                    </div>
                    <div className='paginationBox' >
                        {
                            totalOrgs > resultPerPage &&
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={totalOrgs}
                                pageRangeDisplayed={5}
                                onChange={e => setPage(e)}
                            />
                        }
                    </div>
                </Card>
            </Container>


        </>
    );
}
