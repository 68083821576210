import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import { profiletReducers, usertReducers, forgotPasswordReducers, allUsersReducers, userDetailsReducers, createUserReducer, allUserReducers, bulkImportReducers, memberRegistrationReducers, memberEmailVerifyReducers, renewReducers } from './reducers/userReducer'
import {  getDashboard } from './reducers/fileReducer';
import { createExeCommattiee, getExeCommattiee } from './reducers/exeCommitteeReducer';
import { createOrgDir, getOrgDir ,getMyOrg} from './reducers/orgDirReducer';
import { getEvent,createEvent } from './reducers/eventsReducer';
import { createGallery, getImages,getGallerys } from './reducers/galleryReducer';

import { createBooking,getBookings } from './reducers/bookingReducer';
import { createNews,getNews } from './reducers/newsReducer';
import { createNewsLetter,createNewsMail,getNewsLetter } from './reducers/newsLetterReducer';
import { createContactsReducers, getContactsReducers } from './reducers/contactReduces';
import { getDashboardUserReducers, getDashboardWebReducers } from './reducers/dashboardReducers';
import { bookingPaymentReducers, getPaymentReducers } from './reducers/paymentReducer';
import { createWorkshop, getWorkshop, getWorkshopBooking } from './reducers/workshopReducer';


const reducer = combineReducers({
   profile:profiletReducers,
   users:usertReducers,
   allUsers:allUsersReducers,
   forgetPassword:forgotPasswordReducers,
   createUser: createUserReducer,
   getUser:userDetailsReducers,
   allUsersForExc:allUserReducers,
   // -----------------
   bulk:bulkImportReducers,
   registration:memberRegistrationReducers,
   emailVerify:memberEmailVerifyReducers,
   renew:renewReducers,


   dashboard: getDashboard,

//    Executive Committee
    actionExeCommittiee:createExeCommattiee,
    getExeCommittiee:getExeCommattiee,

    //    Executive Committee
    createOrgDir,
    getOrgDir,
    getMyOrg,

    // Events 
    actionEvents: createEvent,
    getEvents:getEvent,

     // workshop
     actionWorkshops: createWorkshop,
     getWorkshops:getWorkshop,
     getWorkshopBooking,

    
    // gallery 
    actionGallerys: createGallery,
    getGallerys,
    getImages,

    // booking 
    actionBookings: createBooking,
    getBookings,

     //  News
     createNews,
     getNews,

     //  News
     createNewsLetter,
     getNewsLetter,
     notification:createNewsMail,

    //  contact 
    contacts : getContactsReducers,
    contact : createContactsReducers,

    // dashboard 
    dashboardUser : getDashboardUserReducers,
    dashboardWeb : getDashboardWebReducers,

    // payment
       payment:getPaymentReducers ,
       paymentReq:bookingPaymentReducers 


});

const intitialeState = {
    
}; 

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
