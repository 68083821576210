import {

} from '../constants/bookingConstant'
import { CLEAR_ERROR, CREATE_QUERY_FAIL, CREATE_QUERY_REQUEST, CREATE_QUERY_RESET, CREATE_QUERY_SUCCESS, GET_CONTACTS_FAIL, GET_CONTACTS_REQUEST, GET_CONTACTS_SUCCESS, GET_TRACK_FAIL, GET_TRACK_REQUEST, GET_TRACK_SUCCESS } from '../constants/contactConstant';


export const getContactsReducers = (state = { data: {} }, action) => {
  switch (action.type) {

    case GET_CONTACTS_REQUEST:
    case GET_TRACK_REQUEST:
      return {
        loading: true,
      };

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload.contacts,
        resultPerPage: action.payload.resultPerPage,
        totalContacts: action.payload.totalContacts,

      };

    case GET_TRACK_SUCCESS:
      return {
        ...state,
        loading: false,
        tracks: action.payload.tracks,
        resultPerPage: action.payload.resultPerPage,
        totalTracks: action.payload.totalTracks,

      };

    case GET_CONTACTS_FAIL:
    case GET_TRACK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const createContactsReducers = (state = { data: {} }, action) => {
  switch (action.type) {

    case CREATE_QUERY_REQUEST:
      return {
        loading: true,
      };

    case CREATE_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,

      };

   

    case CREATE_QUERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      
    case CREATE_QUERY_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};