import React, { useEffect } from 'react'
import './eventBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { FaDownload } from 'react-icons/fa';

import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMyBooking } from '../redux/actions/bookingActions';
import { getAllMemberNewsLetter } from '../redux/actions/newsLetterAction';

const MagazineList = () => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.users)

    const { loading: getLoading, newsLetters, error: getErr } = useSelector((state) => state.getNewsLetter)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr)
        }
        dispatch(getAllMemberNewsLetter())
    }, [dispatch])


    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>Newsletters </Typography>

            {
                getLoading ? 'Loading...' :
                    <div className='bookingCantainer'>
                        {
                            newsLetters && newsLetters.map((item) => (
                                <div className='magazineItem'>
                                    <Link to={`/dashboard/magazine/${item?._id}`} style={{ textDecoration: 'none' }}>
                                        <div>
                                            <Typography variant="h5" color={'secondary'} sx={{ mb: 1 }}>{item?.heading}</Typography>
                                            <Typography color={''} sx={{ mb: 1 }}>{item?.fileName}</Typography>

                                        </div>
                                    </Link>

                                    <a download rel='noreferrer' target='_blank' href={item?.pdfUrl}>
                                        <Button size='large'><FaDownload size={23}/></Button>
                                    </a>




                                </div>
                            ))
                        }
                    </div >
            }
        </>

    )
}

export default MagazineList