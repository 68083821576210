import axios from "axios";
import {  } from '../constants/bookingConstant'

import {host} from "../../utils/config";
import { CLEAR_ERROR, GET_DASHBOARD_USER_FAIL, GET_DASHBOARD_USER_REQUEST, GET_DASHBOARD_USER_SUCCESS, GET_DASHBOARD_WEB_FAIL, GET_DASHBOARD_WEB_REQUEST, GET_DASHBOARD_WEB_SUCCESS, } from "../constants/dashboardConstant";



export const getDashboardUserAction = () => async (dispatch) => {

    try {

        dispatch({ type: GET_DASHBOARD_USER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/user/api/v1/dashboard`,  config);

        dispatch({ type: GET_DASHBOARD_USER_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_DASHBOARD_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getDashboardWebAction = () => async (dispatch) => {

    try {

        dispatch({ type: GET_DASHBOARD_WEB_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/dashboard`,  config);

        dispatch({ type: GET_DASHBOARD_WEB_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_DASHBOARD_WEB_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};