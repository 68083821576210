import { Helmet } from 'react-helmet-async';
import './ExeCommitee.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'
// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { CREATE_EXE_COMMITTEE_RESET } from '../../redux/constants/exeCommitteeConstant';
import { createExeMember, deleteExeMember, getExeMembers, recoverExeMember, updateExeMember, clearError } from '../../redux/actions/exeCommitteeAction';
// import { round } from 'lodash';


// mock


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExeMembers(keyword, page))
    }, [dispatch, page])

    const { loading: getmemLoading, members, totalBoardMembers, totalMember, totalSplInviteeMembers, totalZonalMembers, error: getErr } = useSelector((state) => state.getExeCommittiee)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getExeMembers(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

    // -----------------------------new Member Modal Code --------------------------------------

    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.actionExeCommittiee)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            deleteMemberModalHandleClose()
            recoverMemberModalHandleClose()
            dispatch({ type: CREATE_EXE_COMMITTEE_RESET });
            dispatch(getExeMembers(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [flag, setFlag] = useState('');

    const [profile, setProfile] = useState('');
    const [profilePreview, setProfilePreview] = useState('');
    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newMemberSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createExeMember({ name, email, position, phone, flag, companyName, address, profile }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setProfile(e.target.result);
                    setProfilePreview(e.target.result);

                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setProfile(null);
                setProfilePreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setProfile(null);
            setProfilePreview(null);
            alert("Please select an profile picture.");
        }

    };


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();


    const [updateName, setUpdateName] = useState('');
    const [updateEmail, setUpdateEmail] = useState('');
    const [updatePosition, setUpdatePosition] = useState('');
    const [updateCompanyName, setUpdateCompanyName] = useState('');
    const [updateAddress, setUpdateAddress] = useState('');
    const [updatePhone, setUpdatePhone] = useState('');
    const [updateFlag, setUpdateFlag] = useState('');

    const [updateProfile, setUpdateProfile] = useState('');
    const [updateProfilePreview, setUpdateProfilePreview] = useState('');

    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateMemberSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { updateName, updateEmail, updatePosition, updatePhone, updateFlag, updateCompanyName, updateAddress, updateProfile }
        dispatch(updateExeMember(memberDetails, updateItem?._id))
    }

    const updateHandleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateProfile(e.target.result);
                    setUpdateProfilePreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateProfile(null);
                setUpdateProfilePreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateProfile(null);
            setUpdateProfilePreview(null);
            alert("Please select an profile picture.");
        }

    };

    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateName(item.name)
        setUpdateAddress(item.address)
        setUpdateCompanyName(item.companyName)
        setUpdateEmail(item.email)
        setUpdateFlag(item.flag)
        setUpdatePhone(item.phone)
        setUpdatePosition(item.position)
        // setUpdateProfile(item.profileUrl)

        updateMemberModalHandleOpen()
    }

    // delete memeber - modal code  ---------------------------
    const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);

    const deleteMemberModalHandleOpen = () => {
        setDeleteMemberModalOpen(true)
    };

    const deleteMemberModalHandleClose = () => {
        setDeleteMemberModalOpen(false);
    }

    const deleteSubmitHandler = () => {
        dispatch(deleteExeMember(updateItem?._id));
    }
    const deleteHandle = (item) => {
        setUpdateItem(item)
        deleteMemberModalHandleOpen()
    }
    // Recover member - modal code ---------------------------------
    const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);

    const recoverMemberModalHandleOpen = () => {
        setRecoverMemberModalOpen(true)
    };

    const recoverMemberModalHandleClose = () => {
        setRecoverMemberModalOpen(false);
    }

    const recoverSubmitHandler = () => {
        dispatch(recoverExeMember(updateItem?._id));
    }

    const recoverHandle = (item) => {
        setUpdateItem(item)
        recoverMemberModalHandleOpen()
    }

    
// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------

    return (
        <>
            <Helmet>
                <title> Executive Committee - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Board Member" total={totalBoardMembers === 0 ? '0' : totalBoardMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Member" total={totalMember === 0 ? '0' : totalMember} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Special Invitee" total={totalSplInviteeMembers === 0 ? '0' : totalSplInviteeMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Zonal" total={totalZonalMembers === 0 ? '0' : totalZonalMembers} color="info" icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* <Link to={'/user/new'}> */ console.log(totalZonalMembers)}
                    <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Member
                    </Button>
                    {/* </Link> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Executive Committee</div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            members && members.map((item) => (
                                <div className="member-Box">
                                    <div className="imgBoxl">
                                        <img src={item.profileUrl ? item.profileUrl : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div>

                                    <div className="namePositionBoxx">
                                        <h5><b>Name </b> : {item?.name}</h5>
                                        <p><b>Position </b> : {item?.position}</p>
                                        <h5><b>Company </b> : {item?.companyName}</h5>
                                        {item?.address ? <p><b>Address </b> : {item?.address}</p> : ''}
                                        <p><b>Email </b> : {item?.email}</p>
                                        <p><b>Phone </b> : {item?.phone}</p>
                                    </div>

                                    <div className="actionBox">
                                        <div className="dateBox">
                                            <p>Create : {item?.createAt}</p>
                                        </div>
                                        {/* <Stack direction={'row'} alignItems={'center'} spacing={2} margin={'0vmax 0'} > */}
                                            <p className='center'>Active on website
                                              <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p> 
                                        {/* </Stack> */}
                                        <div className="icon-Box">

                                            {
                                                item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                                                    <MdSettingsBackupRestore />
                                                </button>) : (<>
                                                    <button onClick={e => editHandle(item)}>
                                                        <MdModeEdit />
                                                    </button>
                                                    <button onClick={e => deleteHandle(item)}>
                                                    <MdDelete />

                                                    </button>
                                                </>)
                                            }


                                        </div>

                                    </div>

                                </div>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </Card>
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                New Executive Committee
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    required label="Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    required label="Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={position}
                                    onChange={e => setPosition(e.target.value)}
                                    required label="Position" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="companyName"
                                    size='small'
                                    value={companyName}
                                    onChange={e => setCompanyName(e.target.value)}
                                    required label="Company Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                 label="Address" />
                            </Stack>


                            <Stack spacing={3} margin='1vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-member'}>Board Member</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    type="file"
                                    // accept=""
                                    onChange={handleFileChange}
                                    name="profile"
                                    placeholder="Profile"
                                />
                            </Stack>

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update Executive Committee
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={updateName}
                                    onChange={e => setUpdateName(e.target.value)}
                                    required label="Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={updateEmail}
                                    onChange={e => setUpdateEmail(e.target.value)}
                                    required label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    value={updatePhone}
                                    onChange={e => setUpdatePhone(e.target.value)}
                                    required label="Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={updatePosition}
                                    onChange={e => setUpdatePosition(e.target.value)}
                                    required label="Position" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="companyName"
                                    size='small'
                                    value={updateCompanyName}
                                    onChange={e => setUpdateCompanyName(e.target.value)}
                                    required label="Company Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={updateAddress}
                                    onChange={e => setUpdateAddress(e.target.value)}
                                     label="Address" />
                            </Stack>


                            <Stack spacing={3} margin='1vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={updateFlag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setUpdateFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-member'}>Board Member</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"

                                    type="file"
                                    // accept=""
                                    onChange={updateHandleFileChange}
                                    name="profile"
                                    placeholder="Profile"
                                />
                            </Stack>

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    UPDATE
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>



            {/* delete Member  modal code  */}
            <div>
                <Modal
                    open={deleteMemberModalOpen}
                    onClose={deleteMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Delete Executive Committee
                            </Typography>
                            <Button onClick={deleteMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure delete the {updateItem?.name} ?</h3>

                            <div className='actionBtnBox'>
                                {createMemberLoading ? (
                                    <LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>
                                ) : (
                                    <Button variant={'contained'} onClick={deleteSubmitHandler} size='small' >
                                        yes
                                    </Button>
                                )
                                }
                                <Button variant={'outlined'} onClick={deleteMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>

            {/* Recover Member  modal code  */}
            <div>
                <Modal
                    open={recoverMemberModalOpen}
                    onClose={recoverMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Recover Executive Committee
                            </Typography>
                            <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure Recover the {updateItem?.name} ?</h3>

                            <div className='actionBtnBox'>
                                {
                                    createMemberLoading ? (
                                        <LoadingButton fullWidth size="large" variant="text" >
                                            <CircularProgress color='success' />
                                        </LoadingButton>
                                    ) : (
                                        <Button variant={'contained'} onClick={recoverSubmitHandler} size='small' >
                                            yes
                                        </Button>
                                    )
                                }
                                <Button variant={'outlined'} onClick={recoverMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>




        </>
    );
}
