
import { CLEAR_ERROR, GET_DASHBOARD_USER_FAIL, GET_DASHBOARD_USER_REQUEST, GET_DASHBOARD_USER_SUCCESS, GET_DASHBOARD_WEB_FAIL, GET_DASHBOARD_WEB_REQUEST, GET_DASHBOARD_WEB_SUCCESS } from '../constants/dashboardConstant';


export const getDashboardUserReducers = (state = { data: {} }, action) => {
    switch (action.type) {

        case GET_DASHBOARD_USER_REQUEST:
            return {
                loading: true,
            };

        case GET_DASHBOARD_USER_SUCCESS:

            return {
                ...state,
                loading: false,
                members: action.payload.members,
                admins: action.payload.admins,

            };


        case GET_DASHBOARD_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getDashboardWebReducers = (state = { data: {} }, action) => {
    switch (action.type) {

        case GET_DASHBOARD_WEB_REQUEST:
            return {
                loading: true,
            };

        case GET_DASHBOARD_WEB_SUCCESS:

            return {
                ...state,
                loading: false,
                totalOrgDir : action.payload.totalOrgDir,
                totalMagazine : action.payload.totalMagazine,
                totalGallery :action.payload.totalGallery,
                totalExeComm :action.payload.totalExeComm,
                totalNews : action.payload.totalNews,
            };


        case GET_DASHBOARD_WEB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};