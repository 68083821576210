import {
    CREATE_GALLERY_REQUEST,
    CREATE_GALLERY_SUCCESS,
    CREATE_GALLERY_FAIL,
    CREATE_GALLERY_RESET,
    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_FAIL,
    UPDATE_GALLERY_REQUEST,
    UPDATE_GALLERY_SUCCESS,
    UPDATE_GALLERY_FAIL,
    UPDATE_GALLERY_RESET,
    DELETE_GALLERY_REQUEST,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAIL,
    DELETE_GALLERY_RESET,
    RECOVER_GALLERY_REQUEST,
    RECOVER_GALLERY_SUCCESS,
    RECOVER_GALLERY_FAIL,
    RECOVER_GALLERY_RESET,
    CLEAR_ERROR,

    DELETE_IMAGE_RESET,
    DELETE_IMAGE_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    GET_IMAGES_REQUEST,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAIL,
} from '../constants/galleryConstant'


export const createGallery = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_GALLERY_REQUEST:
        case CREATE_GALLERY_REQUEST:
            case DELETE_GALLERY_REQUEST:
                case DELETE_IMAGE_REQUEST:
                case RECOVER_GALLERY_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_GALLERY_SUCCESS:
        case CREATE_GALLERY_SUCCESS:
        case DELETE_GALLERY_SUCCESS:
            case DELETE_IMAGE_SUCCESS:
            case RECOVER_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_GALLERY_FAIL:
        case CREATE_GALLERY_FAIL:
        case DELETE_GALLERY_FAIL:
            case DELETE_IMAGE_FAIL:
            case RECOVER_GALLERY_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_GALLERY_RESET:
        case CREATE_GALLERY_RESET:
            case RECOVER_GALLERY_RESET:
                case DELETE_GALLERY_RESET:
                    case DELETE_IMAGE_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getGallerys = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_GALLERY_REQUEST:
        return {
          loading: true,
        };
  
        case GET_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          gallerys: action.payload.gallerys,
          
          totalGallery: action.payload.totalGallery,
          
        };
  

        case GET_GALLERY_FAIL:
        return {
          ...state,
          loading: false,
          members: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getImages = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_IMAGES_REQUEST:
        return {
          loading: true,
        };
  
        case GET_IMAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          images: action.payload.images,
          
          totalImages: action.payload.totalImages,
          
        };
  

        case GET_IMAGES_FAIL:
        return {
          ...state,
          loading: false,
          images: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

