import axios from "axios";
import {
    CLEAR_ERROR,
    CREATE_WORKSHOP_BOOKING_FAIL,
    CREATE_WORKSHOP_BOOKING_REQUEST,
    CREATE_WORKSHOP_BOOKING_SUCCESS,
    CREATE_WORKSHOP_FAIL,
    CREATE_WORKSHOP_REQUEST,
    CREATE_WORKSHOP_SUCCESS,
    GET_WORKSHOPS_FAIL,
    GET_WORKSHOPS_PORTAL_FAIL,
    GET_WORKSHOPS_PORTAL_REQUEST,
    GET_WORKSHOPS_PORTAL_SUCCESS,
    GET_WORKSHOPS_REQUEST,
    GET_WORKSHOPS_SUCCESS,
    GET_WORKSHOP_BOOKINGS_FAIL,
    GET_WORKSHOP_BOOKINGS_REQUEST,
    GET_WORKSHOP_BOOKINGS_SUCCESS,
    GET_WORKSHOP_BOOKING_FAIL,
    GET_WORKSHOP_BOOKING_REQUEST,
    GET_WORKSHOP_BOOKING_SUCCESS,
    GET_WORKSHOP_FAIL,
    GET_WORKSHOP_MYBOOKINGS_FAIL,
    GET_WORKSHOP_MYBOOKINGS_REQUEST,
    GET_WORKSHOP_MYBOOKINGS_SUCCESS,
    GET_WORKSHOP_REQUEST,
    GET_WORKSHOP_SUCCESS,
    UPDATE_WORKSHOP_FAIL,
    UPDATE_WORKSHOP_REQUEST,
    UPDATE_WORKSHOP_SUCCESS
} from '../constants/workshopConstant';

import { host } from "../../utils/config";



export const createWorkshop = (details) => async (dispatch) => {

    try {
        // console.log("create Workshop")

        dispatch({ type: CREATE_WORKSHOP_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/events/api/v1/workshop/new`, details, config);

        dispatch({ type: CREATE_WORKSHOP_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_WORKSHOP_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getWorkshops = () => async (dispatch) => {
    try {
        dispatch({ type: GET_WORKSHOPS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/workshops`, config);

        dispatch({ type: GET_WORKSHOPS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_WORKSHOPS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getWorkshopsForPortal = () => async (dispatch) => {
    try {
        dispatch({ type: GET_WORKSHOPS_PORTAL_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/workshops-portal`, config);

        dispatch({ type: GET_WORKSHOPS_PORTAL_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_WORKSHOPS_PORTAL_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const getWorkshop = (id) => async (dispatch) => {
    // console.log(id)
    try {
        dispatch({ type: GET_WORKSHOP_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/workshop/${id}`, config);

        dispatch({ type: GET_WORKSHOP_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_WORKSHOP_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateWorkshops = (memberDetails,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_WORKSHOP_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/workshop/update/${id}`, memberDetails, config);

        dispatch({ type: UPDATE_WORKSHOP_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_WORKSHOP_FAIL,
            payload: error.response.data.message,
        });
    }
};

// booking -------------------
export const createBookingWorkshops = (memberDetails) => async (dispatch) => {
    try {

        dispatch({ type: CREATE_WORKSHOP_BOOKING_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.post(`${host}/events/api/v1/workshop/payment/request`, memberDetails, config);

        dispatch({ type: CREATE_WORKSHOP_BOOKING_SUCCESS, payload: data });


    } catch (error) {
        dispatch({
            type: CREATE_WORKSHOP_BOOKING_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getBookingWorkshop = (id,userId,role) => async (dispatch) => {
    // console.log(role)
    try {

        dispatch({ type: GET_WORKSHOP_BOOKING_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/workshop/booking/${id}?userId=${userId}&role=${role}`,  config);

        dispatch({ type: GET_WORKSHOP_BOOKING_SUCCESS, payload: data });


    } catch (error) {
        dispatch({
            type: GET_WORKSHOP_BOOKING_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getAllBookingWorkshop = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_WORKSHOP_BOOKINGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/workshop/bookings/${id}`,  config);

        // console.log(data)

        dispatch({ type: GET_WORKSHOP_BOOKINGS_SUCCESS, payload: data });



    } catch (error) {
        dispatch({
            type: GET_WORKSHOP_BOOKINGS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getMyBookingsWorkshop = (userId) => async (dispatch) => {
    try {

        dispatch({ type: GET_WORKSHOP_MYBOOKINGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/my-workshop/bookings/${userId}`,  config);

        dispatch({ type: GET_WORKSHOP_MYBOOKINGS_SUCCESS, payload: data });


    } catch (error) {
        dispatch({
            type: GET_WORKSHOP_MYBOOKINGS_FAIL,
            payload: error.response.data.message,
        });
    }
};







export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

