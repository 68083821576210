import React, { useEffect } from 'react'
// import './eventBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDateTime } from '../../utils/getDate';
import { getMyBookingsWorkshop } from '../../redux/actions/workshopAction';
import  ZeroState  from '../../components/ZeroState';

const MyBooking = () => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.users)

    const { loading: getLoading, bookings, error: getErr } = useSelector((state) => state.getWorkshopBooking)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr)
        }
        dispatch(getMyBookingsWorkshop(user?._id))
    }, [dispatch])


    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>My Workshop Bookings</Typography>

            

            {
                getLoading ? 'loading' :
                    <div className='bookingCantainer'>
                        {
                            bookings?.length === 0 ? <ZeroState title={"Workshop's Booking Not Found!"}/> :
                            <>
                            {
                            bookings && bookings.map((item) => (
                                <div className='bookingItem'>

                                    <div>
                                        <Typography variant="h6" color={'secondary'} sx={{ mb: 0,  }}>{item?.workshopName} -- {item?.workshopDate}</Typography>
                                        
                                        <Typography variant='p'>{getDateTime(item?.createAt)}</Typography>
                                    </div>

                                    <div>
                                        <Link to={`/dashboard/workshop-booking/${item._id}`}>
                                            <Button variant="contained" >
                                                view
                                            </Button>
                                        </Link>
                                    </div>

                                    
                                </div>
                            ))
                        }
                            </>
                        }
                    </div>
            }
        </>

    )
}

export default MyBooking