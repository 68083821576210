import { InputLabel, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './EventDetails.css'



const EventDetail = ({ workshop }) => {


    

    return (
        <div className='workshopbox bgColor'>
            {/* <Stack direction='row' padding={1} className='BackBox' >
        <Link to={-1} className='backBtn'><HiChevronLeft /></Link>
      </Stack> */}


            <Stack className='eventHeading' alignItems={'center'} padding={3}>
                {/* <Typography variant="h3" color="initial"> Event Details</Typography> */}
                <Typography variant="h5" color="primary"> {workshop?.name}</Typography>
            </Stack>

            <div className="imgBanner">
                <img src={workshop?.banner.url} alt="Event" />
            </div>

            <Stack direction={'row'} padding={'1vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'}>
                <Stack spacing={2} padding={'1vmax'} direction={'column'} alignItems={'top'} justifyContent={'top'}>
                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Workshop Date</Typography>
                        <Typography variant="h6" color="primary">{workshop?.date}</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Booking End (Close) Date</Typography>
                        <Typography variant="h6" color="primary">{workshop?.bookingEndDate}</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">active For Member</Typography>
                        <Typography variant="h6" color="primary">{workshop?.activeForMember.toString()}</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">active For Website</Typography>
                        <Typography variant="h6" color="primary">{workshop?.activeForWebsite.toString()}</Typography>
                    </Stack>
                </Stack>

                <Stack spacing={2} padding={'1vmax '} direction={'column'} alignItems={'top'} justifyContent={'top'}>
                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Fees</Typography>
                        <Typography variant="h6" color="primary">{workshop?.fees}</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Sponsors</Typography>
                        <Typography variant="h6" color="primary">{workshop?.sponsors}</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Total Seats</Typography>
                        <Typography variant="h6" color="primary">{workshop?.totalSeats}</Typography>
                    </Stack>
                </Stack>

                <Stack spacing={2} padding={'1vmax'} direction={'column'} alignItems={'top'} justifyContent={'top'}>
                    {/* <InputLabel id="demo-controlled-open-select-label">Indian Delegates</InputLabel> */}

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">GST</Typography>
                        <Typography variant="h6" color="primary"> {workshop?.GST}%</Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="h6" color="initial">Is Compulsory</Typography>
                        <Typography variant="h6" color="primary"> {workshop?.isGst ? 'Yes' :'No'}</Typography>
                    </Stack>

                </Stack>

                
            </Stack>

            {/* <Stack direction={'row'} padding={'4vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'} margin={'6vmax'} borderRadius={2} border={1}>
          <Typography variant="h3" color="initial">Currently No Booking</Typography>
    </Stack> */}



        </div>
    )
}

export default EventDetail