import { Box, Divider, Stack, Button, Typography, TextField, InputLabel, Select, MenuItem, CircularProgress, FormControl } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Country, State } from 'country-state-city';

// ----------------------------------------------------------------------

import { CREATE_BOOKING_RESET } from '../redux/constants/bookingConstant';
import { clearError, createBooking } from '../redux/actions/bookingActions';
import { getEvent } from '../redux/actions/eventsAction';

import PaymentPage from './PaymentPage'
import SelectPerson from './SelectPersons'

// ----------------------------------------------------------------------
const style = {
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overFlow: 'auto',
    marginBottom: 1
};

const MemberRegistration = () => {

    const { eventId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //  event details
    const [userRole, setUserRole] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [eventName, setEventName] = useState('')

    const { event } = useSelector((state) => state.getEvents)
    // const { user } = useSelector((state) => state.users)


    useEffect(() => {
        dispatch(getEvent(eventId))
    }, [eventId])

    useEffect(() => {
        setEventName(event?.event_name)
        setEventDate(event?.event_date)
        setUserRole(user?.role)
        console.log(event)
    }, [event])


    const { user } = useSelector((state) => state.users)
    const userId = user?._id;

    useEffect(() => {
        setRegFields({ 'FullName': user?.name, 'Email': user?.email })
    }, [user])


    const [regFields, setRegFields] = useState({
        FullName: '',
        Email: '',
        Designation: '',
        Organization: '',
        Country: '',
        City: '',
        State: '',
        Pincode: '',
        Telephone: '',
        Mobile: '',
        GSTIN: '',


    })
    const changeHandler = e => {
        setRegFields({ ...regFields, [e.target.name]: e.target.value })
    }

    const submitHandler = (e) => {
        e.preventDefault()

        // setEventName(event?.event_name)
        // setEventDate(event?.event_date)
        // setUserRole(user?.role)
        // console.log({eventId , userId , registrationInfo:regFields})
        // console.log({ eventId, userId, eventDate, eventName, userRole, registrationInfo: regFields ,persons,paymentDetails:{totalAmount:total}})

        // console.log(eventId , userId , eventDate , eventName , userRole , regFields , total , persons.length)
        const no = regFields.Mobile
        // console.log(no)
        if ( no.length <= 9) {
            toast.warn('Mobile number can not accept less than 10 digit ')


        } else if(eventId && userId && eventDate && eventName && userRole && regFields && total && persons.length !== 0) {

                dispatch(createBooking({ eventId, userId, eventDate, eventName, userRole, registrationInfo: regFields, persons, paymentDetails: { totalAmount: total } }))


            }
            else {
                toast.error('Missing field')
            }
        
    }



    const { loading: bLoading, success, message, id, error: bErr } = useSelector((state) => state.actionBookings)

    useEffect(() => {
        if (success) {
            // toast.success(message);
            feildEmpty();
            if (id) {
                navigate(`/dashboard/bookingDetails/${id}`)
            }
            dispatch({ type: CREATE_BOOKING_RESET });
        }
        if (bErr) {
            toast.error(bErr);
            dispatch(clearError());
        }

    }, [success, bErr, navigate, dispatch])

    const feildEmpty = () => {
        setRegFields(regFields.Email = '')
        setRegFields(regFields.FullName = '')
        setRegFields(regFields.Designation = '')
        setRegFields(regFields.Organization = '')
        setRegFields(regFields.Country = '')
        setRegFields(regFields.State = '')
        setRegFields(regFields.Pincode = '')
        setRegFields(regFields.Telephone = '')
        setRegFields(regFields.Mobile = '')
        setRegFields(regFields.GSTIN = '')
    }

    const [persons, setPersons] = useState('')

    const [subtotal, setsubtotal] = useState(0)
    const [total, settotal] = useState(0)
    const [gst, setGst] = useState(0)



    useEffect(() => {
        if (persons) {
            let newSubtotal = 0
            persons.forEach(item => {
                newSubtotal += Number(item.amount)
            });
            setsubtotal(newSubtotal.toFixed(2))
            const total = newSubtotal + (newSubtotal * (18 / 100))
            settotal(total.toFixed(2))
        }
    }, [persons])
    useEffect(() => {
        const Gst = subtotal * (18 / 100)
        setGst(Gst.toFixed(2))
    }, [subtotal])

    // persons && persons
    // console.log('data')
    return (
        <div>
            <Box sx={style}>
                {/* <Divider sx={{ margin: '2vmax 0' }} /> */}

                <SelectPerson data={persons} setData={setPersons} eventId={eventId} />

                <Stack padding={2} spacing={2} alignItems={'flex-start'}>
                    <Typography color="initial"> <b>Sub Total : Rs {subtotal}</b> </Typography>
                    <Typography color="initial"> <b> GST - 18% : Rs {gst}</b></Typography>
                    <Divider sx={{ margin: 2 }} />
                    <Typography color="initial"><b>Total  : Rs {total}</b></Typography>
                </Stack>
                {/* <Divider sx={{ margin: '2vmax 0' }} /> */}
            </Box>
            <div>
                <Box sx={style} >
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Event Booking Registration
                        </Typography>
                    </Stack>

                    {/* <Divider sx={{margin:'2vmax 0'}} />

                    <SelectPerson data={persons} setData={setPersons} eventId={eventId} />

                    <Divider sx={{margin:'2vmax 0'}} /> */}

                    <form action="" onSubmit={submitHandler}>
                        <Stack spacing={4} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <TextField name="FullName"
                                size='large'
                                sx={{ width: '50%', }}
                                value={regFields.FullName}
                                // onChange={changeHandler}
                                disabled
                                required label="Full Name" />

                            <TextField name="Email"
                                size='large'
                                type='email'
                                sx={{ width: '50%', }}
                                value={regFields.Email}
                                // onChange={changeHandler}
                                disabled

                                required label="Email" />
                        </Stack>


                        {/* jj */}
                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            {/* <TextField name="Country"
                                size='large'
                                sx={{ width: '50%' }}
                                value={regFields.Country}
                                onChange={changeHandler}
                                required label="Country" /> */}

                            <FormControl sx={{ width: '50%' }} >
                                <span id="select-label">Country</span>
                                <select
                                    name='Country'
                                    className='selectStyle'

                                    id="select"
                                    value={regFields.Country}
                                    onChange={changeHandler}
                                    label="Select an option"
                                    required
                                >

                                    <option value="">None</option>
                                    {

                                        Country.getAllCountries().map(country => (
                                            // <MenuItem value={country.name}>{country.name} - {country.isoCode}</MenuItem>
                                            <option value={`${country.name} ${country.isoCode}`}>{country.name} - {country.isoCode}</option>
                                        ))

                                    }
                                </select>
                            </FormControl>
                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="City"
                                    size='large'
                                    value={regFields.City}
                                    onChange={changeHandler}
                                    required label="City" />
                            </FormControl>
                        </Stack>

                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <FormControl sx={{ width: '50%' }} >
                                <span id="select-label">State</span>
                                <select
                                    name='State'
                                    className='selectStyle'
                                    id="select"
                                    value={regFields.State}
                                    onChange={changeHandler}
                                    label="Select an option"
                                    required

                                >

                                    <option value="">None</option>
                                    {

                                        State.getStatesOfCountry(regFields?.Country?.split(' ')[1]).map(state => (
                                            // <MenuItem value={country.name}>{country.name} - {country.isoCode}</MenuItem>
                                            <option value={`${state.name}`}>{state.name} - {state.isoCode}</option>
                                        ))

                                    }


                                </select>
                            </FormControl>
                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="Pincode"
                                    size='large'
                                    value={regFields.Pincode}
                                    type='pincode'
                                    onChange={changeHandler}
                                    required label="Pincode" />
                            </FormControl>
                        </Stack>

                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>

                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="Mobile"
                                    size='large'

                                    value={regFields.Mobile}
                                    onChange={changeHandler}
                                    required label="Mobile" />
                            </FormControl>

                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="GSTIN"
                                    size='large'
                                    value={regFields.GSTIN}
                                    onChange={changeHandler}
                                    label="GSTIN" />
                            </FormControl>

                        </Stack>
                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="Designation"
                                    size='large'

                                    value={regFields.Designation}
                                    onChange={changeHandler}
                                    label="Designation" />
                            </FormControl>
                            <FormControl sx={{ width: '50%', }}>

                                <TextField name="Organization"
                                    size='large'

                                    value={regFields.Organization}
                                    onChange={changeHandler}
                                    label="Organization/Company" />
                            </FormControl>

                        </Stack>

                        {/* <Stack spacing={3} margin='1vmax 0' >
                            <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                            <Select

                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={flag}
                                // label="Age"
                                displayEmpty
                                onChange={e => setFlag(e.target.value)}
                                required
                                size='small'
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'board-member'}>Board Member</MenuItem>
                                <MenuItem value={'member'}>Member</MenuItem>
                                <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                <MenuItem value={'zonal'}>Zonal</MenuItem>
                            </Select>
                        </Stack> */}

                        {/* <Stack spacing={3} margin='1vmax 0'>
                            <input
                                className="inpProfileBtn"
                                required
                                type="file"
                                // accept=""
                                onChange={handleFileChange}
                                name="profile"
                                placeholder="Profile"
                            />
                        </Stack> */}
                        <Divider sx={{ margin: '2vmax 0' }} />
                        {/* <PaymentPage data={ persons} setData={setPersons} eventId={eventId} /> */}


                        {bLoading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :
                            (<Button sx={{ padding: '0.6vmax 6vmax', margin: '2vmax auto' }} size='medium' type="submit" variant="contained" >
                                Submit
                            </Button>)

                        }
                    </form>
                </Box>

            </div>
        </div>
    )
}

export default MemberRegistration