export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"


export const RENEW_REQUEST = "RENEW_REQUEST"
export const RENEW_SUCCESS = "RENEW_SUCCESS"
export const RENEW_FAIL = "RENEW_FAIL"
export const RENEW_RESET = "RENEW_RESET"


export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"
export const CREATE_USER_RESET = "CREATE_USER_RESET"


export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST"
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS"
export const INVITE_USER_FAIL = "INVITE_USER_FAIL"

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST"
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS"
export const ALL_USERS_FAIL = "ALL_USERS_FAIL"


export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"


export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET"


export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

// here
export const ALL_USER_REQUEST = "ALL_USERS_REQUEST"
export const ALL_USER_SUCCESS = "ALL_USERS_SUCCESS"
export const ALL_USER_FAIL = "ALL_USERS_FAIL"

export const USER_DETAILS_REQUEST = "USERS_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USERS_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USERS_DETAILS_FAIL"


export const DELETE_USER_REQUEST = "DELETE_USERS_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USERS_FAIL"
export const DELETE_USER_RESET = "DELETE_USERS_RESET"


export const UPDATE_USER_REQUEST = "UPDATE_USERS_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USERS_FAIL"
export const UPDATE_USER_RESET = "UPDATE_USERS_RESET"


export const BULK_USERS_REQUEST = "BULK_USERS_REQUEST"
export const BULK_USERS_SUCCESS = "BULK_USERS_SUCCESS"
export const BULK_USERS_FAIL = "BULK_USERS_FAIL"
export const BULK_USERS_RESET = "BULK_USERS_RESET"


export const EMAIL_VERIFY_REQUEST = "EMAIL_VERIFY_REQUEST"
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS"
export const EMAIL_VERIFY_FAIL = "EMAIL_VERIFY_FAIL"
export const EMAIL_VERIFY_RESET = "EMAIL_VERIFY_RESET"

export const MEMBER_REGISTRATION_REQUEST = "MEMBER_REGISTRATION_REQUEST"
export const MEMBER_REGISTRATION_SUCCESS = "MEMBER_REGISTRATION_SUCCESS"
export const MEMBER_REGISTRATION_FAIL = "MEMBER_REGISTRATION_FAIL"
export const MEMBER_REGISTRATION_RESET = "MEMBER_REGISTRATION_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"

