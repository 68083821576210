import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// import BlogPage from './pages/BlogPage';
import UserPage from './pages/Users/UserPage';
import LoginPage from './pages/LoginPage';
import EmailVerifyPage from './pages/EmailVerifyPage';
import Page404 from './pages/Page404';

// event
import EventsPage from './pages/EventsPage';
import EventDetails from './pages/EventDetail';

// view 
import EventsBooking from './pages/EventsBooking';
import MagazineView from './pages/MagazineView';
import MagazinePage from './pages/MagazinePage';
import ExeCommittieeView from './pages/ExeCommittieeView';
import OrgDirectoryView from './pages/OrgDirectoryView';
import MemberRegistration from './pages/MemberRegistration';
import MyBookings from './pages/MyBookings';
import BookingDetails from './pages/BookingDetails';
import PaymentPage from './pages/PaymentPage';
// users routes end

// users routes start
import DashboardAppPage from './pages/DashboardAppPage';
import UserNew from './pages/Users/UserNew';
import UserInvite from './pages/Users/UserInvite';
import UserEdit from './pages/Users/UserEdit';
import ForgetPassword from './pages/Users/ForgetPassword';

// ExeCommittiee 
import ExeCommittiee from './pages/ExeCommittiee/ExeCommittiee';

// organization-directory
import OrgDirectory from './pages/OrgDirectory/OrgDirectory';
import MyOrgDirectory from './pages/OrgDirectory/MyOrgDirectory';
import OrganizationViewPage from './pages/OrgDirectory/OrganizationViewPage';

import RenewPage from './pages/RenewPage/MyRenewPage';

// GalleryPage 
import GalleryPage from './pages/GalleryPage/GalleryPage';
// news 
import NewsPage from './pages/NewsPage';
import NewsLetterPage from './pages/NewsLetter/NewsLetterPage';

import ContactForm from './pages/Contact/ContactForm';
import PortalTrack from './pages/PortalTrack/PortalTrack';

import EventBookings from './pages/events/EventBookings';
import PaymentStatusPage from './pages/PaymentStatusPage';
import RenewPayments from './pages/RenewPayments';


// workshop
import WorkshopPage from './pages/workshop/WorkshopPage';
import AllWorkshopPage from './pages/workshop/AllWorkshopPage';
import WorkshopRegistrationPage from './pages/workshop/WorkshopRegistrationPage';
import WorkshopRegistrationPage2 from './pages/workshop/WorkshopRegistrationPage2';
import WorkshopBookingPage from './pages/workshop/WorkshopBookingPage';
import MyWorkshopBookingPage from './pages/workshop/MyWorkshopBookingPage';
import WorkshopBookings from './pages/workshop/WorkshopBookings';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardAppPage /> },
        // for admin  manage
        { path: 'user', element: <UserPage /> },
        { path: 'website-contact-form', element: <ContactForm /> },
        { path: 'track', element: <PortalTrack /> },
        { path: 'events', element: <EventsPage /> },
        { path: 'bookings/:eventId', element: <EventBookings /> },
        { path: 'news', element: <NewsPage /> },
        { path: 'news-letter', element: <NewsLetterPage /> },
        { path: 'team', element: <ExeCommittiee /> },
        { path: 'organization', element: <OrgDirectory /> },
        { path: 'renew-or-certificate', element: <RenewPage /> },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'organization/:id', element: <OrganizationViewPage /> },

        // Workshop 
        { path: 'workshop', element: <WorkshopPage /> },
        { path: 'workshop-bookings/:workshopId', element: <WorkshopBookings /> }, // all booking related to workshop for admin

        { path: 'all-workshop', element: <AllWorkshopPage /> }, // for member
        { path: 'workshop-registration/:workshopId', element: <WorkshopRegistrationPage /> }, // for member

        { path: 'workshop-booking/:bookingId', element: <WorkshopBookingPage /> }, // for member
        { path: 'my-workshop-booking', element: <MyWorkshopBookingPage /> }, // for member
        // for member

        // { path: 'my-workshop-booking', element: <EventsBooking /> }, // for member
        // Workshop end 

        // for members and admin view
        { path: 'my-organization', element: <MyOrgDirectory /> },

        // for members view 
        { path: 'magazines', element: <MagazineView /> },
        { path: 'magazine/:id', element: <MagazinePage /> },

        { path: 'events-booking', element: <EventsBooking /> },
        { path: 'team-v', element: <ExeCommittieeView /> },
        { path: 'organization-v', element: <OrgDirectoryView /> },
        { path: 'member-registration/:eventId', element: <MemberRegistration /> },
        { path: 'myBookings', element: <MyBookings /> },
        { path: 'bookingDetails/:bookingId', element: <BookingDetails /> },
        // { path: 'payment/:bookingId', element: <PaymentPage /> },
        // { path: 'gallery-v', element: <GalleryPageView  /> },
      ],
    },
    {
      path: 'event/:id',
      element: <EventDetails />
    },

    {
      path: '/user',
      children: [
        { path: 'new', element: <UserNew /> },
        { path: 'invite/:token', element: <UserInvite /> },
        { path: 'edit/:id', element: <UserEdit /> },
        { path: 'password/reset/:token', element: <ForgetPassword /> },
      ],
    },
    // {
    //   index: true,
    //   path: '/',
    //   element: <EmailVerifyPage />,
    // },
    { path: 'all-workshop-s', element: <AllWorkshopPage /> }, // for member
    { path: 'workshop-registration/:workshopId', element: <WorkshopRegistrationPage2 /> }, // for member


    {
      index: true,
      path: '/',
      element: <LoginPage />,
    },
    // Membership payment history
    {
      // index: true,
      path: '/renew/payment/history',
      element: <RenewPayments />,
    },
    {
      // index: true,
      path: '/payment/:id',
      element: <PaymentStatusPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
