import React, { useEffect } from 'react'
import './eventBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getNewsLetter } from '../redux/actions/newsLetterAction';

const MagazinePage = () => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading: getLoading, newsLetter, error: getErr } = useSelector((state) => state.getNewsLetter)

  useEffect(() => {
    if (getErr) {
      toast.error(getErr)
    }
    dispatch(getNewsLetter(id))
  }, [dispatch, getErr])


  return (
    <>
      <Typography variant="h4" sx={{ mb: 5 }}>Magazine EOAI  </Typography>

      {
        getLoading ? 'Loading...' :
          <div className='bookingCantainer'>
            {
              <div className='magazinePage'>
                <div>
                  <Typography variant="h5" color={'secondary'} sx={{ mb: 1 }}>{newsLetter?.heading}</Typography>
                  <Typography color={''} sx={{ mb: 1 }}>{newsLetter?.fileName}</Typography>
                  {/* <h3>{item?.}</h3> */}
                </div>
                <div>
                  <iframe title='myfr'  src={newsLetter?.pdfUrl}  allowfullscreen="true" > </iframe>

                </div>

              </div>
            }
          </div>
      }
    </>

  )
}

export default MagazinePage