import { Helmet } from 'react-helmet-async';
import './GalleryPage.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MdDelete, MdModeEdit, MdOutlineRemoveRedEye, MdSettingsBackupRestore } from 'react-icons/md'

// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createGallery, deleteGallery, getGallerys, recoverGallery, updateGallery, clearError, getImages, deleteImage } from '../../redux/actions/galleryAction';
import { CREATE_GALLERY_RESET } from '../../redux/constants/galleryConstant';
// import { round } from 'lodash';


// mock


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ViewStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90vh',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overFlow: 'scroll',
};

export default function GalleryPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGallerys())
    }, [])

    const { loading: getmemLoading, gallerys, totalGallerys, error: getErr } = useSelector((state) => state.getGallerys)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getGallerys(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

    // -----------------------------new Member Modal Code --------------------------------------

    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.actionGallerys)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            deleteMemberModalHandleClose()
            recoverMemberModalHandleClose()
            deleteImageModalHandleClose()
            viewImgModalHandleClose()
            dispatch({ type: CREATE_GALLERY_RESET });
            dispatch(getGallerys(keyword, page))
            // dispatch(getGallerys(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [eventName, setEventName] = useState('');
    const [eventYear, setEventYear] = useState('');

    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newGallerySubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createGallery({ eventName, eventYear }))
    }



    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();


    const [updateEventName, setUpdateEventName] = useState('');
    const [updateEventDate, setUpdateEventDate] = useState('');


    const [Images, setImages] = useState('');
    const [ImagesPreview, setImagesPreview] = useState('');

    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateGallerySubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { eventName: updateEventName, eventYear: updateEventDate, eventImages: Images }
        dispatch(updateGallery(memberDetails, updateItem?._id))
    }

    // const updateHandleFileChange = (event) => {
    //     const file = event.target.files[0];

    //     if (file) {
    //         if (file.size <= 1 * 1024 * 1024) {

    //             const reader = new FileReader()
    //             reader.readAsDataURL(file)
    //             reader.onload = (e) => {
    //                 setUpdateProfile(e.target.result);
    //                 setUpdateProfilePreview(e.target.result);

    //             }
    //         } else {
    //             event.target.value = null;
    //             setUpdateProfile(null);
    //             setUpdateProfilePreview(null);
    //             alert("File size exceeds the limit of 1 MB.");
    //         }
    //     } else {
    //         event.target.value = null;
    //         setUpdateProfile(null);
    //         setUpdateProfilePreview(null);
    //         alert("Please select an profile picture.");
    //     }

    // };


    const updateHandleFileChange = (e) => {

        const files = Array.from(e.target.files)
        setImages([])
        setImagesPreview([])

        files.forEach((file) => {
            const reader = new FileReader();


            reader.onload = () => {
                if (reader.readyState === 2) {
                    // console.log("image..",reader)
                    setImages((old) => [...old, reader.result])
                    setImagesPreview((old) => [...old, reader.result])
                }
            }

            reader.readAsDataURL(file);
        });
    };

    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateEventName(item.eventName)
        setUpdateEventDate(item.eventYear)

        // setUpdateProfile(item.profileUrl)

        updateMemberModalHandleOpen()
    }

    // delete memeber - modal code  ---------------------------
    const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);

    const deleteMemberModalHandleOpen = () => {
        setDeleteMemberModalOpen(true)
    };

    const deleteMemberModalHandleClose = () => {
        setDeleteMemberModalOpen(false);
    }

    const deleteSubmitHandler = () => {
        dispatch(deleteGallery(updateItem?._id));
    }
    const deleteHandle = (item) => {
        setUpdateItem(item)
        deleteMemberModalHandleOpen()
    }
    // Recover member - modal code ---------------------------------
    const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);

    const recoverMemberModalHandleOpen = () => {
        setRecoverMemberModalOpen(true)
    };

    const recoverMemberModalHandleClose = () => {
        setRecoverMemberModalOpen(false);
    }

    const recoverSubmitHandler = () => {
        dispatch(recoverGallery(updateItem?._id));
    }

    const recoverHandle = (item) => {
        setUpdateItem(item)
        recoverMemberModalHandleOpen()
    }

    // Images View Modal 

    const { loading: imgLoading, images, totalImages, error: imgErr } = useSelector((state) => state.getImages)

    const [viewImgModalOpen, setViewImgModalOpen] = useState(false);

    const viewImgModalHandleClose = () => {
        setViewImgModalOpen(false)

    }
    const viewHandle = (item) => {
        setUpdateItem(item)
        setViewImgModalOpen(true)
        dispatch(getImages(item._id))

    }

    // delete image - modal code ---------------------------------
    const [deleteImageModalOpen, setdeleteImageModalOpen] = useState(false);
    const [imgId, setImgId] = useState('');

    const deleteImageModalHandleOpen = () => {
        setdeleteImageModalOpen(true)
    };

    const deleteImageModalHandleClose = () => {
        setdeleteImageModalOpen(false);
    }

    const deleteImgSubmitHandler = () => {
        dispatch(deleteImage(imgId));
    }

    const deleteImgHandle = (id) => {
        setImgId(id)
        deleteImageModalHandleOpen()
    }

// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------
    return (
        <>
            <Helmet>
                <title> Gallery Mange - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Gallery" total={gallerys && gallerys?.length} icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* <Link to={'/user/new'}> */}
                    <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Gallery
                    </Button>
                    {/* </Link> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Gallery</div>

                        {/* <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            gallerys && gallerys.map((item) => (
                                <div className="member-Box">
                                    {/* <div className="imgBoxl">
                                        <img src={item.profileUrl ? item.profileUrl : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div> */}

                                    <div className="namePositionBoxx">
                                        <h5>{item?.eventName}</h5>
                                        <p>{item?.eventYear}</p>
                                    </div>

                                    <div className="actionBox">
                                            <p className='center'> Active on website :  <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p>
                                        <div className="dateBox">
                                            <p>Create : {item?.createAt}</p>

                                        </div>
                                        <div className="icon-Box">

                                            {
                                                item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                                                    <MdSettingsBackupRestore />
                                                </button>) : (<>
                                                    <button onClick={e => editHandle(item)}>
                                                        <MdModeEdit />
                                                    </button>
                                                    <button onClick={e => deleteHandle(item)}>
                                                        < MdDelete/>
                                                    </button>
                                                    <button onClick={e => viewHandle(item)}>
                                                        <MdOutlineRemoveRedEye />
                                                    </button>
                                                </>)
                                            }


                                        </div>
                                        {/* <div className={item?.deleteAt ? 'unlive' : 'live'}> </div> */}
                                    </div>

                                </div>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </Card>
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Create new gallery
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newGallerySubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={eventName}
                                    onChange={e => setEventName(e.target.value)}
                                    required label="Event Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={eventYear}
                                    onChange={e => setEventYear(e.target.value)}
                                    required label="Event Year" />
                            </Stack>


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='autoScroll' >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update Gallery Images
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateGallerySubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={updateEventName}
                                    onChange={e => setUpdateEventName(e.target.value)}
                                    required label="Event Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField 
                                    size='small'
                                    value={updateEventDate}
                                    onChange={e => setUpdateEventDate(e.target.value)}
                                    required label="Event Date" />
                            </Stack>


                            <p>Select Gallery Images</p>
                            <Stack spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"

                                    type="file"
                                    // accept=""
                                    onChange={updateHandleFileChange}
                                    name="profile"
                                    placeholder="Profile"
                                    accept='image/*'
                                    multiple
                                />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'} alignContent={'center'} flexWrap={'wrap'} >
                                {
                                    ImagesPreview && ImagesPreview?.map((item) => (
                                        <img src={item} alt="" width={140} style={{ margin: '0.5vmax' }} />
                                    ))
                                }
                            </Stack>

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    UPDATE
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>



            {/* delete Member  modal code  */}
            <div>
                <Modal
                    open={deleteMemberModalOpen}
                    onClose={deleteMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Delete Gallery
                            </Typography>
                            <Button onClick={deleteMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure delete the {updateItem?.eventName} ?</h3>

                            <div className='actionBtnBox'>
                                {createMemberLoading ? (
                                    <LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>
                                ) : (
                                    <Button variant={'contained'} onClick={deleteSubmitHandler} size='small' >
                                        yes
                                    </Button>
                                )
                                }
                                <Button variant={'outlined'} onClick={deleteMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>

            {/* Recover Member  modal code  */}
            <div>
                <Modal
                    open={recoverMemberModalOpen}
                    onClose={recoverMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Recover Galley
                            </Typography>
                            <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure Recover the {updateItem?.eventName} ?</h3>

                            <div className='actionBtnBox'>
                                {
                                    createMemberLoading ? (
                                        <LoadingButton fullWidth size="large" variant="text" >
                                            <CircularProgress color='success' />
                                        </LoadingButton>
                                    ) : (
                                        <Button variant={'contained'} onClick={recoverSubmitHandler} size='small' >
                                            yes
                                        </Button>
                                    )
                                }
                                <Button variant={'outlined'} onClick={recoverMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>


            {/* View Image modal code  */}
            <div>
                <Modal
                    open={viewImgModalOpen}
                    onClose={viewImgModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ViewStyle} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Gallery images
                            </Typography>
                            <Button onClick={viewImgModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={1} style={{overflow:'auto'}} height={'90%'} direction='row' justifyContent={'center'} marginBottom='0.8vmax ' flexWrap={'wrap'}>
                            {
                                images && images?.map(item => (
                                    <div >
                                        <img src={item.url} alt="" width={200} height={200} style={{ margin: '0.5vmax' }} />
                                        <button onClick={e=>deleteImgHandle(item._id)} className='deleteBtn'>Delete</button>
                                    </div>

                                ))
                            }
                        </Stack>


                    </Box>
                </Modal>
            </div>

            {/* Delete image modal code  */}
            <div>
                <Modal
                    open={deleteImageModalOpen}
                    onClose={deleteImageModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Delete Image
                            </Typography>
                            <Button onClick={deleteImageModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure Recover this image</h3>

                            <div className='actionBtnBox'>
                                {
                                    createMemberLoading ? (
                                        <LoadingButton fullWidth size="large" variant="text" >
                                            <CircularProgress color='success' />
                                        </LoadingButton>
                                    ) : (
                                        <Button variant={'contained'} onClick={deleteImgSubmitHandler} size='small' >
                                            yes
                                        </Button>
                                    )
                                }
                                <Button variant={'outlined'} onClick={deleteImageModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>

        </>
    );
}
