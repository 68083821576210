export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL"

export const GET_TRACK_REQUEST = "GET_TRACK_REQUEST"
export const GET_TRACK_SUCCESS = "GET_TRACK_SUCCESS"
export const GET_TRACK_FAIL = "GET_TRACK_FAIL"

export const CREATE_QUERY_REQUEST = "CREATE_QUERY_REQUEST"
export const CREATE_QUERY_SUCCESS = "CREATE_QUERY_SUCCESS"
export const CREATE_QUERY_FAIL = "CREATE_QUERY_FAIL"
export const CREATE_QUERY_RESET = "CREATE_QUERY_RESET"


export const CLEAR_ERROR = "CLEAR_ERROR"