import { Helmet } from 'react-helmet-async';
import './UserNew.css'
// @mui
import { styled } from '@mui/material/styles';
import {  Container, Typography,  Stack,  IconButton, InputAdornment, TextField, CircularProgress } from '@mui/material';
// import Select from '@mui/material/Select';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


import Iconify from '../../components/iconify';

import useResponsive from '../../hooks/useResponsive';

// components
import { clearError,  resetPassword } from '../../redux/actions/userAction';
import logo from '../../images/icons/logo.png'
import inviteUserImg from '../../images/image/invite-user.avif'

import { CREATE_USER_RESET, UPDATE_USER_RESET } from '../../redux/constants/userConstant';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');


    // ----------------------------------------------------------------------
        const [password, setPassword] = useState('')
        const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams()

    const { loading, success, error } = useSelector((state) => state.forgetPassword)

    useEffect(() => {
        if (success) {
            toast.success('Password Updated successfully');
            navigate('/');
            // dispatch({ type: UPDATE_USER_RESET });
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [success, error, navigate, dispatch])


    const forgetPasswordSubmitHandler = (e) => {
        e.preventDefault()
        // alert(password, confirmPassword)
        dispatch(resetPassword(token, {password, confirmPassword}))
    }

    

  


    return (
        <>
            <Helmet>
                <title> Password Recover  | EOAI DASHBOARD </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    <img src={logo} width={30} alt="EOAI LOGO" />
                </div>
                {mdUp && (
                    <StyledSection>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Change your password
                        </Typography>
                        <>
                            <form action="" onSubmit={forgetPasswordSubmitHandler}>
                                <Stack spacing={3} paddingY={4}>

                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        name="confirmpassword"
                                        label="Confirm Password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>

                                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                    <Checkbox name="remember"label="Remember me" /> 
                                    <Link variant="subtitle2" underline="hover">
                                        Forgot password?
                                    </Link>
                                </Stack> */}

                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        Change
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
