import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'
// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../sections/@dashboard/app';
import Iconify from '../components/iconify';
import TextEditor from '../components/TextEditor';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createNews, deleteNews, getAllNews, recoverNews, updateNews, clearError } from '../redux/actions/newsAction';
import { CREATE_NEWS_RESET } from '../redux/constants/newsConstant';
// import {  } from 'src/redux/reducers/newsReducer';
// import { createNews } from '../redux/reducers/newsReducer';
// import { round } from 'lodash';


// mock


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '650px',
    // height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function NewsPage() {

    // const [page, setPage] = useState(1);
    // const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllNews())
    }, [dispatch])

    const { loading: getmemLoading, allNews, error: getErr } = useSelector((state) => state.getNews)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

    // -----------------------------new Member Modal Code --------------------------------------

    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.createNews)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            deleteMemberModalHandleClose()
            recoverMemberModalHandleClose()
            dispatch({ type: CREATE_NEWS_RESET });
            dispatch(getAllNews())

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [heading, setHeading] = useState('');
    const [title, setTitle] = useState('');
    const [html, setHtml] = useState('');



    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newMemberSubmitHandler = (e) => {
        e.preventDefault()
        // console.log(heading, title, html)
        dispatch(createNews({ heading, title, html }))
    }




    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();


    const [updateHeading, setUpdateHeading] = useState('');
    const [updateTitle, setUpdateTitle] = useState('');
    const [updateHtml, setUpdateHtml] = useState('');



    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateMemberSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { }
        dispatch(updateNews(memberDetails, updateItem?._id))
    }

    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateHeading(item.heading)
        setUpdateTitle(item.title)
        setUpdateHtml(item.html)
        

        updateMemberModalHandleOpen()
    }

    // delete memeber - modal code  ---------------------------
    const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);

    const deleteMemberModalHandleOpen = () => {
        setDeleteMemberModalOpen(true)
    };

    const deleteMemberModalHandleClose = () => {
        setDeleteMemberModalOpen(false);
    }

    const deleteSubmitHandler = () => {
        dispatch(deleteNews(updateItem?._id));
    }
    const deleteHandle = (item) => {
        setUpdateItem(item)
        deleteMemberModalHandleOpen()
    }
    // Recover member - modal code ---------------------------------
    const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);

    const recoverMemberModalHandleOpen = () => {
        setRecoverMemberModalOpen(true)
    };

    const recoverMemberModalHandleClose = () => {
        setRecoverMemberModalOpen(false);
    }

    const recoverSubmitHandler = () => {
        dispatch(recoverNews(updateItem?._id));
    }

    const recoverHandle = (item) => {
        setUpdateItem(item)
        recoverMemberModalHandleOpen()
    }
    
// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------

    return (
        <>
            <Helmet>
                <title> News  - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total News" total={allNews?.length === 0 ? '0' : allNews?.length} icon={'ph:users-fill'} />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Member" total={totalMember === 0 ? '0' : totalMember} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Special Invitee" total={totalSplInviteeMembers === 0 ? '0' : totalSplInviteeMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Zonal" total={totalZonalMembers === 0 ? '0' : totalZonalMembers} color="info" icon={'ph:users-fill'} />
                        </Grid> */}
                    </div>

                    {/* */}
                    <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New News
                    </Button>
                    {/* </Link> */}
                </Stack>

                {/* <Card> */}
                <div className='tableTop'>
                    <div hidden={!getmemLoading ? 'true' : ''}>
                        <CircularProgress color="primary" />
                    </div>
                    <div>News</div>
                    {/* 
                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}
                    <div> </div>


                </div>
                {/* <Divider /> */}

                <div className="teamContainers">
                    {
                        allNews && allNews.map((item) => (
                            <div className="news-Box">


                                <div className="namePositionBoxx">
                                    <h5> <b>{item?.heading}</b></h5>
                                    <p> {item?.title}</p>
                                    {/* <p> {
                                        const s = item?.html
                                    
                                    s.toHtmlObject}</p> */}
                                    <div dangerouslySetInnerHTML={{ __html: item?.html }} />

                                </div>

                                <div className="actionBox">
                                    <div className="dateBox">
                                        <p>Create : {item?.createAt}</p>
                                    </div>
                                    {/* <Stack direction={'row'} alignItems={'center'} spacing={2} margin={'0vmax 0'} > */}
                                    <p className='center'>Active on website
                                        <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p>
                                    {/* </Stack> */}
                                    <div className="icon-Box">

                                        {
                                            item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                                                <MdSettingsBackupRestore />
                                            </button>) : (<>
                                                <button onClick={e => editHandle(item)}>
                                                    <MdModeEdit />
                                                </button>
                                                <button onClick={e => deleteHandle(item)}>
                                                    <MdDelete />

                                                </button>
                                            </>)
                                        }


                                    </div>

                                </div>

                            </div>
                        ))
                    }


                </div>
                {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                {/* </Card> */}
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    // onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Create New News
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={heading}
                                    onChange={e => setHeading(e.target.value)}
                                    required label="Heading" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                    required label="Sub Heading / Title" />
                            </Stack>

                            <TextEditor content={html} setContent={setHtml}  />

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update News
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateMemberSubmitHandler}>
                        <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={updateHeading}
                                    onChange={e => setUpdateHeading(e.target.value)}
                                    required label="Heading" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={updateTitle}
                                    onChange={e => setUpdateTitle(e.target.value)}
                                    required label="Sub Heading / Title" />
                            </Stack>

                            <TextEditor content={updateHtml} setContent={setUpdateHtml} />
                           

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    UPDATE
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>



            {/* delete Member  modal code  */}
            <div>
                <Modal
                    open={deleteMemberModalOpen}
                    onClose={deleteMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style1} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Delete News
                            </Typography>
                            <Button onClick={deleteMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure delete the  - {updateItem?.heading} ?</h3>

                            <div className='actionBtnBox'>
                                {createMemberLoading ? (
                                    <LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>
                                ) : (
                                    <Button variant={'contained'} onClick={deleteSubmitHandler} size='small' >
                                        yes
                                    </Button>
                                )
                                }
                                <Button variant={'outlined'} onClick={deleteMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>

            {/* Recover Member  modal code  */}
            <div>
                <Modal
                    open={recoverMemberModalOpen}
                    onClose={recoverMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style1} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Recover News
                            </Typography>
                            <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure Recover the {updateItem?.heading} ?</h3>

                            <div className='actionBtnBox'>
                                {
                                    createMemberLoading ? (
                                        <LoadingButton fullWidth size="large" variant="text" >
                                            <CircularProgress color='success' />
                                        </LoadingButton>
                                    ) : (
                                        <Button variant={'contained'} onClick={recoverSubmitHandler} size='small' >
                                            yes
                                        </Button>
                                    )
                                }
                                <Button variant={'outlined'} onClick={recoverMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>




        </>
    );
}
