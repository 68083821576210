import React, { useEffect, useState } from 'react'
import './WorkshopBookings.css';
import { LoadingButton } from '@mui/lab';


// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Box, Button, CircularProgress, Divider, Drawer, Modal, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { cancelPerson, clearError, getBookingDetails, getMyBooking } from '../../redux/actions/bookingActions';
// import { getEvent } from '../../redux/actions/workshopsAction';
import { CANCEL_PERSON_RESET } from '../../redux/constants/bookingConstant';
import { GET_BOOKING_PAYMENT_RESET } from '../../redux/constants/paymentConstant';
// import { bookingPayment } from '../../redux/actions/paymentAction';
import { clearError, getBookingWorkshop, getWorkshop } from '../../redux/actions/workshopAction';




// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BookingDetails = () => {
    const { bookingId } = useParams()
    const dispatch = useDispatch();

    const { loading: getLoading, booking, error: getErr } = useSelector((state) => state.getWorkshopBooking)
    const { user } = useSelector((state) => state.users)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr)
            clearError()
        }
    }, [dispatch, getErr])

    useEffect(() => {
        if (user?._id !== undefined) {
            dispatch(getBookingWorkshop(bookingId, user?._id, user?.role))
        }
    }, [dispatch])

    const reg = booking
    const totalAmount = booking?.totalAmount
    //  workshop details
    const { workshop } = useSelector((state) => state.getWorkshops)

    useEffect(() => {
        dispatch(getWorkshop(booking?.workshopId))
    }, [dispatch, booking])


    // console.log(workshop, booking)

    const printHandle = () => {
        const body = document.getElementById('root').innerHTML
        const workshopId = document.getElementById('workshopId').innerHTML
        // console.log(body,workshopId)
        document.getElementById('root').innerHTML = workshopId
        window.print()
        document.getElementById('root').innerHTML = body
        window.location.reload();
    }



    return (
        <div>
            <div className='printBox'>
                <Button onClick={printHandle}>Print</Button>
            </div>

            <span className='topStyle'> </span>

            <div className='detailsBox' id='workshopId'>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{}}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Workshop Name</Typography>
                        <Typography variant="h5" color="secondary">{workshop?.name}</Typography>
                    </Stack>

                    <Stack direction={'column'} width={'20%'} alignItems={'center'}>
                        <Typography variant="h6" color="dark">Total Seats</Typography>
                        <Typography variant="h5" color="secondary">{booking && booking?.totalCount}</Typography>
                    </Stack>
                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3, marginBottom: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Date</Typography>
                        <Typography variant="h6" color="secondary">{workshop?.date}</Typography>
                    </Stack>
                </Stack>
                <Divider marginTop={2} />

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Address</Typography>
                        {/* <Typography variant="h6" color="secondary">Name : {reg?.FullName}</Typography> */}
                    </Stack>

                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Name </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.FullName}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Email </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Email}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Organization </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Organization}</Typography>
                    </Stack>
                    {/* n */}
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Designation </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Designation}</Typography>
                    </Stack>

                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Country </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.Country}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">City </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.City}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">State </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.State}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Pincode </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Pincode}</Typography>
                    </Stack>

                </Stack>


                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Telephone </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.Telephone}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Mobile </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Mobile}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">GSTIN </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.GSTIN}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        {/* <Typography  sx={{fontSize:'1vmax',fontWeight:600}} color="dark">Pincode </Typography>
                        <Typography  sx={{fontSize:'1vmax'}} color="dark"> : {reg?.Pincode}</Typography> */}
                    </Stack>
                </Stack>


                <Divider sx={{ margin: '2vmax 0' }} />

                <Typography color="initial" textAlign={'start'} fontWeight={600}>Person</Typography>
                <Stack paddingTop={2} direction={'row'} spacing={2}>


                    {booking?.name1 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name1}
                        </Typography>
                    }
                    {booking?.name2 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name2}
                        </Typography>
                    }
                    {booking?.name3 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name3}
                        </Typography>
                    }
                    {booking?.name4 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name4}
                        </Typography>
                    }
                    {booking?.name5 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name5}
                        </Typography>
                    }
                    {booking?.name6 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name6}
                        </Typography>
                    }
                    {booking?.name6 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name6}
                        </Typography>
                    }
                    {booking?.name7 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name7}
                        </Typography>
                    }
                    {booking?.name8 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name8}
                        </Typography>
                    }
                    {booking?.name9 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name9}
                        </Typography>
                    }
                    {booking?.name10 &&
                        <Typography color="initial" textAlign={'start'} border={1} padding={1}>
                            {booking?.name10}
                        </Typography>
                    }
                </Stack>
                <Divider sx={{ margin: '2vmax 0' }} />


                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Payment</Typography>
                        {/* <Typography variant="h6" color="secondary">Name : {reg?.FullName}</Typography> */}
                    </Stack>

                </Stack>
                <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={1} sx={{ marginTop: 3 }}>

                    <br />

                    {/* <Typography color="initial"> Sub Total : Rs {totalAmount / 1.18} </Typography> */}
                    {/* <Typography color="initial"> GST - 18% : Rs {(totalAmount / 1.18) * (18 / 100)}</Typography> */}
                    <Typography color="dark">Total Amount : Rs {totalAmount}</Typography>
                    <Stack direction={'row'} spacing={1}>

                        <Typography color="dark">Payment Status</Typography>
                        {
                            booking?.isPaid ?
                                <Typography color="success.main">{booking?.paymentStatus}</Typography> :
                                <Typography color="warning.main">Pending</Typography>

                        }
                    </Stack>
                </Stack>

                <Divider sx={{ margin: '2vmax 0' }} />
                {
                    booking?.isPaid ?
                        (<>
                            <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                                <Stack direction={'column'}>
                                    <img src={booking?.workshopTicketQR.url} alt="" />
                                </Stack>
                            </Stack>
                        </>) :
                        (<>

                        </>)
                }

            </div>



        </div>
    )
}

export default BookingDetails