export const CREATE_NEWS_LETTER_REQUEST = "CREATE_NEWS_LETTER_REQUEST"
export const CREATE_NEWS_LETTER_SUCCESS = "CREATE_NEWS_LETTER_SUCCESS"
export const CREATE_NEWS_LETTER_FAIL = "CREATE_NEWS_LETTER_FAIL"
export const CREATE_NEWS_LETTER_RESET = "CREATE_NEWS_LETTER_RESET"

export const MAGAZINE_NOTIFICATION_REQUEST = "MAGAZINE_NOTIFICATION_REQUEST"
export const MAGAZINE_NOTIFICATION_SUCCESS = "MAGAZINE_NOTIFICATION_SUCCESS"
export const MAGAZINE_NOTIFICATION_FAIL = "MAGAZINE_NOTIFICATION_FAIL"
export const MAGAZINE_NOTIFICATION_RESET = "MAGAZINE_NOTIFICATION_RESET"

export const WELCOME_NOTIFICATION_REQUEST = "WELCOME_NOTIFICATION_REQUEST"
export const WELCOME_NOTIFICATION_SUCCESS = "WELCOME_NOTIFICATION_SUCCESS"
export const WELCOME_NOTIFICATION_FAIL = "WELCOME_NOTIFICATION_FAIL"
export const WELCOME_NOTIFICATION_RESET = "WELCOME_NOTIFICATION_RESET"

export const GET_NEWS_LETTER_REQUEST = "GET_NEWS_LETTER_REQUEST"
export const GET_NEWS_LETTER_SUCCESS = "GET_NEWS_LETTER_SUCCESS"
export const GET_NEWS_LETTER_FAIL = "GET_NEWS_LETTER_FAIL"

export const GET_ALL_NEWS_LETTER_REQUEST = "GET_ALL_NEWS_LETTER_REQUEST"
export const GET_ALL_NEWS_LETTER_SUCCESS = "GET_ALL_NEWS_LETTER_SUCCESS"
export const GET_ALL_NEWS_LETTER_FAIL = "GET_ALL_NEWS_LETTER_FAIL"

export const GET_ALL_MEMBER_NL_REQUEST = "GET_ALL_MEMBER_NL_REQUEST"
export const GET_ALL_MEMBER_NL_SUCCESS = "GET_ALL_MEMBER_NL_SUCCESS"
export const GET_ALL_MEMBER_NL_FAIL = "GET_ALL_MEMBER_NL_FAIL" 
// NL = NEWS LETTER 

export const UPDATE_NEWS_LETTER_REQUEST = "UPDATE_NEWS_LETTER_REQUEST"
export const UPDATE_NEWS_LETTER_SUCCESS = "UPDATE_NEWS_LETTER_SUCCESS"
export const UPDATE_NEWS_LETTER_FAIL = "UPDATE_NEWS_LETTER_FAIL"
export const UPDATE_NEWS_LETTER_RESET = "UPDATE_NEWS_LETTER_RESET"




export const CLEAR_ERROR = "CLEAR_ERROR"