import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Divider, FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { Country, State } from 'country-state-city';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './WorkshopRegPage.css';
// ----------------------------------------------------------------------

import { CREATE_BOOKING_RESET } from '../../redux/constants/bookingConstant';
import { CREATE_WORKSHOP_RESET } from '../../redux/constants/workshopConstant';
// import { } from '../../redux/actions/workshopAction';
import { clearError, createBookingWorkshops, getWorkshop } from '../../redux/actions/workshopAction';

// import SelectPersonForWorkshop from './SelectPersonForWorkshop'

// ----------------------------------------------------------------------
const style = {
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overFlow: 'auto',
    marginBottom: 1
};

const MemberRegistration = () => {

    const { workshopId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //  workshop details
    const [userRole, setUserRole] = useState('')
    const [workshopDate, setWorkshopDate] = useState('')
    const [workshopName, setWorkshopName] = useState('')

    const { workshop } = useSelector((state) => state.getWorkshops)
    // const { user } = useSelector((state) => state.users)


    useEffect(() => {
        dispatch(getWorkshop(workshopId))
    }, [workshopId])

    useEffect(() => {
        setWorkshopName(workshop?.name)
        setWorkshopDate(workshop?.date)
        setUserRole(user?.role)
        // console.log(workshop)
    }, [workshop])


    const { user } = useSelector((state) => state.users)
    const userId = user?._id;

    useEffect(() => {
        setRegFields({ 'FullName': user?.name, 'Email': user?.email })
    }, [user])


    const [regFields, setRegFields] = useState({
        FullName: '',
        Email: '',
        Designation: '',
        Organization: '',
        Country: '',
        City: '',
        State: '',
        Pincode: '',
        Telephone: '',
        Mobile: '',
        GSTIN: '',
    })
    const changeHandler = e => {
        setRegFields({ ...regFields, [e.target.name]: e.target.value })
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        await convertToObjects()
        // setWorkshopName(workshop?.workshop_name)
        // setWorkshopDate(workshop?.workshop_date)
        // setUserRole(user?.role)
        // console.log({workshopId , userId , registrationInfo:regFields})
        // console.log({ workshopId, userId, workshopDate, workshopName, userRole, registrationInfo: regFields ,persons,paymentDetails:{totalAmount:total}})
        const persons = inputValues.filter(value => value.trim() !== '').map(value => ({ name: value.trim() }))
        // console.log(inputValues)
        const personObject = {};

        inputValues.forEach((element, index) => {
            const key = `name${index + 1}`;
            personObject[key] = element;
        });

        if (count === 0) {

            toast.error('Seats should be greater than 0');

        } else if (workshopId && userId && workshopDate && workshopName && userRole && regFields && total) {
            dispatch(createBookingWorkshops({ workshopId, userId, workshopDate, workshopName, userRole, totalCount: count, ...regFields, ...personObject, totalAmount: total }))
        }
        else {
            toast.error('Something went wrong or Missing field ');
        }
    }

    const { loading: bLoading, success, html, message, id, error: bErr } = useSelector((state) => state.actionWorkshops)

    useEffect(() => {
        if (success) {
            toast.success(message);

            feildEmpty();
            if (id) {
                navigate(`/dashboard/bookingDetails/${id}`)
            }
            dispatch({ type: CREATE_BOOKING_RESET });
        }
        if (bErr) {
            toast.error(bErr);
            dispatch(clearError());
        }

    }, [success, bErr, navigate, dispatch])

    const feildEmpty = () => {
        // setRegFields(regFields.Email = '')
        // setRegFields(regFields.FullName = '')
        setRegFields(regFields.Designation = '')
        setRegFields(regFields.Organization = '')
        setRegFields(regFields.Country = '')
        setRegFields(regFields.State = '')
        setRegFields(regFields.Pincode = '')
        setRegFields(regFields.Telephone = '')
        setRegFields(regFields.Mobile = '')
        setRegFields(regFields.GSTIN = '')
    }

    const [persons, setPersons] = useState('')

    const [subtotal, setsubtotal] = useState(0)
    const [total, settotal] = useState(0)

    const [count, setCount] = useState(0)
    const [isGst, setIsGst] = useState(true)
    const [gst, setGst] = useState(0)


    useEffect(() => {
        const newSubtotal = workshop?.fees * count
        setsubtotal(newSubtotal.toFixed(2))

        const total = isGst ? newSubtotal + (newSubtotal * (workshop?.GST / 100)) : newSubtotal

        settotal(total.toFixed(2))


    }, [count, isGst])

    useEffect(() => {

        const gst = (!subtotal ? 0 : subtotal) * (workshop?.GST / 100)


        setGst(gst.toFixed(2))

    }, [subtotal])

    //  select person name
    // persons && persons
    // console.log('data')
    const [inputValues, setInputValues] = useState(Array(count).fill('')); // State to store input values

    // Function to handle input change
    const handleInputChange = async (index, event) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = event.target.value;
        setInputValues(newInputValues);
        await convertToObjects()
    };

    // Function to convert input values into an array of objects
    const convertToObjects = async () => {
        const objectsArray = inputValues.filter(value => value.trim() !== '').map(value => ({ name: value.trim() }));
        setPersons(objectsArray)
        // console.log(persons)
    };

    const inputFields = [];
    for (let i = 0; i < count; i += 1) {
        inputFields.push(
            <input
                key={i}
                type="text"
                className='nameInputFiled'
                placeholder={`Name ${i + 1}`}
                value={inputValues[i]}
                onChange={(event) => handleInputChange(i, event)}
            />
        );
    }

    const deacreseBtn = (e) => {
        e.preventDefault()
        if (count > 1) {
            setCount(count - 1)
        }
    }
    const increaseBtn = (e) => {
        e.preventDefault()

        if (count < 20) {
            setCount(count + 1)
        }
    }

    // -------------------------------payment 
    useEffect(() => {

        if (success) {
            // toast.success('updated success');
            dispatch({ type: CREATE_WORKSHOP_RESET });

            //  run formData code 
            const container = document.createElement('div');
            container.innerHTML = html;

            // Append the form to the document body
            document.body.appendChild(container.firstChild);

            // Trigger the form submission
            document.getElementById('nonseamless').submit();

        }

    }, [success])

    // console.log( typeof subtotal)
    return (
        <div>
            <Box sx={style}>
                {/* <Divider sx={{ margin: '2vmax 0' }} /> */}

                {/* <SelectPerson data={persons} setData={setPersons} workshopId={workshopId} /> */}
                {/* <div>
                    <InputFieldsToArrayOfObjects count={5} />
                </div>

                <Stack padding={2} spacing={2} alignItems={'flex-start'}>
                    <Typography color="initial"> <b>Sub Total : Rs {subtotal}</b> </Typography>
                    <Typography color="initial"> <b> GST - 18% : Rs {subtotal * (18 / 100)}</b></Typography>
                    <Divider sx={{ margin: 2 }} />
                    <Typography color="initial"><b>Total  : Rs {total}</b></Typography>
                </Stack> */}
                {/* <Divider sx={{ margin: '2vmax 0' }} /> */}
            </Box>
            <div>
                <Box sx={style} >
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Workshop Booking Registration
                        </Typography>
                    </Stack>

                    {/* <Divider sx={{margin:'2vmax 0'}} />

                    <SelectPerson data={persons} setData={setPersons} workshopId={workshopId} />

                    <Divider sx={{margin:'2vmax 0'}} /> */}

                    <form action="" onSubmit={submitHandler}>
                        <Stack spacing={4} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <TextField name="FullName"
                                size='large'
                                sx={{ width: '50%', }}
                                value={regFields.FullName}
                                // onChange={changeHandler}
                                disabled
                                required label="" />

                            <TextField name="Email"
                                size='large'
                                type='email'
                                sx={{ width: '50%', }}
                                value={regFields.Email}
                                // onChange={changeHandler}
                                disabled

                                required label="" />
                        </Stack>


                        {/* jj */}
                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            {/* <TextField name="Country"
                                size='large'
                                sx={{ width: '50%' }}
                                value={regFields.Country}
                                onChange={changeHandler}
                                required label="Country" /> */}

                            <FormControl sx={{ width: '50%' }} >
                                <span id="select-label">Country</span>
                                <select
                                    name='Country'
                                    className='selectStyle'

                                    id="select"
                                    value={regFields.Country}
                                    onChange={changeHandler}
                                    label="Select an option"
                                    required
                                >

                                    <option value="">None</option>
                                    {

                                        Country.getAllCountries().map(country => (
                                            // <MenuItem value={country.name}>{country.name} - {country.isoCode}</MenuItem>
                                            <option value={`${country.name} ${country.isoCode}`}>{country.name} - {country.isoCode}</option>
                                        ))

                                    }
                                </select>
                            </FormControl>
                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>City</FormLabel>

                                <TextField name="City"
                                    size='large'
                                    value={regFields.City}
                                    onChange={changeHandler}
                                    required label="" />
                            </FormControl>
                        </Stack>

                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <FormControl sx={{ width: '50%' }} >
                                <span id="select-label">State</span>
                                <select
                                    name='State'
                                    className='selectStyle'
                                    id="select"
                                    value={regFields.State}
                                    onChange={changeHandler}
                                    label="Select an option"
                                    required

                                >

                                    <option value="">None</option>
                                    {

                                        State.getStatesOfCountry(regFields?.Country?.split(' ')[1]).map(state => (
                                            // <MenuItem value={country.name}>{country.name} - {country.isoCode}</MenuItem>
                                            <option value={`${state.name}`}>{state.name} - {state.isoCode}</option>
                                        ))

                                    }


                                </select>
                            </FormControl>
                            {/* <FormControl>
                                <FormLabel>Pincode</FormLabel>
                                <TextField name="Pincode"
                                    size='large'
                                    value={regFields.Pincode}
                                    type='pincode'
                                    onChange={changeHandler}
                                    required 
                                    label="d" />
                                {/* <FormHelperText>y</FormHelperText> 
                            </FormControl> */}

                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>Pincode</FormLabel>

                                <TextField name="Pincode"
                                    size='large'
                                    value={regFields.Pincode}
                                    type='pincode'
                                    onChange={changeHandler}
                                    required label="" />
                            </FormControl>
                        </Stack>

                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>

                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>Mobile</FormLabel>

                                <TextField name="Mobile"
                                    size='large'

                                    value={regFields.Mobile}
                                    onChange={changeHandler}
                                    required label="" />
                            </FormControl>

                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>GSTIN</FormLabel>

                                <TextField name="GSTIN"
                                    size='large'
                                    value={regFields.GSTIN}
                                    onChange={changeHandler}
                                    label="" />
                            </FormControl>

                        </Stack>
                        <Stack spacing={3} width={'100%'} direction={'row'} margin='1.5vmax 0'>
                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>Designation</FormLabel>

                                <TextField name="Designation"
                                    size='large'

                                    value={regFields.Designation}
                                    onChange={changeHandler}
                                    label="" />
                            </FormControl>
                            <FormControl sx={{ width: '50%', }}>
                                <FormLabel>Organization/Company</FormLabel>

                                <TextField name="Organization"
                                    size='large'

                                    value={regFields.Organization}
                                    onChange={changeHandler}
                                    label="" />
                            </FormControl>

                        </Stack>

                        {/* --------------------------------------------------------------------------------------------- */}
                        <Divider sx={{ margin: '2vmax 0' }} />
                        {/* <PaymentPage data={ persons} setData={setPersons} workshopId={workshopId} /> */}

                        <div className='nameInputBox'>
                            {/* <InputFieldsToArrayOfObjects count={5} /> */}
                            <Stack style={{ border: '1px solid gray', borderRadius: 10 }} direction={'row'} alignItems={'center'} justifyContent={"space-between"} padding={2} >
                                <Typography variant="p" color="initial">Select no Count</Typography>

                                <div style={{ display: 'flex', gap: '0.9rem' }}>
                                    <button onClick={deacreseBtn} style={{ backgroundColor: 'gray', color: 'white', fontSize: '20px', border: 'none', width: '30px', borderRadius: 3 }}>-</button>
                                    <div className='countBox'>{count}</div>
                                    <button onClick={increaseBtn} style={{ backgroundColor: 'gray', color: 'white', fontSize: '20px', border: 'none', width: '30px', borderRadius: 3 }}>+</button>

                                </div>
                            </Stack>
                            <div style={{ padding: '1vmax 0.2vmax', display: 'flex', gap: 10 }}>
                                {inputFields}
                                {/* <button onClick={convertToObjects}>Convert to Objects</button> */}
                            </div>

                        </div>

                        <Stack padding={2} spacing={2} alignItems={'flex-start'}>
                            <Typography color="initial"> <b>Sub Total : Rs {subtotal === 'NaN' ? 0 : subtotal}</b> </Typography>


                            {
                                workshop?.isGst &&
                                <Stack direction={'row'} spacing={1}>
                                    <input
                                        type="checkbox"
                                        checked={isGst}
                                        onChange={() => setIsGst(!isGst)}
                                    />
                                    <Typography color="initial"> Do you want to pay GST ?</Typography>
                                </Stack>
                            }

                            {
                                isGst ?
                                    <Typography color="initial" disabled={'true'}> <b> GST - {workshop?.GST}% : Rs {

                                        // num = (!subtotal ? 0 : subtotal) * (workshop?.GST / 100)
                                        // num.toFixed(2)

                                        gst === 'NaN' ? 0 : gst

                                    }</b></Typography>

                                    : ''
                            }
                            <Divider sx={{ margin: 2 }} />
                            <Typography color="initial"><b>Total  : Rs {total === 'NaN' ? 0 : total}</b></Typography>
                        </Stack>
                        <Divider sx={{ margin: '2vmax 0' }} />

                        {bLoading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :
                            (<Button sx={{ padding: '0.6vmax 6vmax', margin: '2vmax auto' }} size='medium' type="submit" variant="contained" >
                                Submit
                            </Button>)

                        }
                    </form>
                </Box>

            </div>
        </div>
    )
}




export default MemberRegistration