import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import './editor.css'
// ----------------------------------------------------------------------

const TextEditor = ({ content, setContent, placeholder }) => {
	const editor = useRef(null);
	// const [content, setContent] = useState('');
	// const config = {
	// 	readonly: false,
	// 	placeholder: 'Start typing...'
	// }

	// const config = useMemo(() => ({
	// 	readonly: false, // all options from https://xdsoft.net/jodit/docs/,
	// 	enableDragAndDropFileToEditor: true,
	// 	placeholder: 'Start typings...',
	// 	uploader: {
	// 		url: 'http://localhost:8000/api/upload-image',
	// 		imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
	// 		// headers: {"token":`${db.token}`},
	// 		withCredentials: false,
	// 		pathVariableName: 'path',
	// 		format: 'json',
	// 		method: 'POST',
	// 		prepareData: function (formData) {
	// 			const file = formData.getAll('files[0]')[0]
	// 			formData.append('image', file)

	// 			formData.delete('files[0]')
	// 			formData.delete('path')
	// 			formData.delete('source')

	// 			return formData
	// 		},
	// 		isSuccess: function (resp) {
	// 			// console.log("resp", resp);
	// 			return !resp.error;
	// 		},
	// 		getMessage: function (resp) {
	// 			// console.log("resp",resp);
	// 			return resp.msgs.join("\n");
	// 		},
	// 		process: function (resp) {
	// 			const imageUrl = resp?.imageUrl;

	// 			return imageUrl;
	// 		},
	// 	}
	// }), []);
	const value = 9999

	return (
		<div>

			<JoditEditor
				ref={editor}
				// sx={style}
				className='editor'
				value={content}
				config={{
					readonly: false,
					toolbar: true,
					uploader: {
					  insertImageAsBase64URI: true,
					  imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
					},

				  }}
				tabIndex={value} // tabIndex of textarea
				// onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
				onChange={newContent => setContent(newContent)}
			/>
		</div>

	);
};

export default TextEditor