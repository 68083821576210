export const CREATE_ORG_DIR_REQUEST = "CREATE_ORG_DIR_REQUEST"
export const CREATE_ORG_DIR_SUCCESS = "CREATE_ORG_DIR_SUCCESS"
export const CREATE_ORG_DIR_FAIL = "CREATE_ORG_DIR_FAIL"
export const CREATE_ORG_DIR_RESET = "CREATE_ORG_DIR_RESET"

export const GET_ORG_DIR_REQUEST = "GET_ORG_DIR_REQUEST"
export const GET_ORG_DIR_SUCCESS = "GET_ORG_DIR_SUCCESS"
export const GET_ORG_DIR_FAIL = "GET_ORG_DIR_FAIL"

export const GET_MY_ORG_REQUEST = "GET_MY_ORG_REQUEST"
export const GET_MY_ORG_SUCCESS = "GET_MY_ORG_SUCCESS"
export const GET_MY_ORG_FAIL = "GET_MY_ORG_FAIL"

export const GET_ORG_REQUEST = "GET_ORG_REQUEST"
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS"
export const GET_ORG_FAIL = "GET_ORG_FAIL"

export const UPDATE_ORG_DIR_REQUEST = "UPDATE_ORG_DIR_REQUEST"
export const UPDATE_ORG_DIR_SUCCESS = "UPDATE_ORG_DIR_SUCCESS"
export const UPDATE_ORG_DIR_FAIL = "UPDATE_ORG_DIR_FAIL"
export const UPDATE_ORG_DIR_RESET = "UPDATE_ORG_DIR_RESET"


export const DELETE_ORG_DIR_REQUEST = "DELETE_ORG_DIR_REQUEST"
export const DELETE_ORG_DIR_SUCCESS = "DELETE_ORG_DIR_SUCCESS"
export const DELETE_ORG_DIR_FAIL = "DELETE_ORG_DIR_FAIL"
export const DELETE_ORG_DIR_RESET = "DELETE_ORG_DIR_RESET"


export const RECOVER_ORG_DIR_REQUEST = "RECOVER_ORG_DIR_REQUEST"
export const RECOVER_ORG_DIR_SUCCESS = "RECOVER_ORG_DIR_SUCCESS"
export const RECOVER_ORG_DIR_FAIL = "RECOVER_ORG_DIR_FAIL"
export const RECOVER_ORG_DIR_RESET = "RECOVER_ORG_DIR_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"