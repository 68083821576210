import React, { useEffect, useState } from 'react'
import './bookingDetails.css';
import { LoadingButton } from '@mui/lab';


// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Box, Button, CircularProgress, Divider, Drawer, Modal, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cancelPerson, clearError, getBookingDetails, getMyBooking } from '../redux/actions/bookingActions';
import { getEvent } from '../redux/actions/eventsAction';
import { CANCEL_PERSON_RESET } from '../redux/constants/bookingConstant';
import { GET_BOOKING_PAYMENT_RESET } from '../redux/constants/paymentConstant';
import { bookingPayment } from '../redux/actions/paymentAction';




// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BookingDetails = () => {
    const { bookingId } = useParams()
    const dispatch = useDispatch();

    const { loading: getLoading, booking, persons, error: getErr } = useSelector((state) => state.getBookings)
    const { user } = useSelector((state) => state.users)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr)
            clearError()
        }
        if (user?._id !== undefined) {
            dispatch(getBookingDetails(bookingId, user?._id, user?.role))
        }

    }, [dispatch])

    const reg = booking?.registrationInfo
    const totalAmount = booking?.paymentDetails?.totalAmount
    //  event details
    const { event } = useSelector((state) => state.getEvents)

    useEffect(() => {

        dispatch(getEvent(booking?.eventId))
    }, [dispatch, booking])



    //  -------------------  Cancel modal code  -----------------------------------

    const { loading: cancelLoading, success: cancelSuccess, error } = useSelector((state) => state.actionBookings)

    useEffect(() => {
        if (cancelSuccess) {
            toast.success('updated success');
            dispatch({ type: CANCEL_PERSON_RESET });
            // dispatch(getOrgDirs(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, cancelSuccess, dispatch])

    const [cancelItem, setCancelItem] = useState();

    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');



    //  modal 
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    const cancelModalHandleOpen = () => {
        setCancelModalOpen(true)
    };
    const cancelModalHandleClose = () => {
        setCancelModalOpen(false);
    }

    const cancelSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { amount, transactionId }
        dispatch(cancelPerson(memberDetails, cancelItem?._id, bookingId))
    }

    const editHandle = (item) => {
        setCancelItem(item)

        cancelModalHandleOpen()
    }


    // -----------------------------Payment Now Modal Code --------------------------------------


    const { loading: payLoading, formData, success: paySuccess, error: PayErr } = useSelector((state) => state.paymentReq)

    const paySubmitHandler = (e) => {
        e.preventDefault()
        dispatch(bookingPayment(bookingId, { price: totalAmount, userId: user._id }))
    }

    useEffect(() => {

        if (paySuccess) {
            // toast.success('updated success');
            dispatch({ type: GET_BOOKING_PAYMENT_RESET });

            //  run formData code 
            const container = document.createElement('div');
            container.innerHTML = formData;

            // Append the form to the document body
            document.body.appendChild(container.firstChild);

            // Trigger the form submission
            document.getElementById('nonseamless').submit();

        }
        if (PayErr) {
            toast.error(PayErr);
            dispatch(clearError());
        }
    }, [PayErr, paySuccess, dispatch])


    const printHandle = () => {
        const body = document.getElementById('root').innerHTML
        const eventID = document.getElementById('eventID').innerHTML
        // console.log(body,eventID)
        document.getElementById('root').innerHTML = eventID
        window.print()
        document.getElementById('root').innerHTML = body
        window.location.reload();
    }
    console.log(booking)
    return (
        <div>
            {
                booking?.isPaid &&
                <div className='printBox'>
                    <Button variant='contained' color='success' sx={{ color: 'white' }} onClick={printHandle}>Print</Button>

                </div>
            }

            <span className='topStyle'> </span>
            <div className='detailsBox' id='eventID'>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{}}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Event Name</Typography>
                        <Typography variant="h5" color="secondary">{event?.event_name}</Typography>
                    </Stack>

                    <Stack direction={'column'} width={'20%'} alignItems={'center'}>
                        <Typography variant="h6" color="dark">total Seats</Typography>
                        <Typography variant="h5" color="secondary">{booking && booking?.persons?.length}</Typography>
                    </Stack>
                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3, marginBottom: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Date</Typography>
                        <Typography variant="h6" color="secondary">{event?.event_date}</Typography>
                    </Stack>
                </Stack>
                <Divider marginTop={2} />

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Address</Typography>
                        {/* <Typography variant="h6" color="secondary">Name : {reg?.FullName}</Typography> */}
                    </Stack>

                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Name </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.FullName}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Email </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Email}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Organization </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Organization}</Typography>
                    </Stack>
                    {/* n */}
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Designation </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Designation}</Typography>
                    </Stack>

                </Stack>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Country </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.Country}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">City </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.City}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">State </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.State}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Pincode </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Pincode}</Typography>
                    </Stack>

                </Stack>


                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Telephone </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark">:  {reg?.Telephone}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">Mobile </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.Mobile}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        <Typography sx={{ fontSize: '1vmax', fontWeight: 600 }} color="dark">GSTIN </Typography>
                        <Typography sx={{ fontSize: '1vmax' }} color="dark"> : {reg?.GSTIN}</Typography>
                    </Stack>

                    <Stack direction={'row'} width={'25%'} spacing={1}>
                        {/* <Typography  sx={{fontSize:'1vmax',fontWeight:600}} color="dark">Pincode </Typography>
                        <Typography  sx={{fontSize:'1vmax'}} color="dark"> : {reg?.Pincode}</Typography> */}
                    </Stack>
                </Stack>


                <Divider sx={{ margin: '2vmax 0' }} />
                <div className='ol'>
                    {booking && booking?.persons.map((item, i) => (
                        <div className='viewperson1Box'>

                            <div key={item.id} className='viewperson1' >
                                <div>
                                    <Typography color="initial">{i + 1}</Typography>
                                    <Typography color="initial"> {item.name}</Typography>
                                    <Typography color="initial"> {item.category}</Typography>
                                    <Typography color="initial"> Rs {item.amount}</Typography>
                                </div>
                                <div>
                                    {
                                        booking?.isPaid ?
                                            <div className='bookingBox'>
                                                {
                                                    item.cancel === false ?
                                                        <Typography color="green"> {item.bookingStatus}</Typography> :
                                                        <Typography color="red"> Canceled</Typography>

                                                }
                                                {
                                                    (user.role === 'admin' && item.cancel === false) &&
                                                    <Button color="error" onClick={e => editHandle(item)} variant="outlined">Cancel</Button>
                                                }
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>

                            {
                                item.cancel &&
                                <div className='viewperson1' >
                                    {/* <div> </div> */}
                                    <div className='cancelDetails'>
                                        <Typography color="initial">Refund Transaction Id : {item.refundTransactionID}</Typography>
                                        <Typography color="initial"> ReFund amount : {item.refundAmount}</Typography>
                                        <Typography color="initial"> Refund By EOAI Admin</Typography>
                                    </div>
                                </div>

                            }

                        </div>

                    ))}
                </div>
                <Divider sx={{ margin: '2vmax 0' }} />


                <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                    <Stack direction={'column'}>
                        <Typography variant="h6" color="dark">Payment</Typography>
                        {/* <Typography variant="h6" color="secondary">Name : {reg?.FullName}</Typography> */}
                    </Stack>

                </Stack>
                <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={1} sx={{ marginTop: 3 }}>
                    <Stack direction={'row'} spacing={1}>

                        <Typography color="dark">Payment Status</Typography>
                        {
                            booking?.isPaid ?
                                <Typography color="success.main">{booking?.paymentDetails?.paymentStatus}</Typography> :
                                <Typography color="warning.main">Pending</Typography>

                        }
                    </Stack>
                    <br />

                    <Typography color="initial"> Sub Total : Rs {totalAmount / 1.18} </Typography>
                    <Typography color="initial"> GST - 18% : Rs {(totalAmount / 1.18) * (18 / 100)}</Typography>
                    <Typography color="dark">Total Amount : Rs {totalAmount}</Typography>

                </Stack>
                {
                    booking?.isPaid ?
                        (<>

                        </>) :
                        (<>
                            <Stack direction={'column'} justifyContent={'space-between'} spacing={2} sx={{ marginTop: 3 }}>
                                <Stack direction={'row'} spacing={3}>
                                    {/* <Link to={`/dashboard/payment/${bookingId}`}> */}
                                    <Button variant='contained' color='success' sx={{ color: 'white' }} onClick={paySubmitHandler}>Pay Now</Button>
                                    {/* </Link> */}
                                </Stack>

                            </Stack>
                        </>)
                }

                <Divider sx={{ margin: '2vmax 0' }} />
                {
                    booking?.isPaid ?
                        (<>
                            <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: 3 }}>
                                <Stack direction={'column'}>
                                    <img src={booking?.eventTicketQR?.url} alt="event" />
                                </Stack>

                            </Stack>
                        </>) :
                        (<>

                        </>)
                }

            </div>


            {/* Cancel Member'person  modal code  */}
            <div>
                <Modal
                    open={cancelModalOpen}
                    onClose={cancelModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Cancel  Member ({cancelItem?.name})
                            </Typography>
                            <Button onClick={cancelModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>
                        <Divider />

                        <form action="" onSubmit={cancelSubmitHandler}>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name=""
                                    size='medium'
                                    value={amount}
                                    type='number'
                                    onChange={e => setAmount(e.target.value)}
                                    required label="Refund Amount" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='medium'
                                    value={transactionId}
                                    onChange={e => setTransactionId(e.target.value)}
                                    required label="Transaction Id" />
                            </Stack>





                            {cancelLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' color='error' type="submit" variant="contained" >
                                    Cancel
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default BookingDetails