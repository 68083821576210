import { Helmet } from 'react-helmet-async';
import './WorkshopsPage.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel, FormControlLabel, Radio, Switch,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AiTwotoneEdit, AiFillEye } from 'react-icons/ai'
// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
import { CREATE_WORKSHOP_RESET } from '../../redux/constants/workshopConstant';
import { clearError, createWorkshop, getWorkshops, updateWorkshops } from '../../redux/actions/workshopAction';
import WorkshopDetail from '../WorkshopDetail';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const viewWorkshopStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
}
const stackStyle = {
    width: '48%',
}
const NewWorkshopStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90vh',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}
const NewWorkshopInput = {
    width: '100%',

}
export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWorkshops(keyword, page))
    }, [dispatch, page])

    const { loading: getLoading, workshops, totalBoardWorkshops, totalWorkshops, error: getErr } = useSelector((state) => state.getWorkshops)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.prworkshopDefault();
        dispatch(getWorkshops(keyword, page))
    }
    const handlePage = (workshop, value) => {
        setPage(value);
    };

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

    // -----------------------------new Workshop Modal Code --------------------------------------

    const { loading: createWorkshopLoading, success: createWorkshopSuccess, error } = useSelector((state) => state.actionWorkshops)

    useEffect(() => {
        if (createWorkshopSuccess) {
            toast.success('updated success');
            WorkshopModalHandleClose()
            updateWorkshopModalHandleClose()
            // deleteWorkshopModalHandleClose()
            // recoverWorkshopModalHandleClose()
            dispatch({ type: CREATE_WORKSHOP_RESET });
            dispatch(getWorkshops(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createWorkshopSuccess, dispatch])


    const [workshopName, setWorkshopName] = useState('');
    const [workshopDate, setWorkshopdate] = useState('');
    const [bookingEndDate, setBookingEndDate] = useState('');
    const [fees, setFees] = useState('');
    const [sponsors, setSponsers] = useState('');
    const [activeForMember, setActiveForMember] = useState(true);
    const [activeForWebsite, setActiveForWebsite] = useState(true);
    const [totalSeats, setTotalSeats] = useState('');
    const [GST, setGST] = useState('');
    const [isGst, setIsGst] = useState(false);


    const [banner, setBanner] = useState('');
    const [bannerPreview, setBannerPreview] = useState('');
    const [WorkshopModalOpen, setWorkshopModalOpen] = useState(false);

    const WorkshopModalHandleOpen = () => {
        setWorkshopModalOpen(true)
    };
    const WorkshopModalHandleClose = () => {
        setWorkshopModalOpen(false);
    }

    const newWorkshopSubmitHandler = (e) => {
        e.preventDefault()
        if (fees < 1) {
            toast.warn('Fees can not accept less then 1 Rupee')
            console.log()

        } else if (GST < 1) {
            toast.warn('GST can not accept less then 1%')
        } else if (bookingEndDate > workshopDate) {
            toast.warn('Booking Close Date can not accept greater then  Workshop Date')
        }
        else {
            dispatch(createWorkshop({
                name: workshopName,
                date: workshopDate,
                fees,
                banner, sponsors, totalSeats, GST, isGst,
                activeForMember, activeForWebsite,
                bookingEndDate
            }))
        }
    }

    const handleFileChange = (workshop) => {
        const file = workshop.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setBanner(e.target.result);
                    setBannerPreview(e.target.result);

                    // console.log(selectedFile)
                }
            } else {
                workshop.target.value = null;
                setBanner(null);
                setBannerPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            workshop.target.value = null;
            setBanner(null);
            setBannerPreview(null);
            alert("Please select an profile picture.");
        }
    };


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();

    const [workshopUpdateName, setUpdateWorkshopName] = useState('');
    const [workshopUpdateDate, setUpdateWorkshopdate] = useState('');
    const [updateBookingEndDate, setUpdateBookingEndDate] = useState('');

    const [UpdateWorkshopfee, setUpdateWorkshopfee] = useState('');
    const [UpdateSponsors, setUpdateSponsors] = useState('');
    const [UpdateActiveForMember, setUpdateActiveForMember] = useState('');
    const [UpdateActiveForWebsite, setUpdateActiveForWebsite] = useState('');
    const [UpdateTotalSeats, setUpdateTotalSeats] = useState('');
    const [UpdateGST, setUpdateGST] = useState('');
    const [UpdateIsGst, setUpdateIsGst] = useState('');


    const [UpdateBannerPreview, setUpdateBannerPreview] = useState('');
    // modal
    const [updateWorkshopModalOpen, setUpdateWorkshopModalOpen] = useState(false);

    const updateWorkshopModalHandleOpen = () => {
        setUpdateWorkshopModalOpen(true)
    };
    const updateWorkshopModalHandleClose = () => {
        setUpdateWorkshopModalOpen(false);
    }

    // form submit 
    const updateWorkshopSubmitHandler = (e) => {
        e.preventDefault()

        if (UpdateWorkshopfee < 1) {
            toast.warn('Fees can not accept less then 1 Rupee')

        } else if (UpdateGST < 1) {
            toast.warn('GST can not accept less then 1%')
        } else if (updateBookingEndDate > workshopUpdateDate) {
            toast.warn('Booking Close Date can not accept greater then  Workshop Date')
        }
        else {
            const WorkshopDetails = {
                name: workshopUpdateName, date: workshopUpdateDate, fees: UpdateWorkshopfee, sponsors: UpdateSponsors, activeForMember: UpdateActiveForMember, activeForWebsite: UpdateActiveForWebsite, totalSeats: UpdateTotalSeats, GST: UpdateGST, banner: UpdateBannerPreview, isGst: UpdateIsGst, bookingEndDate: updateBookingEndDate
            }

            dispatch(updateWorkshops(WorkshopDetails, updateItem?._id))

        }
    }

    const updateHandleFileChange = (workshop) => {
        const file = workshop.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateBannerPreview(e.target.result);
                    // setUpdateProfilePreview(e.target.result);

                }
            } else {
                workshop.target.value = null;
                setUpdateBannerPreview(null);
                // setUpdateProfilePreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            workshop.target.value = null;
            setUpdateBannerPreview(null);
            // setUpdateProfilePreview(null);
            alert("Please select an profile picture.");
        }

    };

    const editHandle = (item) => {
        console.log(item)
        setUpdateItem(item)

        setUpdateWorkshopName(item.name)
        setUpdateWorkshopdate(item.date)
        setUpdateBookingEndDate(item.bookingEndDate)
        setUpdateActiveForMember(item.activeForMember)
        setUpdateActiveForWebsite(item.activeForWebsite)
        setUpdateBannerPreview(item.banner.url)
        setUpdateWorkshopfee(item.fees)
        setUpdateGST(item.GST)
        setUpdateIsGst(item.isGst)
        setUpdateSponsors(item.sponsors)
        setUpdateTotalSeats(item.totalSeats)

        updateWorkshopModalHandleOpen()
    }

    // view Workshop Modal code ----------------------------------------- 

    const [viewWorkshopModalOpen, setviewWorkshopModalOpen] = useState(false);
    const [workshopDetails, setWorkshopDetails] = useState('');

    const viewWorkshopModalHandleOpen = item => {
        setWorkshopDetails(item)
        setviewWorkshopModalOpen(true)
    };
    const viewWorkshopModalHandleClose = () => {
        setviewWorkshopModalOpen(false);
    }

    // view Bookings Modal code ----------------------------------------- 

    const [viewBookingsModalOpen, setviewBookingsModalOpen] = useState(false);
    //   const [BookingsDetails, setBookingsDetails] = useState('');

    const viewBookingsModalHandleOpen = (item) => {
        //   setBookingsDetails(item)
        setviewBookingsModalOpen(true)
    };
    const viewBookingsModalHandleClose = () => {
        setviewBookingsModalOpen(false);
    }

    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)
    if (user) {
        if (user.role !== 'admin') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------
    return (
        <>
            <Helmet>
                <title> Workshop - eoai</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Workshops" total={workshops?.length} icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    <Button variant="contained" onClick={WorkshopModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Workshops
                    </Button>
                </Stack>

                <div>
                    <div className='tableTop'>
                        <div hidden={!getLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Workshop</div>
                    </div>

                    <div className="eventsContainers">
                        {
                            workshops && workshops.map((item) => (
                                <Card>
                                    <div className="event-Box">
                                        <div className="eventImgBoxl">
                                            <img src={item.banner ? item.banner.url : 'https://us.123rf.com/450wm/arypsto/arypsto2104/arypsto210400138/168148723-colorful-layout-design-for-events-festivals-and-art-performances.jpg?ver=6'} alt="Workshop" />
                                        </div>

                                        <div className="namePositionBoxx">
                                            <h5>{item?.name}</h5>

                                            <p> {item?.date}</p>
                                            {/* <h5><b>Workshop fee</b> : {item?.event_registration_fee}</h5> */}
                                            {/* <p><b>Total Seats</b> : {item?.totalSeats}</p>
                                            <p><b>Marketing Status</b> : {item?.MarketingStatus}</p>
                                            <p>{item?.phone}</p>
                                            <p>Create : {item?.createAt}</p> */}
                                        </div>

                                        <div className="actionBox">

                                            <div className="dateBox">
                                                {/* <p>Create : {item?.createAt}</p> */}
                                            </div>
                                            <div className="event-icon-Box">

                                                <Stack direction={'row'} spacing={1}>
                                                    <button className='ei_btn' onClick={e => editHandle(item)}>
                                                        <AiTwotoneEdit />
                                                    </button>
                                                    <Link className='ei_btn' onClick={e => viewWorkshopModalHandleOpen(item)}  >
                                                        <AiFillEye />
                                                    </Link>

                                                </Stack>
                                                <p>Active for website :  <div className={item?.activeForWebsite ? 'live' : 'unlive'}> </div></p>
                                                <p>Active for Portal :  <div className={item?.activeForMember ? 'live' : 'unlive'}> </div></p>
                                                <Link className='book_btn' to={`/dashboard/workshop-bookings/${item._id}`} style={{ textDecoration: 'none' }}   >
                                                    {/* <AiFillEye /> */}
                                                    Bookings
                                                </Link>
                                            </div>
                                            {/* <div className={item?.deleteAt ? 'unlive' : 'live'}> </div> */}
                                        </div>

                                    </div>
                                </Card>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </div>
            </Container>


            {/* New Workshop  modal code  */}
            <div >
                <Modal
                    open={WorkshopModalOpen}
                    // onClose={WorkshopModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={NewWorkshopStyle} className='eventCreateBox' >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Create  New Workshop
                            </Typography>
                            <Button onClick={WorkshopModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newWorkshopSubmitHandler}>
                            <Stack spacing={2} margin='0.6vmax 0' direction="row" >
                                <Stack spacing={0} sx={stackStyle} >
                                    <Stack spacing={2} margin='0.4vmax 0' direction="row" >
                                        <TextField name="name"
                                            size='small'
                                            value={workshopName}
                                            onChange={e => setWorkshopName(e.target.value)}
                                            required label="Workshop Name"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography mt={0} color="initial" type="p" textAlign={'left'}>Select Workshop Date</Typography>
                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={workshopDate}
                                            onChange={e => setWorkshopdate(e.target.value)}
                                            required
                                            // label="Workshop Date"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography mt={0} color="initial" type="p" textAlign={'left'}>Booking End (Close) Date</Typography>
                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={bookingEndDate}
                                            onChange={e => setBookingEndDate(e.target.value)}
                                            required
                                            // label="Workshop Date"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    {/* <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="phone"
                                            size='small'
                                            value={workshopfee}
                                            onChange={e => setWorkshopfee(e.target.value)}
                                            required label="Workshop Registration fee" />
                                    </Stack> */}
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="Sponsors"
                                            size='small'
                                            value={sponsors}
                                            onChange={e => setSponsers(e.target.value)}
                                            required label="Sponsors" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="totalSeats"
                                            type='number'
                                            size='small'
                                            value={totalSeats}
                                            onChange={e => setTotalSeats(e.target.value)}
                                            required label="Total seats" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField
                                            size='small'
                                            value={fees}
                                            type='Number'
                                            onChange={e => setFees(e.target.value)}
                                            required label="Enter Workshop Fees" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField
                                            size='small'
                                            value={GST}
                                            type='Number'
                                            onChange={e => setGST(e.target.value)}
                                            required label="GST (in %)" />
                                    </Stack>
                                    <Stack spacing={3} margin='0vmax 0' mb={2} direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Is GST Compulsory to pay</InputLabel>

                                        <Switch
                                            checked={isGst}
                                            onChange={e => { setIsGst(!isGst) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>
                                </Stack>


                                <Stack sx={stackStyle}>
                                    <Stack spacing={3} margin='0vmax 0' direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Active for website</InputLabel>

                                        <Switch
                                            checked={activeForWebsite}
                                            onChange={e => { setActiveForWebsite(!activeForWebsite) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>

                                    <Stack spacing={3} margin='0vmax 0' mb={2} direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Active for portal/member</InputLabel>

                                        <Switch
                                            checked={activeForMember}
                                            onChange={e => { setActiveForMember(!activeForMember) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label" >Select Workshop Poster</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <input
                                            className="inpProfileBtn"
                                            required
                                            type="file"
                                            // accept=""
                                            onChange={handleFileChange}
                                            name="profile"
                                            placeholder="Profile"
                                        />
                                        <img src={bannerPreview !== '' ? bannerPreview : 'https://media.istockphoto.com/id/1427946294/vector/red-color-inserted-label-banner-with-word-preview-on-white-background.jpg?s=612x612&w=0&k=20&c=Xbp2hzB7quoG03KuJ2ZqOj5Yos7I3iuTFR9pY8--OeQ='} className='bannerPreview' alt="preview" height={120} width={'auto'} />
                                    </Stack>


                                </Stack>
                            </Stack>


                            {/* <Stack spacing={3} margin='0.6vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-Workshop'}>Board Workshop</MenuItem>
                                    <MenuItem value={'Workshop'}>Workshop</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}



                            {createWorkshopLoading ?
                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Workshop  modal code  */}
            <div>
                <Modal
                    open={updateWorkshopModalOpen}
                    onClose={updateWorkshopModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={NewWorkshopStyle} className='workshopCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Workshop Update
                            </Typography>
                            <Button onClick={updateWorkshopModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />


                        <form action="" onSubmit={updateWorkshopSubmitHandler}>
                            <Stack spacing={2} margin='0.6vmax 0' direction="row" >
                                <Stack spacing={0} sx={stackStyle} >
                                    <Stack spacing={2} margin='0.4vmax 0' direction="row" >
                                        <TextField name="name"
                                            size='small'
                                            value={workshopUpdateName}
                                            onChange={e => setUpdateWorkshopName(e.target.value)}
                                            required label="Workshop Name"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography mt={0} color="initial" textAlign={'left'}>Select Workshop Date</Typography>
                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={workshopUpdateDate}
                                            onChange={e => setUpdateWorkshopdate(e.target.value)}
                                            required
                                            // label="Workshop Date"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography mt={0} color="initial" textAlign={'left'}>Booking End (Close) Date</Typography>
                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={updateBookingEndDate}
                                            onChange={e => setUpdateBookingEndDate(e.target.value)}
                                            required
                                            // label="Workshop Date"
                                            sx={NewWorkshopInput} />
                                    </Stack>
                                    {/* <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="phone"
                                            size='small'
                                            value={workshopfee}
                                            onChange={e => setWorkshopfee(e.target.value)}
                                            required label="Workshop Registration fee" />
                                    </Stack> */}
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="Sponsors"
                                            size='small'
                                            value={UpdateSponsors}
                                            onChange={e => setUpdateSponsors(e.target.value)}
                                            required label="Sponsors" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="totalSeats"
                                            type='number'
                                            size='small'
                                            value={UpdateTotalSeats}
                                            onChange={e => setUpdateTotalSeats(e.target.value)}
                                            required label="Total seats" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField
                                            size='small'
                                            value={UpdateWorkshopfee}
                                            type='Number'
                                            onChange={e => setUpdateWorkshopfee(e.target.value)}
                                            required label="Enter Workshop Fees" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField
                                            size='small'
                                            value={UpdateGST}
                                            type='Number'
                                            onChange={e => setUpdateGST(e.target.value)}
                                            required label="GST (in %)" />
                                    </Stack>
                                    <Stack spacing={3} margin='0vmax 0' mb={2} direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Is GST Compulsory to pay</InputLabel>

                                        <Switch
                                            checked={UpdateIsGst}
                                            onChange={e => { setUpdateIsGst(!UpdateIsGst) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>
                                </Stack>


                                <Stack sx={stackStyle}>
                                    <Stack spacing={3} margin='0vmax 0' direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Active for website</InputLabel>

                                        <Switch
                                            checked={UpdateActiveForWebsite}
                                            onChange={e => { setUpdateActiveForWebsite(!UpdateActiveForWebsite) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>

                                    <Stack spacing={3} margin='0vmax 0' mb={2} direction={'row'} alignItems={'center'}
                                        justifyContent={'space-between'}>
                                        <InputLabel id="demo-controlled-open-select-label">Active for portal/member</InputLabel>

                                        <Switch
                                            checked={UpdateActiveForMember}
                                            onChange={e => { setUpdateActiveForMember(!UpdateActiveForMember) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label" >Select Workshop Poster</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <input
                                            className="inpProfileBtn"
                                            type="file"
                                            // accept=""
                                            onChange={updateHandleFileChange}
                                            name="profile"
                                            placeholder="Profile"
                                        />
                                        <img src={UpdateBannerPreview !== '' ? UpdateBannerPreview : 'https://media.istockphoto.com/id/1427946294/vector/red-color-inserted-label-banner-with-word-preview-on-white-background.jpg?s=612x612&w=0&k=20&c=Xbp2hzB7quoG03KuJ2ZqOj5Yos7I3iuTFR9pY8--OeQ='} className='bannerPreview' alt="preview" height={120} width={'auto'} />
                                    </Stack>


                                </Stack>
                            </Stack>


                            {/* <Stack spacing={3} margin='0.6vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-Workshop'}>Board Workshop</MenuItem>
                                    <MenuItem value={'Workshop'}>Workshop</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}



                            {createWorkshopLoading ?
                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>

            {/* view Workshop details  modal code  */}
            <div >
                <Modal
                    open={viewWorkshopModalOpen}
                    // onClose={WorkshopModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={viewWorkshopStyle} className='workshopCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Workshops
                            </Typography>
                            <Button onClick={viewWorkshopModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>
                        <Divider />
                        <WorkshopDetail workshop={workshopDetails} />
                    </Box>
                </Modal>
            </div>


            {/* view Workshop details  modal code  */}
            {/* <div >
                <Modal
                    open={viewBookingsModalOpen}
                    // onClose={WorkshopModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={viewWorkshopStyle} className='workshopCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Workshops Bookings
                            </Typography>
                            <Button onClick={viewBookingsModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />
                        <div>
                            <Stack direction={'row'} padding={'4vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'} margin={'6vmax'} borderRadius={2} border={1}>
                                <Typography variant="h3" color="initial">Currently No Booking</Typography>
                            </Stack>
                        </div>
                    </Box>
                </Modal>
            </div> */}



        </>
    );
}
