import axios from "axios";
import {
    CREATE_GALLERY_REQUEST,
    CREATE_GALLERY_SUCCESS,
    CREATE_GALLERY_FAIL,

    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_FAIL,
    UPDATE_GALLERY_REQUEST,
    UPDATE_GALLERY_SUCCESS,
    UPDATE_GALLERY_FAIL,

    DELETE_GALLERY_REQUEST,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAIL,

    RECOVER_GALLERY_REQUEST,
    RECOVER_GALLERY_SUCCESS,
    RECOVER_GALLERY_FAIL,
    CLEAR_ERROR,
    GET_IMAGES_REQUEST,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_FAIL,

} from '../constants/galleryConstant'

import {host} from "../../utils/config";



export const createGallery = (details) => async (dispatch) => {

    try {
        // console.log("create gallery")

        dispatch({ type: CREATE_GALLERY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.post(`${host}/web/api/v1/new/gallery`, details, config);

        dispatch({ type: CREATE_GALLERY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CREATE_GALLERY_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getGallerys = () => async (dispatch) => {
    try {
        dispatch({ type: GET_GALLERY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/admin/gallerys`, config);

        dispatch({ type: GET_GALLERY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_GALLERY_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getImages = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_IMAGES_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/images/${id}`, config);

        dispatch({ type: GET_IMAGES_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_IMAGES_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateGallery = (Details,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_GALLERY_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/update/gallery/${id}`, Details, config);

        dispatch({ type: UPDATE_GALLERY_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_GALLERY_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const deleteImage = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_IMAGE_REQUEST });

 const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/delete/image/${id}`,{}, config);

        dispatch({ type: DELETE_IMAGE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: DELETE_IMAGE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const deleteGallery = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_GALLERY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/delete/gallery/${id}`,{}, config);

        dispatch({ type: DELETE_GALLERY_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: DELETE_GALLERY_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const recoverGallery = (id) => async (dispatch) => {
    try {

        dispatch({ type: RECOVER_GALLERY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/recover/gallery/${id}`, {},config);

        dispatch({ type: RECOVER_GALLERY_SUCCESS, payload: data.success });

    } catch (error) {
        dispatch({
            type: RECOVER_GALLERY_FAIL,
            payload: error.response.data.message,
        });
    }
};




export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

