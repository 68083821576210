import { Helmet } from 'react-helmet-async';
import './userPage.css'
import { Fragment, useEffect, useState } from 'react';
import Pagination from "react-js-pagination";

import {
  Card,
  Stack,
  Button,
  Container,
  TextField,
  // Pagination,
  Box,
  Grid,
  CircularProgress,
  Divider,
  Modal,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  FormControl

} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { MdModeEdit } from 'react-icons/md';

// components
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
import { bulkImportMember, clearError, createUser, getAllUsers, welcomeNotification } from '../../redux/actions/userAction';
import { BULK_USERS_RESET, CREATE_USER_RESET } from '../../redux/constants/userConstant';
// import { round } from 'lodash';
import { getDate } from '../../utils/getDate';
import { WELCOME_NOTIFICATION_RESET } from '../../redux/constants/newsLetterConstant';



// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  // height: '90vh',
  overflow: 'auto',
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};


export default function UserPage() {



  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');


  const { loading, users, resultPerPage, totalUsers, error } = useSelector((state) => state.allUsers)


  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(totalUsers, resultPerPage, users)

  useEffect(() => {
    dispatch(getAllUsers(page, keyword))
  }, [dispatch, page])

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
  }, [error, navigate, dispatch])


  const { loading: cuLoading, success, error: cuErr } = useSelector((state) => state.createUser)

  useEffect(() => {
    if (success) {
      toast.success('invite Send successfully');
      dispatch({ type: CREATE_USER_RESET });
    }
    if (cuErr) {
      toast.error(cuErr);
      dispatch(clearError());
    }
  }, [success, error, navigate, dispatch])


  const inviteUserSubmitHandler = (e, id) => {
    e.preventDefault()
    dispatch(createUser({ id }))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, },
    { field: 'name', headerName: 'Name', width: 180 },
    { field: 'email', headerName: 'Email', width: 270 },
    {
      field: 'role',
      headerName: 'Role',
      width: 130,
    },
    {
      field: 'verified',
      headerName: 'Verified',
      width: 130,
      renderCell: (params) => {
        // console.log(params.row)
        return (
          <Fragment className="actionIconBox">
            <p className={params.row.verified}>
              {params.row.verified}
            </p>

          </Fragment>
        );
      },
    },

    {
      field: 'active',
      headerName: 'Active',
      width: 140,
      renderCell: (params) => {
        // console.log(params.row)
        return (
          <Fragment className="actionIconBox">
            <p className={params.row.active}>
              {params.row.active}
            </p>

          </Fragment>
        );
      },

    },
    { field: 'membershipStatus', headerName: 'membership Status', width: 170 },
    { field: 'expire', headerName: 'Membership Expired', width: 170 },
    { field: 'createAt', headerName: 'createdAt', width: 170 },

    {
      field: 'fullName',
      headerName: 'Action',
      width: 170,
      renderCell: (params) => {
        return (
          <div className="actionBoxe">

            <Link to={`/user/edit/${params.row?.id_}`}>
              <Iconify icon="akar-icons:edit" />
            </Link>



            <Link onClick={e => inviteUserSubmitHandler(e, params.row?.id_)} hidden={params.row.verified === 'Yes' ? 'true' : ''} >
              <Iconify icon="prime:send" />
            </Link>


          </div>
        );
      },
    }
  ];
  const rows = [];


  users?.forEach((item, i) => {
    const date = new Date(item?.createAt)

    rows.push({
      id: i + 1,
      id_: item._id,
      name: item.name,
      active: item.isActive ? 'Yes' : 'No',
      verified: item.verification === 'verified' ? 'Yes' : 'No',
      role: item.role,
      email: item.email,
      // createAt:date.toString().split('G')[0]
      createAt: getDate(item?.createAt),
      expire: item?.memberShipExpired ? getDate(item?.memberShipExpired) : '_',
      membershipStatus: item?.memberShipStatus === '0' ? 'Life time' : '1 Year'

    });
  });

  // searchHandler
  const searchHandler = (e) => {
    e.preventDefault();
    dispatch(getAllUsers(page, keyword))

  }
  const handlePage = (event, value) => {
    // console.log(value)
    setPage(value);
  };

  // if((totalUsers / resultPerPage)  0){

  const count = totalUsers / resultPerPage
  // }else{
  //   const count = Math.round(totalUsers / resultPerPage)  + 1           

  // }



  // -----------------------------Bulk Import Modal Code --------------------------------------


  const { loading: bulkLoading, success: bulkSuccess, error: BulkErr } = useSelector((state) => state.bulk)

  // console.log('import s')
  useEffect(() => {
    if (bulkSuccess) {
      toast.success('Import Success');
      dispatch({ type: BULK_USERS_RESET });
      BulkModalHandleClose()
      dispatch(getAllUsers(page, keyword))

    }
    if (BulkErr) {
      toast.error(BulkErr);
      dispatch(clearError());
    }
  }, [BulkErr, bulkSuccess, dispatch])



  const [file, setFile] = useState('');
  // const [profilePreview, setProfilePreview] = useState('');

  const [BulkModalOpen, setBulkModalOpen] = useState(false);

  const BulkModalHandleOpen = () => {
    setBulkModalOpen(true)
  };
  const BulkModalHandleClose = () => {
    setBulkModalOpen(false);
  }

  const newBulkSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(bulkImportMember({ file }))

  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // setfileName(file?.name)

    if (file) {
      if (file.size <= 1 * 1024 * 1024) {

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          setFile(e.target.result);

          // console.log(selectedFile)
        }
      } else {
        event.target.value = null;
        setFile(null);
        alert("File size exceeds the limit of 1 MB.");
      }
    } else {
      event.target.value = null;
      setFile(null);
      alert("Please select an profile picture.");
    }

  };
  // ----------------- Admin Authentication -----------------------------------------
  const { user } = useSelector((state) => state.users)
  if (user) {
    if (user.role !== 'admin') {
      navigate('/dashboard/app')
    }
  } else {
    navigate('/')
  }



  // send mail - modal code ---------------------------------
  // const [updateItem, setUpdateItem] = useState();

  const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);
  const [role, setRole] = useState('');
  const [emails, setEmails] = useState('');

  // let emailItem = {}

  const recoverMemberModalHandleOpen = () => {
    setRecoverMemberModalOpen(true)
  };

  const recoverMemberModalHandleClose = () => {
    setRecoverMemberModalOpen(false);
  }


  const mailSubmitHandler = (e) => {
    e.preventDefault()
    const emailData = { role, emails }
    // console.log(emailData)
    dispatch(welcomeNotification(emailData));
  }

  const mailHandle = async (item) => {
    // setUpdateItem(item)
    recoverMemberModalHandleOpen()

  }

  const { loading: mailLoading, success: mailSuccess, error: mailErr } = useSelector((state) => state.notification)

  useEffect(() => {
    if (mailSuccess) {
      toast.success('Mail send  SuccessFully');
      recoverMemberModalHandleClose()
      dispatch({ type: WELCOME_NOTIFICATION_RESET });


    }
    if (mailErr) {
      toast.error(mailErr);
      dispatch(clearError());
    }
  }, [mailErr, mailSuccess, dispatch])
  // ----------------- Admin Authentication end -----------------------------------------

  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div className='totalComponents'>
            <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="Total Users" total={totalUsers} icon={'ph:users-fill'} />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="InActive Users" total={1} color="info" icon={'ph:users-fill'} />
            </Grid> */}
          </div>

          <Stack direction={'row'} spacing={2}>
            <Link to={'/user/new'}>
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                New User
              </Button>
            </Link>
            {/* <Link to={'/user/new'}> */}
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={BulkModalHandleOpen}>
              Bulk Import
            </Button>

            {/* </Link> */}
            <a href={'https://res.cloudinary.com/dwevw3pnz/raw/upload/v1703309145/Eoai-QR-Code/eoai-members_u1pjvy.xlsx'} download={'excelMember'}>
              <Button variant="contained" startIcon={<Iconify icon="eva:cloud-download-fill" />}>
                Excelsheet
              </Button>
            </a>
          </Stack>
        </Stack>

        <Card>
          <div className='tableTop'>
            <form onSubmit={searchHandler}>
              <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
            </form>

            <div hidden={!cuLoading ? 'true' : ''}>
              <CircularProgress color="primary" />
            </div>
            <Button  size='small'  onClick={e => mailHandle()}>
              Send welcome Notification
            </Button>

          </div>
          <div style={{ height: 'auto', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // checkboxSelection
              pagination={false}
              sx={
                {
                  // padding:'0 1vmax'
                }
              }
            />
          </div>

          <div className='paginationBox' >
            {
              totalUsers > resultPerPage && <Pagination
                activePage={page}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={totalUsers}
                pageRangeDisplayed={5}
                onChange={e => setPage(e)}
              />
            }

          </div>
        </Card>
      </Container>


      {/* New Bulk  modal code  */}
      <div>
        <Modal
          open={BulkModalOpen}
          onClose={BulkModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} >
            <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Bulk Import Members
              </Typography>
              <Button onClick={BulkModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
            </Stack>

            <Divider />

            <form action="" onSubmit={newBulkSubmitHandler}>

              <p>Select Excel File</p>
              <Stack spacing={3} margin='1vmax 0'>
                <input
                  className="inpProfileBtn"
                  required
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleFileChange}
                  name="profile"
                  placeholder="Logo"
                />
              </Stack>

              {bulkLoading ?

                (<LoadingButton fullWidth size="large" variant="text" >
                  <CircularProgress color='success' />
                </LoadingButton>)
                :

                (<LoadingButton fullWidth disabled={!file} size='medium' type="submit" variant="contained" >
                  create
                </LoadingButton>)

              }
            </form>
          </Box>
        </Modal>
      </div>

      {/* send mail  modal code  */}
      <div>
        <Modal
          open={recoverMemberModalOpen}
          onClose={recoverMemberModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} >
            <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Send mail notification
              </Typography>
              <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >Hide</Button>
            </Stack>

            <Divider />

            <form action="" onSubmit={mailSubmitHandler}>
              <Stack spacing={3} margin='1vmax 0'>

                <FormControl>

                  <InputLabel id="demo-simple-select-label">Select Users to send mail</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={role}
                    required
                    label="Select Users to send mail"
                    // placeholder='Select'
                    onChange={e => setRole(e.target.value)}
                  >
                    <MenuItem value={''}><em>none</em></MenuItem>
                    <MenuItem value={'custom'}>Custom</MenuItem>
                    <MenuItem value={'member'}>Member</MenuItem>
                    <MenuItem value={'non-member'}>Non Member</MenuItem>
                    <MenuItem value={'all'}>All</MenuItem>
                  </Select>
                </FormControl>

              </Stack>

              {
                role === 'custom' ?
                <>
                <p>Please Verify emails is Registered in portal or not (Manually by Search) before send mail.</p>
                  <TextField
                    fullWidth
                    id=""
                    sx={{ margin: '0.5vmax 0' }}
                    label="Type Emails"
                    required
                    value={emails}
                    onChange={e => setEmails(e.target.value)}

                
                /> </>    : ''
              }





              {mailLoading ?

                (<LoadingButton fullWidth size="large" variant="text" >
                  <CircularProgress color='success' />
                </LoadingButton>)
                :

                (<LoadingButton m={2} fullWidth size='medium' type="submit" variant="contained" >
                  Send mail
                </LoadingButton>)

              }
            </form>
          </Box>
        </Modal >
      </div >


    </>
  );
}
