import { Helmet } from 'react-helmet-async';
import './UserNew.css'
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, IconButton, InputAdornment, TextField, CircularProgress, Box, MenuItem, OutlinedInput, useTheme, InputLabel, FormControl } from '@mui/material';
import Select from '@mui/material/Select';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


import useResponsive from '../../hooks/useResponsive';

// components
import { clearError, createUser, getUserDetails, loadUser, login, updateUser } from '../../redux/actions/userAction';
import logo from '../../images/icons/logo.png'
import inviteUserImg from '../../images/image/invite-user.avif'

import { CLEAR_ERROR, CREATE_USER_RESET, UPDATE_USER_RESET } from '../../redux/constants/userConstant';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');


    // ----------------------------------------------------------------------
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [membershipStatus, setmembershipStatus] = useState('')
    const [memberShipExpired, setexpiredDate] = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()

    const { loading, isUpdated, error } = useSelector((state) => state.profile)
    const { loading: userLoading, user, error: userErr } = useSelector((state) => state.getUser)

    useEffect(() => {
        if (isUpdated) {
            toast.success('Updated successfully');
            navigate(-1);
            dispatch({ type: UPDATE_USER_RESET });
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
        if (userErr) {
            toast.error(userErr);
            dispatch(clearError());
        }


    }, [isUpdated, error, navigate, dispatch])

    useEffect(() => {
        dispatch(getUserDetails(id))
    }, [userErr])

    useEffect(() => {

        setName(user?.name)
        setRole(user?.role)
        setactive(user?.isActive)
        setmembershipStatus(user?.memberShipStatus)
        setexpiredDate(user?.memberShipExpired)
    }, [userLoading])


    const updateSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser(id, { name, active, role, memberShipExpired, membershipStatus }))
    }

    const [active, setactive] = useState('')

    const handleChange = (event) => {
        setactive(event.target.value);
    };


    return (
        <>
            <Helmet>
                <title> Update User  | EOAI DASHBOARD </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    <img src={logo} width={30} alt="EOAI LOGO" />
                </div>
                {mdUp && (
                    <StyledSection>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>

                        <Typography variant="h4" gutterBottom>
                            Update  / Edit
                            <Typography variant="h6" gutterBottom>
                                {user?.email}
                            </Typography>
                        </Typography>


                        {/* <Divider sx={{ my: 4 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider> */}

                        {/* new user Form  */}
                        <>
                            <form action="" onSubmit={updateSubmitHandler}>
                                <Stack spacing={3} paddingY={4}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />

                                    <Stack spacing={1}>
                                        <InputLabel id="demo-controlled-open-select-label">Select Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={role}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setRole(e.target.value)}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'super-admin'}>super-admin</MenuItem>
                                            <MenuItem value={'admin'}>admin</MenuItem>
                                            <MenuItem value={'member'}>Member</MenuItem>
                                            <MenuItem value={'non-member'}>Non Member</MenuItem>
                                            {/* <MenuItem value={'user'}>user</MenuItem> */}
                                        </Select>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <InputLabel id="demo-controlled-open-select-label">User Active</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={active}
                                            // label="Age"
                                            displayEmpty
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>
                                </Stack>

                                <FormControl fullWidth >
                                    <InputLabel id="demo-controlled-open-select">Membership Status</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={membershipStatus}
                                        label="Ro space space space"
                                        placeholder='None'
                                        onChange={e => setmembershipStatus(e.target.value)}
                                        // color='success'
                                        fullWidth

                                    >
                                        <MenuItem key={role} value={''} selected><em>None</em></MenuItem>
                                        <MenuItem key={role} value={'1'} selected><em>1 Year</em></MenuItem>
                                        <MenuItem key={role} value={'0'} selected><em>Lifetime</em></MenuItem>

                                    </Select>
                                </FormControl>
                                <Stack spacing={1} style={{ margin: '1vmax 0' }}>
                                    <InputLabel id="demo-controlled-open-select">Membership Expired </InputLabel>
                                    <TextField name="date"
                                        type='date'
                                        onChange={e => setexpiredDate(e.target.value)}
                                    />
                                </Stack>

                                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                    <Checkbox name="remember"label="Remember me" /> 
                                    <Link variant="subtitle2" underline="hover">
                                        Forgot password?
                                    </Link>
                                </Stack> */}

                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        UPDATE
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot >
        </>
    );
}
