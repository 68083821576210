import axios from "axios";
import {
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAIL,

    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAIL,

    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_FAIL,

    UPDATE_EVENT_REQUEST,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,

    HIDE_EVENT_FOR_WEB_REQUEST,
HIDE_EVENT_FOR_WEB_SUCCESS,
HIDE_EVENT_FOR_WEB_FAIL,

HIDE_EVENT_FOR_PORTAL_REQUEST,
HIDE_EVENT_FOR_PORTAL_SUCCESS,
HIDE_EVENT_FOR_PORTAL_FAIL,

    CLEAR_ERROR,
    GET_EVENTS_PORTAL_REQUEST,
    GET_EVENTS_PORTAL_SUCCESS,
    GET_EVENTS_PORTAL_FAIL,

} from '../constants/eventsConstant'

import {host} from "../../utils/config";



export const createEvent = (details) => async (dispatch) => {

    try {
        // console.log("create Event")

        dispatch({ type: CREATE_EVENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/events/api/v1/event/new`, details, config);

        dispatch({ type: CREATE_EVENT_SUCCESS, payload: data.data.user });

    } catch (error) {
        dispatch({
            type: CREATE_EVENT_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getEvents = () => async (dispatch) => {
    try {
        dispatch({ type: GET_EVENTS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/events`, config);

        dispatch({ type: GET_EVENTS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_EVENTS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getEventsForPortal = () => async (dispatch) => {
    try {
        dispatch({ type: GET_EVENTS_PORTAL_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/events-portal`, config);

        dispatch({ type: GET_EVENTS_PORTAL_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_EVENTS_PORTAL_FAIL,
            payload: error.response.data.message,
        });
    }
};

// export const getEventsForWebsite = () => async (dispatch) => {
//     try {
//         dispatch({ type: GET_EVENTS_WEBSITE_REQUEST });
//         const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

//         const {data} = await axios.get(`${host}/events/api/v1/events-website`, config);

//         dispatch({ type: GET_EVENTS_WEBSITE_SUCCESS, payload: data });

//     } catch (error) {
//         dispatch({
//             type: GET_EVENTS_WEBSITE_FAIL,
//             payload: error.response.data.message,
//         });
//     }
// };

export const getEvent = (id) => async (dispatch) => {
    // console.log(id,userId,role)
    try {
        dispatch({ type: GET_EVENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/event/${id}`, config);

        dispatch({ type: GET_EVENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_EVENT_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateEvents = (memberDetails,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_EVENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/event/update/${id}`, memberDetails, config);

        dispatch({ type: UPDATE_EVENT_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_EVENT_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const hideWebEvents = (id,active) => async (dispatch) => {
    try {

        dispatch({ type: HIDE_EVENT_FOR_WEB_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/delete/member/${id}`,{active}, config);

        dispatch({ type: HIDE_EVENT_FOR_WEB_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: HIDE_EVENT_FOR_WEB_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const hidePortalEvents = (id,active) => async (dispatch) => {
    try {

        dispatch({ type: HIDE_EVENT_FOR_PORTAL_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/delete/member/${id}`,{active}, config);

        dispatch({ type: HIDE_EVENT_FOR_PORTAL_SUCCESS, payload: data.success });

    } catch (error) {
        dispatch({
            type: HIDE_EVENT_FOR_PORTAL_FAIL,
            payload: error.response.data.message,
        });
    }
};





export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

