import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../images/icons/logo.png';
// import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

import EmailVerifyForm from '../components/EmailVerifyForm';
import RegistrationForm from '../components/RegistrationForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  const [user, setUser] = useState()
  useEffect(() => {

  
  }, [user])
  


  return (
    <>
      <Helmet>
        <title> Login | EOAI DASHBOARD </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection>
            <Typography variant="h3"  sx={{ px: 5, mt: 0, mb: 5 , textAlign:'center' }}>
              Hi, Welcome Back
            </Typography>
            <img src={Logo} alt="login" width={200} style={{margin:'1vmax auto'}} />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" sx={{ my: 3 }} gutterBottom>
              Sign in to EOAI Dashboard
            </Typography>

            {/* 1st Step */}
            {
              !user && <EmailVerifyForm setUser={setUser} />
            }

            {/* 2st Step */}
            {
              user && user.firstLogin === 'no' ?
                <>
                  <LoginForm user={user} />
                </> : ''
            }
          
            {/* 3st Step */}
            {
              user && user.firstLogin === 'yes' ?
              <>
              <RegistrationForm setUser={setUser} user={user}  />
                </> : ''
            }

          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
