import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {  Stack,  TextField, CircularProgress, Box,  Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from '@mui/material/Modal';

import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
// components
import { clearError, forgetPassword, loadUser, login, memberEmailVerify } from '../redux/actions/userAction';
import { EMAIL_VERIFY_RESET, FORGOT_PASSWORD_RESET } from '../redux/constants/userConstant';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function LoginForm({setUser}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setemail] = useState(null)
    const { loading, user, success, error } = useSelector((state) => state.emailVerify)
    const {  isAuthenticated } = useSelector((state) => state.users)


    useEffect(() => {
        dispatch(loadUser())

        if (isAuthenticated  ) {
            // toast.success('Log in')
            navigate('/dashboard/app')
        }
    }, [dispatch,isAuthenticated])

    useEffect(() => {

        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
        if(success){
            setUser(user)
            dispatch({type:EMAIL_VERIFY_RESET})
            
        }

    }, [error,user, navigate, dispatch])

    const loginSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(memberEmailVerify({email}))
    }

    

   

   

    return (
        <>
            <form action="" onSubmit={loginSubmitHandler}>
                <Stack spacing={3}>

                    <TextField name="email"
                        onChange={e => setemail(e.target.value)}
                        required label="Email address" />

                </Stack>

                <br />

                {loading ?

                    (<LoadingButton fullWidth size="large" variant="text" >
                        <CircularProgress color='success' />
                    </LoadingButton>)
                    :

                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                        Login
                    </LoadingButton>)

                }
            </form>


           
        </>
    );
}
