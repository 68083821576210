import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';
import { FaCheckCircle, FaHome } from 'react-icons/fa';
import { MdCancel, MdOutlineErrorOutline } from 'react-icons/md';

import { clearError, getRenewPaymentAction } from '../redux/actions/paymentAction';
// import { useState } from 'react';

const PaymentStatusPage = () => {


  const [paymentDetails, setPaymentDetails] = useState(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();


  const { loading, payment, error } = useSelector((state) => state.payment)

  useEffect(() => {
    dispatch(getRenewPaymentAction(id))
  }, [dispatch])

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
  }, [error, navigate, dispatch])

  console.log(paymentDetails)

  useEffect(() => {
    if (payment?.paymentDetails) {
      const data = JSON.parse(payment?.paymentDetails)
      setPaymentDetails(data)
    }
  }, [payment])

  return (
    <div>
      {
        paymentDetails?.order_status === 'Success' ?
          <div>
            <Stack width={'100%'} spacing={2} alignItems={'center'} padding={10} >
              <FaCheckCircle size={50} color='green' />
              <Typography variant="h4" color="">{paymentDetails?.status_message}</Typography>

              <Stack width={'40%'} spacing={1} alignItems={'center'} borderRadius={2} padding={4} border={1}>
                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Email</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.billing_email}</Typography>
                </Stack>
                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Amount</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.mer_amount}</Typography>
                </Stack>

                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Bank Ref No</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.bank_ref_no}</Typography>
                </Stack>

                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Order Id</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.order_id}</Typography>
                </Stack>

                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Payment mode</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.payment_mode}</Typography>
                </Stack>
                <Stack width={'100%'} direction={'row'} spacing={3} justifyContent={'space-between'} >
                  <Typography variant="p" width={'48%'} textAlign={'right'} color="">Date</Typography>
                  <Typography variant="p" width={'48%'} textAlign={'left'} color="">{paymentDetails?.trans_date}</Typography>
                </Stack>
                
              </Stack>
                <Link to={'/dashboard/app'}><Button  variant='contained' ><FaHome style={{marginRight:5}}/> Home</Button></Link>
            </Stack>
          </div> : <div>
            {
              paymentDetails?.order_status === 'Failure' ?
                // Failed Html
                <div>
                  <Stack width={'100%'} spacing={2} alignItems={'center'} padding={10}>
                    <MdCancel size={50} color='red' />
                    <Typography variant="h4" color="">{paymentDetails?.status_message}</Typography>
                    <Typography variant="h6" color="">{paymentDetails?.failure_message}</Typography>
                    <Link to={'/dashboard/app'}><Button >Home</Button></Link>

                  </Stack>
                </div> :
                // Some thing went worng Html

                <div>
                  <Stack width={'100%'} spacing={2} alignItems={'center'} padding={10}>
                    <MdOutlineErrorOutline size={50} color='red' />
                    <Typography variant="h4" color="">Some thing went Wrong</Typography>
                    <Link to={'/dashboard/app'}><Button >Home</Button></Link>
                  </Stack>
                </div>
            }
          </div>
      }
    </div>

  )
}

export default PaymentStatusPage