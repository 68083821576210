export const CREATE_EXE_COMMITTEE_REQUEST = "CREATE_EXE_COMMITTEE_REQUEST"
export const CREATE_EXE_COMMITTEE_SUCCESS = "CREATE_EXE_COMMITTEE_SUCCESS"
export const CREATE_EXE_COMMITTEE_FAIL = "CREATE_EXE_COMMITTEE_FAIL"
export const CREATE_EXE_COMMITTEE_RESET = "CREATE_EXE_COMMITTEE_RESET"

export const GET_EXE_COMMITTEE_REQUEST = "GET_EXE_COMMITTEE_REQUEST"
export const GET_EXE_COMMITTEE_SUCCESS = "GET_EXE_COMMITTEE_SUCCESS"
export const GET_EXE_COMMITTEE_FAIL = "GET_EXE_COMMITTEE_FAIL"

export const UPDATE_EXE_COMMITTEE_REQUEST = "UPDATE_EXE_COMMITTEE_REQUEST"
export const UPDATE_EXE_COMMITTEE_SUCCESS = "UPDATE_EXE_COMMITTEE_SUCCESS"
export const UPDATE_EXE_COMMITTEE_FAIL = "UPDATE_EXE_COMMITTEE_FAIL"
export const UPDATE_EXE_COMMITTEE_RESET = "UPDATE_EXE_COMMITTEE_RESET"


export const DELETE_EXE_COMMITTEE_REQUEST = "DELETE_EXE_COMMITTEE_REQUEST"
export const DELETE_EXE_COMMITTEE_SUCCESS = "DELETE_EXE_COMMITTEE_SUCCESS"
export const DELETE_EXE_COMMITTEE_FAIL = "DELETE_EXE_COMMITTEE_FAIL"
export const DELETE_EXE_COMMITTEE_RESET = "DELETE_EXE_COMMITTEE_RESET"


export const RECOVER_EXE_COMMITTEE_REQUEST = "RECOVER_EXE_COMMITTEE_REQUEST"
export const RECOVER_EXE_COMMITTEE_SUCCESS = "RECOVER_EXE_COMMITTEE_SUCCESS"
export const RECOVER_EXE_COMMITTEE_FAIL = "RECOVER_EXE_COMMITTEE_FAIL"
export const RECOVER_EXE_COMMITTEE_RESET = "RECOVER_EXE_COMMITTEE_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"