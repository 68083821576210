export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST"
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS"
export const CREATE_EVENT_FAIL = "CREATE_EVENT_FAIL"
export const CREATE_EVENT_RESET = "CREATE_EVENT_RESET"

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL"

export const GET_EVENTS_PORTAL_REQUEST = "GET_EVENTS_PORTAL_REQUEST"
export const GET_EVENTS_PORTAL_SUCCESS = "GET_EVENTS_PORTAL_SUCCESS"
export const GET_EVENTS_PORTAL_FAIL = "GET_EVENTS_PORTAL_FAIL"

export const GET_EVENTS_WEBSITE_REQUEST = "GET_EVENTS_WEBSITE_REQUEST"
export const GET_EVENTS_WEBSITE_SUCCESS = "GET_EVENTS_WEBSITE_SUCCESS"
export const GET_EVENTS_WEBSITE_FAIL = "GET_EVENTS_WEBSITE_FAIL"

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST"
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS"
export const GET_EVENT_FAIL = "GET_EVENT_FAIL"

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST"
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS"
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL"
export const UPDATE_EVENT_RESET = "UPDATE_EVENT_RESET"


export const HIDE_EVENT_FOR_WEB_REQUEST = "HIDE_EVENT_FOR_WEB_REQUEST"
export const HIDE_EVENT_FOR_WEB_SUCCESS = "HIDE_EVENT_FOR_WEB_SUCCESS"
export const HIDE_EVENT_FOR_WEB_FAIL = "HIDE_EVENT_FOR_WEB_FAIL"
export const HIDE_EVENT_FOR_WEB_RESET = "HIDE_EVENT_FOR_WEB_RESET"

export const HIDE_EVENT_FOR_PORTAL_REQUEST = "HIDE_EVENT_FOR_PORTAL_REQUEST"
export const HIDE_EVENT_FOR_PORTAL_SUCCESS = "HIDE_EVENT_FOR_PORTAL_SUCCESS"
export const HIDE_EVENT_FOR_PORTAL_FAIL = "HIDE_EVENT_FOR_PORTAL_FAIL"
export const HIDE_EVENT_FOR_PORTAL_RESET = "HIDE_EVENT_FOR_PORTAL_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"