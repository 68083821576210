import { FaHelicopter, FaQuestion } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, CircularProgress, List, ListItemText, Modal, Stack ,Typography} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
// import data from '../../layouts/dashboard/nav/config';
// ----------------------------------------------------------------------
// Nav Config Data 


import event from '../../images/icons/ic_event.png';
import news from '../../images/icons/news-177.svg';
import directory from '../../images/icons/new-directory.svg';
import team from '../../images/icons/team.svg';
import gallery from '../../images/icons/gallery.png';
import magazine from '../../images/icons/magazine.png';
import newspaper from '../../images/icons/newspaper.png';
import booking from '../../images/icons/booking-history.png';
import users from '../../images/icons/users-m.svg';
import analitics from '../../images/icons/analytics.svg';
import orgs from '../../images/icons/orgs.svg';

// component
import SvgColor from '../svg-color';
import { queryAction } from '../../redux/actions/contactAction';
import { CREATE_QUERY_RESET } from '../../redux/constants/contactConstant';
import { clearError } from '../../redux/actions/userAction';
// import { da } from 'date-fns/locale';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={name} sx={{ width: 1, height: 1 }} />;


NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ dat = [], ...other }) {

  const { loading, isAuthenticated, user, error } = useSelector((state) => state.users)
  // console.log(user)
  let data = [

    (user?.role === 'admin' || user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: icon('/assets/icons/navbar/ic_analytics.svg'),
    } : '',
    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'Newsletter',
      path: '/dashboard/magazines',
      icon: icon(magazine),
    } : '',
    (user?.role === 'admin') ?
      {
        title: 'user',
        path: '/dashboard/user',
        icon: icon('/assets/icons/navbar/ic_user.svg'),


      } : '',
    (user?.role === 'admin') ?
      {
        title: 'Events ',
        path: '/dashboard/events',
        icon: icon(event),


      } : '',
    (user?.role === 'admin') ?
      {
        title: 'Workshop',
        path: '/dashboard/workshop',
        icon: icon(event),


      } : '',

    (user?.role === 'member' || user?.role === 'non-member') ?
      {
        title: 'Workshop',
        path: '/dashboard/all-workshop',
        icon: icon(event),


      } : '',
      (user?.role === 'member' || user?.role === 'non-member') ?
      {
        title: 'My Workshop Bookings',
        path: '/dashboard/my-workshop-booking',
        icon: icon(event),


      } : '',
      

    (user?.role === 'admin') ? {
      title: 'Manage Gallery',
      path: 'gallery',
      icon: icon(gallery),
    } : '',

    (user?.role === 'admin') ? {
      title: 'News/Updates',
      path: '/dashboard/news',
      icon: icon(newspaper),
    } : '',
    (user?.role === 'admin') ? {
      title: 'Newsletter',
      path: '/dashboard/news-letter',
      icon: icon(magazine),
    } : '',

    (user?.role === 'admin') ? {
      title: 'Organization Directory',
      path: '/dashboard/organization',
      icon: icon(directory),

    } : '',
    (user?.role === 'admin') ? {
      title: 'Executive Committee',
      path: '/dashboard/team',
      icon: icon(team),
    } : '',


    // member view 
    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'Events',
      path: 'events-booking',
      icon: icon(event),

    }:'',

    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'My Event Bookings',
      path: 'mybookings',
      icon: icon(booking),

    }:'',

    // (user?.role === 'member' || user?.role === 'non-member') ? {
    //   title: 'Gallery',
    //   path: 'gallery-v',
    //   icon: icon(event),

    // }:'',

    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'Executive committee',
      path: 'team-v',
      icon: icon(team),

    } : '',

    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'organization directory',
      path: 'organization-v',
      icon: icon(directory),

    } : '',

    (user?.role === 'admin') ?
      {
        title: 'Contact enquiry',
        path: '/dashboard/website-contact-form',
        icon: icon('/assets/icons/navbar/ic_user.svg'),


      } : '',


    (user?.role === 'admin') ? {
      title: 'Portal analytics',
      path: '/dashboard/track',
      icon: icon(analitics),
    } : '',
    
    // (user?.role === 'member' || user?.role === 'non-member') ? {
    //   title: 'My Membership',
    //   path: 'renew-or-certificate',
    //   icon: icon(users),
    // } : '',
    (user?.role === 'member' || user?.role === 'non-member') ? {
      title: 'My Organization',
      path: 'my-organization',
      icon: icon(orgs),

    } : '',

    (user?.role === 'member' || user?.role === 'non-member') ?
      {
        title: 'Payments History',
        path: '/renew/payment/history',
        icon: icon(event),


      } : '',
  ]

  // console.log(data)
  data = data.filter((a) => a !== '');
  // console.log(data)
  // help desk Modal --------------------------------------------- 

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [nameHelp, setNameHelp] = useState(null)
  const [phoneHelp, setPhoneHelp] = useState(null)
  const [emailHelp, setEmailHelp] = useState(null)
  const [queryHelp, setQueryHelp] = useState(null)



  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading: Helploading, success: helpSuccess, error: HelpErr } = useSelector((state) => state.contact)

  useEffect(() => {
    if (HelpErr) {
      alert(HelpErr)
      clearError()
    }
    if (helpSuccess) {
      alert('Your message was sent successfully.');
      setNameHelp('')
      setEmailHelp('')
      setPhoneHelp('')
      setQueryHelp('')
      handleCloseHelp()
      dispatch({ type: CREATE_QUERY_RESET })
    }
  }, [helpSuccess, HelpErr])


  const queryHandler = (e) => {
    // console.log('query............')
    e.preventDefault()
    dispatch(queryAction({ name: nameHelp, email: emailHelp, phone: phoneHelp, query: queryHelp }))
  }


  return (
    <>
      <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
          {data.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}

          <Stack direction={'row'} pt={0} alignItems={'center'} justifyContent={'center'}>
            <button onClick={handleOpenHelp}  style={{width:'100%', backgroundColor:'transparent' , border:'none',padding:'1vmax', font:'300 1vmax gray', color:'grey', textAlign:'start'}}  ><FaQuestion style={{marginRight:'1vmax'}} size={18} color='gray' /> Help Desk</button>
          </Stack>

        </List>
      </Box>

      {/* Modal */}
      <div>
        <Modal
          open={openHelp}
          onClose={handleCloseHelp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box id='modalBox'>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Help Desk
            </Typography>

            <div className='helpFormBox'>
              <form action="" onSubmit={queryHandler}>
                <input type="text" placeholder='Name' required name="" id="" value={nameHelp} onChange={e => setNameHelp(e.target.value)} />
                <input type="email" placeholder='Email' required name="" id="" value={emailHelp} onChange={e => setEmailHelp(e.target.value)} />
                <input type="number" placeholder='Phone ' required name="" id="" value={phoneHelp} onChange={e => setPhoneHelp(e.target.value)} />
                <textarea className='textArea' name="" required placeholder='Query' id="" value={queryHelp} onChange={e => setQueryHelp(e.target.value)} cols="20" rows="5" />

                {
                  Helploading ? <div style={{ textAlign: 'center' }}> <CircularProgress color="success" /></div> :
                    <Button type='submit' variant="contained" placeholder='Send'   >Send</Button>
                }

              </form>

            </div>

          </Box>
        </Modal>
      </div>
    </>

  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
