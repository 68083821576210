import { Helmet } from 'react-helmet-async';
import './EventsPage.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel, FormControlLabel, Radio,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AiTwotoneEdit, AiFillEye } from 'react-icons/ai'
import { FaLock, FaStreetView, FaUnlock } from 'react-icons/fa'
// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../sections/@dashboard/app';
import Iconify from '../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { CREATE_EVENT_RESET } from '../redux/constants/eventsConstant';
import { clearError, createEvent, getEvents, updateEvents } from '../redux/actions/eventsAction';
import EventsBooking from './EventsBooking';
import EventDetail from './EventDetail';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const viewEventStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
}
const stackStyle = {
    width: '48%',
}
const NewEventStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}
const NewEventInput = {
    width: '100%',

}
export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEvents(keyword, page))
    }, [dispatch, page])

    const { loading: getLoading, events, totalBoardEvents, totalEvents, error: getErr } = useSelector((state) => state.getEvents)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getEvents(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };

    // if((totalUsers / resultPerPage) ===  0){
    // const count = totalUsers / resultPerPage
    // }else{
    //   const count = Math.round(totalUsers / resultPerPage)  + 1           
    // }

    // -----------------------------new Event Modal Code --------------------------------------

    const { loading: createEventLoading, success: createEventSuccess, error } = useSelector((state) => state.actionEvents)

    useEffect(() => {
        if (createEventSuccess) {
            toast.success('updated success');
            EventModalHandleClose()
            updateEventModalHandleClose()
            // deleteEventModalHandleClose()
            // recoverEventModalHandleClose()
            dispatch({ type: CREATE_EVENT_RESET });
            dispatch(getEvents(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createEventSuccess, dispatch])


    const [eventName, setEventName] = useState('');
    const [eventDate, setEventdate] = useState('');
    const [bookingEndDate, setBookingEndDate] = useState('');
    const [eventRegistrationfee, setEventRegistrationfee] = useState('');
    const [sponsers, setSponsers] = useState('');
    const [activeForMember, setActiveForMember] = useState('');
    const [activeForWebsite, setActiveForWebsite] = useState('');
    const [totalSeats, setTotalSeats] = useState('');
    const [MarketingStatus, setMarketingStatus] = useState('');

    const [memberPrice, setmemberPrice] = useState('');
    const [nonMemberPrice, setnonMemberPrice] = useState('');
    const [spouse, setspouse] = useState('');
    const [child1Price, setchild1] = useState('');
    const [child2Price, setchild2] = useState('');

    const [memberScientist, setmemberScientist] = useState('');

    const [ForeignPrice, setForeignPrice] = useState('')


    const [banner, setBanner] = useState('');

    const [bannerPreview, setBannerPreview] = useState('');
    const [EventModalOpen, setEventModalOpen] = useState(false);

    const EventModalHandleOpen = () => {
        setEventModalOpen(true)
    };
    const EventModalHandleClose = () => {
        setEventModalOpen(false);
    }

    const newEventSubmitHandler = (e) => {
        e.preventDefault()

        if (memberPrice < 1 || nonMemberPrice < 1  || child2Price < 1 || child1Price < 1 || spouse < 1 || memberScientist < 1 || ForeignPrice < 1) {
            toast.warn('Price can not accept less than 1 rupee ')

        } else {

            dispatch(createEvent({
                event_name: eventName,
                event_date: eventDate,
                booking_end_date: bookingEndDate,
                event_registration_fee: eventRegistrationfee,
                banner, sponsers, totalSeats, MarketingStatus,
                memberPrice, nonMemberPrice, child2Price, child1Price,
                spouse, memberScientist, ForeignPrice
            }))
        }

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setBanner(e.target.result);
                    setBannerPreview(e.target.result);

                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setBanner(null);
                setBannerPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setBanner(null);
            setBannerPreview(null);
            alert("Please select an profile picture.");
        }

    };


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();

    const [eventUpdateName, setUpdateEventName] = useState('');
    const [eventUpdateDate, setUpdateEventdate] = useState('');
    const [updateBookingEndDate, setUpdateBookingEndDate] = useState('');

    const [UpdateEventRegistrationfee, setUpdateEventRegistrationfee] = useState('');
    const [UpdateSponsers, setUpdateSponsers] = useState('');
    const [UpdateActiveForMember, setUpdateActiveForMember] = useState('');
    const [UpdateActiveForWebsite, setUpdateActiveForWebsite] = useState('');
    const [UpdateTotalSeats, setUpdateTotalSeats] = useState('');
    const [UpdateMarketingStatus, setUpdateMarketingStatus] = useState('');

    const [UpdatememberPrice, setUpdatememberPrice] = useState('');
    const [UpdatenonMemberPrice, setUpdatenonMemberPrice] = useState('');
    const [Updatespouse, setUpdatespouse] = useState('');
    const [Updatechild1Price, setUpdatechild1] = useState('');
    const [Updatechild2Price, setUpdatechild2] = useState('');

    const [UpdatememberScientist, setUpdatememberScientist] = useState('');

    const [UpdateForeignPrice, setUpdateForeignPrice] = useState('')


    const [UpdateBannerPreview, setUpdateBannerPreview] = useState('');

    // modal
    const [updateEventModalOpen, setUpdateEventModalOpen] = useState(false);

    const updateEventModalHandleOpen = () => {
        setUpdateEventModalOpen(true)
    };
    const updateEventModalHandleClose = () => {
        setUpdateEventModalOpen(false);
    }

    // form submit 
    const updateEventSubmitHandler = (e) => {
        e.preventDefault()
        if (UpdatememberPrice < 1 || UpdatenonMemberPrice < 1 || Updatechild2Price < 1 || Updatechild1Price < 1 || Updatespouse < 1  || UpdatememberScientist < 1 || UpdateForeignPrice < 1) {
            toast.warn('Price can not accept less than 1 rupee ')

        } else {
            const EventDetails = {
                event_name: eventUpdateName, event_date: eventUpdateDate, event_registration_fee: UpdateEventRegistrationfee, sponsers: UpdateSponsers, activeForMember: UpdateActiveForMember, activeForWebsite: UpdateActiveForWebsite, totalSeats: UpdateTotalSeats, MarketingStatus: UpdateMarketingStatus, banner: UpdateBannerPreview,
                memberPrice: UpdatememberPrice, nonMemberPrice: UpdatenonMemberPrice, child2Price: Updatechild2Price, child1Price: Updatechild1Price,
                spouse: Updatespouse, memberScientist: UpdatememberScientist, ForeignPrice: UpdateForeignPrice, booking_end_date: updateBookingEndDate
            }

            dispatch(updateEvents(EventDetails, updateItem?._id))
        }
    }

    const updateHandleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateBannerPreview(e.target.result);
                    // setUpdateProfilePreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateBannerPreview(null);
                // setUpdateProfilePreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateBannerPreview(null);
            // setUpdateProfilePreview(null);
            alert("Please select an profile picture.");
        }

    };

    const editHandle = (item) => {
        console.log(item)
        setUpdateItem(item)

        setUpdateEventName(item.event_name)
        setUpdateEventdate(item.event_date)
        setUpdateBookingEndDate(item.booking_end_date)
        setUpdateActiveForMember(item.activeForMember)
        setUpdateActiveForWebsite(item.activeForWebsite)
        // setUpdateBannerPreview(item.eve)
        setUpdateEventRegistrationfee(item.event_registration_fee)
        setUpdateMarketingStatus(item.MarketingStatus)
        setUpdateTotalSeats(item.totalSeats)
        setUpdateSponsers(item.sponsers)
        setUpdatenonMemberPrice(item?.nonMemberPrice)
        setUpdatememberPrice(item?.memberPrice)
        setUpdatechild1(item?.child1Price)
        setUpdatechild2(item?.child2Price)
        setUpdatememberScientist(item?.memberScientist)
        setUpdateForeignPrice(item?.ForeignPrice)
        setUpdatespouse(item?.spouse)

        updateEventModalHandleOpen()
    }

    // view Event Modal code ----------------------------------------- 

    const [viewEventModalOpen, setviewEventModalOpen] = useState(false);
    const [eventDetails, setEventDetails] = useState('');

    const viewEventModalHandleOpen = item => {
        setEventDetails(item)
        setviewEventModalOpen(true)
    };
    const viewEventModalHandleClose = () => {
        setviewEventModalOpen(false);
    }

    // view Bookings Modal code ----------------------------------------- 

    const [viewBookingsModalOpen, setviewBookingsModalOpen] = useState(false);
    //   const [BookingsDetails, setBookingsDetails] = useState('');

    const viewBookingsModalHandleOpen = (item) => {
        //   setBookingsDetails(item)
        setviewBookingsModalOpen(true)
    };
    const viewBookingsModalHandleClose = () => {
        setviewBookingsModalOpen(false);
    }

    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)
    if (user) {
        if (user.role !== 'admin') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------
    return (
        <>
            <Helmet>
                <title> Events - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Events" total={events?.length} icon={'ph:users-fill'} />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="coming soon " total={totalBoardEvents} icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Completed " total={totalBoardEvents} icon={'ph:users-fill'} />
                        </Grid> */}
                    </div>

                    {/* <Link to={'/user/new'}> */}
                    <Button variant="contained" onClick={EventModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Events
                    </Button>
                    {/* </Link> */}
                </Stack>

                <div>
                    <div className='tableTop'>
                        <div hidden={!getLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Events</div>

                        {/* <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}
                    </div>
                    {/* <Divider /> */}

                    <div className="eventsContainers">
                        {
                            events && events.map((item) => (
                                <Card>
                                    <div className="event-Box">
                                        <div className="eventImgBoxl">
                                            <img src={item.banner ? item.banner.url : 'https://us.123rf.com/450wm/arypsto/arypsto2104/arypsto210400138/168148723-colorful-layout-design-for-events-festivals-and-art-performances.jpg?ver=6'} alt="Event" />
                                        </div>

                                        <div className="namePositionBoxx">
                                            <h5>{item?.event_name}</h5>

                                            {/* <p><b>sponsors</b> : {item?.sponsers}</p> */}
                                            {/* <h5><b>Event fee</b> : {item?.event_registration_fee}</h5> */}
                                            {/* <p><b>Total Seats</b> : {item?.totalSeats}</p>
                                            <p><b>Marketing Status</b> : {item?.MarketingStatus}</p>
                                            <p>{item?.phone}</p>
                                            <p>Create : {item?.createAt}</p> */}
                                        </div>

                                        <div className="actionBox">

                                            <div className="dateBox">
                                                {/* <p>Create : {item?.createAt}</p> */}
                                            </div>
                                            <div className="event-icon-Box">

                                                <Stack direction={'row'} spacing={1}>
                                                    <button className='ei_btn' onClick={e => editHandle(item)}>
                                                        <AiTwotoneEdit />
                                                    </button>
                                                    <Link className='ei_btn' onClick={e => viewEventModalHandleOpen(item)}  >
                                                        <AiFillEye />
                                                    </Link>

                                                </Stack>
                                                <p>Active for website :  <div className={item?.activeForWebsite ? 'live' : 'unlive'}> </div></p>
                                                <p>Active for Portal :  <div className={item?.activeForMember ? 'live' : 'unlive'}> </div></p>
                                                <Link className='book_btn' to={`/dashboard/bookings/${item._id}`} style={{ textDecoration: 'none' }}   >
                                                    {/* <AiFillEye /> */}
                                                    Bookings
                                                </Link>
                                            </div>
                                            {/* <div className={item?.deleteAt ? 'unlive' : 'live'}> </div> */}
                                        </div>

                                    </div>
                                </Card>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </div>
            </Container>


            {/* New Event  modal code  */}
            <div >
                <Modal
                    open={EventModalOpen}
                    // onClose={EventModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={NewEventStyle} className='eventCreateBox' >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Create  New Event
                            </Typography>
                            <Button onClick={EventModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newEventSubmitHandler}>
                            <Stack spacing={2} margin='0.6vmax 0' direction="row" >
                                <Stack spacing={0} sx={stackStyle} >
                                    <Stack spacing={2} margin='0.4vmax 0' direction="row" >
                                        <TextField name="name"
                                            size='small'
                                            value={eventName}
                                            onChange={e => setEventName(e.target.value)}
                                            required label="Event Name"
                                            sx={NewEventInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography variant="p" color="initial">Event Date</Typography>
                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={eventDate}
                                            onChange={e => setEventdate(e.target.value)}
                                            required
                                            // label="Event Date"
                                            sx={NewEventInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography variant="p" color="initial">Booking end date</Typography>

                                        <TextField name="name"
                                            type='date'
                                            size='small'
                                            value={bookingEndDate}
                                            onChange={e => setBookingEndDate(e.target.value)}
                                            required
                                            // label="Event Date"
                                            sx={NewEventInput} />
                                    </Stack>
                                    {/* <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="phone"
                                            size='small'
                                            value={eventRegistrationfee}
                                            onChange={e => setEventRegistrationfee(e.target.value)}
                                            required label="Event Registration fee" />
                                    </Stack> */}
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="Sponsors"
                                            size='small'
                                            value={sponsers}
                                            onChange={e => setSponsers(e.target.value)}
                                            required label="Sponsors" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="totalSeats"
                                            type='number'
                                            size='small'
                                            value={totalSeats}
                                            onChange={e => setTotalSeats(e.target.value)}
                                            required label="Total seats" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="MarketingStatus"
                                            size='small'
                                            value={MarketingStatus}
                                            onChange={e => setMarketingStatus(e.target.value)}
                                            required label="Marketing Status" />
                                    </Stack>

                                    {/* Indian Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">Indian Delegates</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            type='number'
                                            size='small'
                                            value={memberPrice}
                                            onChange={e => setmemberPrice(e.target.value)}
                                            border={'red'}
                                            required label="Member Price" />


                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'

                                            value={nonMemberPrice}
                                            onChange={e => setnonMemberPrice(e.target.value)}
                                            required label="Non Member Price" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={spouse}
                                            onChange={e => setspouse(e.target.value)}
                                            label="Spouse Price" />
                                    </Stack>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            type='number'
                                            required
                                            size='small'
                                            value={child1Price}
                                            onChange={e => setchild1(e.target.value)}
                                            label="Child1 Price" />
                                    </Stack>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={child2Price}
                                            onChange={e => setchild2(e.target.value)}
                                            label="Child2 Price" />
                                    </Stack>



                                </Stack>

                                <Stack sx={stackStyle}>
                                    {/* memberScientist Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">Member Scientist</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            type='number'
                                            required
                                            size='small'
                                            value={memberScientist}
                                            onChange={e => setmemberScientist(e.target.value)}
                                            label="Member Scientist Price" />
                                    </Stack>

                                    {/* Foreign Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">Foreign Delegates</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={ForeignPrice}
                                            onChange={e => setForeignPrice(e.target.value)}
                                            label="Foreign Price" />
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Active for website</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0' >
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={activeForWebsite}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setActiveForWebsite(e.target.value)}
                                            required
                                            size='small'
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Active for Portal</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0' >
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={activeForMember}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setActiveForMember(e.target.value)}
                                            required
                                            size='small'
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Select Event Poster</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <input
                                            className="inpProfileBtn"
                                            required
                                            type="file"
                                            // accept=""
                                            onChange={handleFileChange}
                                            name="profile"
                                            placeholder="Profile"
                                        />
                                        <img src={bannerPreview !== '' ? bannerPreview : 'https://media.istockphoto.com/id/1427946294/vector/red-color-inserted-label-banner-with-word-preview-on-white-background.jpg?s=612x612&w=0&k=20&c=Xbp2hzB7quoG03KuJ2ZqOj5Yos7I3iuTFR9pY8--OeQ='} className='bannerPreview' alt="preview" height={120} width={'auto'} />
                                    </Stack>


                                </Stack>
                            </Stack>


                            {/* <Stack spacing={3} margin='0.6vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-Event'}>Board Event</MenuItem>
                                    <MenuItem value={'Event'}>Event</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}



                            {createEventLoading ?
                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Event  modal code  */}
            <div>
                <Modal
                    open={updateEventModalOpen}
                    onClose={updateEventModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={NewEventStyle} className='eventCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Event Update
                            </Typography>
                            <Button onClick={updateEventModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateEventSubmitHandler}>
                            <Stack spacing={2} margin='0.6vmax 0' direction="row" >
                                <Stack spacing={0} sx={stackStyle} >
                                    <Stack spacing={2} margin='0.4vmax 0' direction="row" >
                                        <TextField name="name"
                                            size='small'
                                            value={eventUpdateName}
                                            onChange={e => setUpdateEventName(e.target.value)}
                                            required label="Event Name"
                                            sx={NewEventInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography variant="p" color="initial">Event Date</Typography>

                                        <TextField name="name"
                                            size='small'
                                            type='date'
                                            value={eventUpdateDate}
                                            onChange={e => setUpdateEventdate(e.target.value)}
                                            required label=""
                                            sx={NewEventInput} />
                                    </Stack>
                                    <Stack spacing={0} margin='0.6vmax 0'>
                                        <Typography variant="p" color="initial">Booking End date</Typography>
                                        <TextField name="name"
                                            size='small'
                                            type='date'
                                            value={updateBookingEndDate}
                                            onChange={e => setUpdateBookingEndDate(e.target.value)}
                                            required label=""
                                            sx={NewEventInput} />
                                    </Stack>
                                    {/* <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            value={UpdateEventRegistrationfee}
                                            onChange={e => setUpdateEventRegistrationfee(e.target.value)}
                                            required label="Event Registration fee" />
                                    </Stack> */}
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="Sponsors"
                                            size='small'
                                            value={UpdateSponsers}
                                            onChange={e => setUpdateSponsers(e.target.value)}
                                            required label="Sponsors" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="totalSeats"
                                            size='small'
                                            value={UpdateTotalSeats}
                                            onChange={e => setUpdateTotalSeats(e.target.value)}
                                            required label="Total seats" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name="MarketingStatus"
                                            size='small'
                                            value={UpdateMarketingStatus}
                                            onChange={e => setUpdateMarketingStatus(e.target.value)}
                                            required label="Marketing Status" />
                                    </Stack>

                                    {/* Indian Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">Indian Delegates</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            value={UpdatememberPrice}
                                            onChange={e => setUpdatememberPrice(e.target.value)}
                                            required label="Member Price" />

                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'

                                            value={UpdatenonMemberPrice}
                                            onChange={e => setUpdatenonMemberPrice(e.target.value)}
                                            required label="Non Member Price" />
                                    </Stack>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={Updatespouse}
                                            onChange={e => setUpdatespouse(e.target.value)}
                                            label="Spouse Price" />
                                    </Stack>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={Updatechild1Price}
                                            onChange={e => setUpdatechild1(e.target.value)}
                                            label="Child1 Price" />
                                    </Stack>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={Updatechild2Price}
                                            onChange={e => setUpdatechild2(e.target.value)}
                                            label="Child2 Price" />
                                    </Stack>
                                </Stack>

                                <Stack sx={stackStyle}>
                                    {/* memberScientist Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">member Scientist</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'
                                            required
                                            value={UpdatememberScientist}
                                            onChange={e => setUpdatememberScientist(e.target.value)}
                                            label="member Scientist Price" />
                                    </Stack>

                                    {/* Foreign Delegates */}
                                    <InputLabel id="demo-controlled-open-select-label">Foreign Delegates</InputLabel>

                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <TextField name=""
                                            size='small'
                                            type='number'

                                            value={UpdateForeignPrice}
                                            onChange={e => setUpdateForeignPrice(e.target.value)}
                                            required label="Foreign Price" />
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Active for website</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0' >

                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={UpdateActiveForWebsite}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setUpdateActiveForWebsite(e.target.value)}
                                            required
                                            size='small'
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Active for Portal</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0' >
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={UpdateActiveForMember}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setUpdateActiveForMember(e.target.value)}
                                            required
                                            size='small'
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>

                                    <InputLabel id="demo-controlled-open-select-label">Select Event Poster</InputLabel>
                                    <Stack spacing={3} margin='0.6vmax 0'>
                                        <input
                                            className="inpProfileBtn"

                                            type="file"
                                            // accept=""
                                            onChange={updateHandleFileChange}
                                            name="profile"
                                            placeholder="Profile"
                                        />
                                        <img src={UpdateBannerPreview !== '' ? UpdateBannerPreview : 'https://media.istockphoto.com/id/1427946294/vector/red-color-inserted-label-banner-with-word-preview-on-white-background.jpg?s=612x612&w=0&k=20&c=Xbp2hzB7quoG03KuJ2ZqOj5Yos7I3iuTFR9pY8--OeQ='} className='bannerPreview' alt="preview" height={120} width={'auto'} />
                                    </Stack>


                                </Stack>
                            </Stack>


                            {createEventLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    UPDATE
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>

            {/* view Event details  modal code  */}
            <div >
                <Modal
                    open={viewEventModalOpen}
                    // onClose={EventModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={viewEventStyle} className='eventCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Events
                            </Typography>
                            <Button onClick={viewEventModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />
                        <EventDetail event={eventDetails} />
                    </Box>
                </Modal>
            </div>


            {/* view Event details  modal code  */}
            <div >
                <Modal
                    open={viewBookingsModalOpen}
                    // onClose={EventModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={viewEventStyle} className='eventCreateBox'>
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Events Bookings
                            </Typography>
                            <Button onClick={viewBookingsModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />
                        <div>
                            <Stack direction={'row'} padding={'4vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'} margin={'6vmax'} borderRadius={2} border={1}>
                                <Typography variant="h3" color="initial">Currently No Booking</Typography>
                            </Stack>
                        </div>
                    </Box>
                </Modal>
            </div>



        </>
    );
}
