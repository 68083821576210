export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST"
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS"
export const CREATE_NEWS_FAIL = "CREATE_NEWS_FAIL"
export const CREATE_NEWS_RESET = "CREATE_NEWS_RESET"

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"

export const GET_ALL_NEWS_REQUEST = "GET_ALL_NEWS_REQUEST"
export const GET_ALL_NEWS_SUCCESS = "GET_ALL_NEWS_SUCCESS"
export const GET_ALL_NEWS_FAIL = "GET_ALL_NEWS_FAIL"

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST"
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS"
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL"
export const UPDATE_NEWS_RESET = "UPDATE_NEWS_RESET"


export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL"
export const DELETE_NEWS_RESET = "DELETE_NEWS_RESET"


export const RECOVER_NEWS_REQUEST = "RECOVER_NEWS_REQUEST"
export const RECOVER_NEWS_SUCCESS = "RECOVER_NEWS_SUCCESS"
export const RECOVER_NEWS_FAIL = "RECOVER_NEWS_FAIL"
export const RECOVER_NEWS_RESET = "RECOVER_NEWS_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"