export const CREATE_WORKSHOP_REQUEST = "CREATE_WORKSHOP_REQUEST"
export const CREATE_WORKSHOP_SUCCESS = "CREATE_WORKSHOP_SUCCESS"
export const CREATE_WORKSHOP_FAIL = "CREATE_WORKSHOP_FAIL"
export const CREATE_WORKSHOP_RESET = "CREATE_WORKSHOP_RESET"

export const GET_WORKSHOPS_REQUEST = "GET_WORKSHOPS_REQUEST"
export const GET_WORKSHOPS_SUCCESS = "GET_WORKSHOPS_SUCCESS"
export const GET_WORKSHOPS_FAIL = "GET_WORKSHOPS_FAIL"

export const GET_WORKSHOPS_PORTAL_REQUEST = "GET_WORKSHOPS_PORTAL_REQUEST"
export const GET_WORKSHOPS_PORTAL_SUCCESS = "GET_WORKSHOPS_PORTAL_SUCCESS"
export const GET_WORKSHOPS_PORTAL_FAIL = "GET_WORKSHOPS_PORTAL_FAIL"

export const GET_WORKSHOPS_WEBSITE_REQUEST = "GET_WORKSHOPS_WEBSITE_REQUEST"
export const GET_WORKSHOPS_WEBSITE_SUCCESS = "GET_WORKSHOPS_WEBSITE_SUCCESS"
export const GET_WORKSHOPS_WEBSITE_FAIL = "GET_WORKSHOPS_WEBSITE_FAIL"

export const GET_WORKSHOP_REQUEST = "GET_WORKSHOP_REQUEST"
export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS"
export const GET_WORKSHOP_FAIL = "GET_WORKSHOP_FAIL"

export const UPDATE_WORKSHOP_REQUEST = "UPDATE_WORKSHOP_REQUEST"
export const UPDATE_WORKSHOP_SUCCESS = "UPDATE_WORKSHOP_SUCCESS"
export const UPDATE_WORKSHOP_FAIL = "UPDATE_WORKSHOP_FAIL"
export const UPDATE_WORKSHOP_RESET = "UPDATE_WORKSHOP_RESET"


export const HIDE_WORKSHOP_FOR_WEB_REQUEST = "HIDE_WORKSHOP_FOR_WEB_REQUEST"
export const HIDE_WORKSHOP_FOR_WEB_SUCCESS = "HIDE_WORKSHOP_FOR_WEB_SUCCESS"
export const HIDE_WORKSHOP_FOR_WEB_FAIL = "HIDE_WORKSHOP_FOR_WEB_FAIL"
export const HIDE_WORKSHOP_FOR_WEB_RESET = "HIDE_WORKSHOP_FOR_WEB_RESET"

export const HIDE_WORKSHOP_FOR_PORTAL_REQUEST = "HIDE_WORKSHOP_FOR_PORTAL_REQUEST"
export const HIDE_WORKSHOP_FOR_PORTAL_SUCCESS = "HIDE_WORKSHOP_FOR_PORTAL_SUCCESS"
export const HIDE_WORKSHOP_FOR_PORTAL_FAIL = "HIDE_WORKSHOP_FOR_PORTAL_FAIL"
export const HIDE_WORKSHOP_FOR_PORTAL_RESET = "HIDE_WORKSHOP_FOR_PORTAL_RESET"


export const CREATE_WORKSHOP_BOOKING_REQUEST = "CREATE_WORKSHOP_BOOKING_REQUEST"
export const CREATE_WORKSHOP_BOOKING_SUCCESS = "CREATE_WORKSHOP_BOOKING_SUCCESS"
export const CREATE_WORKSHOP_BOOKING_FAIL = "CREATE_WORKSHOP_BOOKING_FAIL"
export const CREATE_WORKSHOP_BOOKING_RESET = "CREATE_WORKSHOP_BOOKING_RESET"

export const GET_WORKSHOP_BOOKING_REQUEST = "GET_WORKSHOP_BOOKING_REQUEST"
export const GET_WORKSHOP_BOOKING_SUCCESS = "GET_WORKSHOP_BOOKING_SUCCESS"
export const GET_WORKSHOP_BOOKING_FAIL = "GET_WORKSHOP_BOOKING_FAIL"

export const GET_WORKSHOP_BOOKINGS_REQUEST = "GET_WORKSHOP_BOOKINGS_REQUEST"
export const GET_WORKSHOP_BOOKINGS_SUCCESS = "GET_WORKSHOP_BOOKINGS_SUCCESS"
export const GET_WORKSHOP_BOOKINGS_FAIL = "GET_WORKSHOP_BOOKINGS_FAIL"


export const GET_WORKSHOP_MYBOOKINGS_REQUEST = "GET_WORKSHOP_MYBOOKINGS_REQUEST"
export const GET_WORKSHOP_MYBOOKINGS_SUCCESS = "GET_WORKSHOP_MYBOOKINGS_SUCCESS"
export const GET_WORKSHOP_MYBOOKINGS_FAIL = "GET_WORKSHOP_MYBOOKINGS_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"