import { Helmet } from 'react-helmet-async';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    FormControl,
    InputLabel,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'

// components
import { LoadingButton } from '@mui/lab';
import { getDate } from '../../utils/getDate';

import { RENEW_RESET } from '../../redux/constants/userConstant';
import Logo from '../../images/icons/logo.png'

import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createOrgDir, deleteOrgDir, getOrgDirs, recoverOrgDir, updateOrgDir, clearError, getMyOrg } from '../../redux/actions/orgDirAction';
import { CREATE_ORG_DIR_RESET } from '../../redux/constants/orgDirConstant';
import { renewMember } from '../../redux/actions/userAction';
import { payment } from './payment';
// import { round } from 'lodash';


// ----------------------------------------------------------------------
const priceList = [
    1, 5000
]

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function RenewPage() {
    const [userId, setUserId] = useState(''); 
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.users)



    // -----------------------------Renew Modal Code --------------------------------------


    const { loading: renewLoading, formBody, success: renewSuccess, error } = useSelector((state) => state.renew)


    // ==============================================================================


    const [memberShipStatus, setMemberShipStatus] = useState('');
    const [price, setPrice] = useState('0');

    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const paySubmitHandler = (e) => {
        e.preventDefault()
        // if (userId) {
        // payment(userId, price)
        // }

        dispatch(renewMember(userId, { memberShipStatus, price }))

    }

    useEffect(() => {

        if (renewSuccess) {
            // toast.success('updated success');
            MemberModalHandleClose()
            dispatch({ type: RENEW_RESET });
            // dispatch(getOrgDirs(keyword, page))


            //  run formBody code 
            const container = document.createElement('div');
            container.innerHTML = formBody;

            // Append the form to the document body
            document.body.appendChild(container.firstChild);

            // Trigger the form submission
            document.getElementById('nonseamless').submit();

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, renewSuccess, dispatch])



    useEffect(() => {
        setUserId(user?._id)
        // ---------------------------
        const currentDate = new Date();
        const memberShipExpired = new Date(user?.memberShipExpired);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = memberShipExpired - currentDate;
        // Convert milliseconds to days
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        // Update the state with the difference in days
        // setDifferenceInDays(differenceInDays);
        // toast.warn(`Your Membership is expired in ${differenceInDays} days. Please Renew your Membership`)


    }, [user])
    useEffect(() => {

        if (memberShipStatus === '0') {
            setPrice(priceList[1])
        } else if (memberShipStatus === '1') {
            setPrice(priceList[0])
        } else {
            setPrice(0)

        }
    }, [memberShipStatus])

    // --------------------------------------------------------------------------------------------------------
    const htmlContent = useRef()

    const handleDownload = () => {
        // const element = document.getElementById('htmlContent');
        const element = htmlContent.current;

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // console.log('imgData', imgData)
            // const pdf = new jsPDF();
            // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            // pdf.save('code.pdf');
            //     const pdf = new JsPDF('p', 'mm', 'a4', true);
            //     const pdfWidth = pdf.internal.pageSize.getWidth(); 
            //     const pdfHeight = pdf.internal.pageSize.getHeight();
            //     const imgWidth = canvas.width;
            //     const imgHeight =  canvas.height;
            //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            //     const imgX = (pdfWidth, imgWidth, ratio) / 2;
            // const imgY = 30;
            // pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight, ratio);
            // pdf.save('invoice.pdf');

            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'code.png';
            link.click();

        });
    };
    return (
        <>
            <Helmet>
                <title> My Organization - eoai </title>
            </Helmet>

            <Container>
                {
                    <Card direction="row" alignItems="center" justifyContent="center"  >
                        <Stack direction="column" alignItems="center" justifyContent="center" p={5} spacing={1}>
                            <Typography color="initial"> Renew your Membership</Typography>
                            <Typography variant="h6" color="initial">Membership Expired On : {getDate(user?.memberShipExpired)}</Typography>
                            <Button variant="contained" disabled={'true'} onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                                Renew
                            </Button>

                            {/* Live <Link to={`/renew/payment/history`} disabled={'true'}><Button  variant='' > Transaction History</Button></Link> */}

                            {/* <Link to={`/payment/${user?.paymentId}`}><Button  variant='' > Last Transaction</Button></Link> */}

                            {/* {formBody &&
                                <>
                                <div dangerouslySetInnerHTML={{ __html: formBody }} />
                               { console.log(formBody)}
                                </>

                            } */}
                        </Stack>
                    </Card>
                }
                <br />
                <Card>
                    {
                        <>
                            <div className='tableTop'>
                                {/* <div hidden={!getmemLoading ? 'true' : ''}>
                                    <CircularProgress color="primary" />
                                </div> */}
                                <div>Certificate</div>
                                <Button onClick={handleDownload} >Download</Button>
                            </div>
                            <Divider />


                            <div className="orgBox center" >

                                <div className='certificateBox' ref={htmlContent}>
                                    <img src={Logo} alt="" />
                                    <Typography variant="h6" color="initial">ESSENTIAL OIL ASSOCIATION OF INDIA</Typography>
                                    <Typography color="initial">Membership till : {getDate(user?.memberShipExpired)}</Typography>
                                </div >

                            </div>
                        </>

                    }
                </Card>
            </Container>


            {/* Renew your Membership  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}  >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Renew your Membership
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        {/* <Divider /> */}
                        <br />


                        <form action="" onSubmit={paySubmitHandler}>


                            <Stack spacing={2}>


                                <FormControl fullWidth spacing={3}  >
                                    <InputLabel id="demo-controlled-open-select-label">Select MemberShip</InputLabel>

                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={memberShipStatus}
                                        label="space space space"
                                        // displayEmpty
                                        onChange={e => setMemberShipStatus(e.target.value)}
                                        required
                                        size='medium'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'1'}>1 Year</MenuItem>
                                        <MenuItem value={'0'}>Lifetime</MenuItem>
                                    </Select>
                                </FormControl>

                                <Stack padding={0}>
                                    <Typography color="success.main">Membership Fee : Rs {price}</Typography>
                                </Stack>

                                {renewLoading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size='medium' disabled={!price} type="submit" variant="contained" >
                                        Pay
                                    </LoadingButton>)

                                }
                            </Stack>
                        </form>

                    </Box>
                </Modal>
            </div>







        </>
    );
}
