export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST"
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS"
export const CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL"
export const CREATE_BOOKING_RESET = "CREATE_BOOKING_RESET"

export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST"
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS"
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL"
export const CREATE_PAYMENT_RESET = "CREATE_PAYMENT_RESET"

export const GET_ALL_BOOKINGS_REQUEST = "GET_ALL_BOOKINGS_REQUEST"
export const GET_ALL_BOOKINGS_SUCCESS = "GET_ALL_BOOKINGS_SUCCESS"
export const GET_ALL_BOOKINGS_FAIL = "GET_ALL_BOOKINGS_FAIL"

export const GET_BOOKING_DETAILS_REQUEST = "GET_BOOKING_DETAILS_REQUEST"
export const GET_BOOKING_DETAILS_SUCCESS = "GET_BOOKING_DETAILS_SUCCESS"
export const GET_BOOKING_DETAILS_FAIL = "GET_BOOKING_DETAILS_FAIL"

export const GET_MY_BOOKINGS_REQUEST = "GET_MY_BOOKINGS_REQUEST"
export const GET_MY_BOOKINGS_SUCCESS = "GET_MY_BOOKINGS_SUCCESS"
export const GET_MY_BOOKINGS_FAIL = "GET_MY_BOOKINGS_FAIL"

export const CANCEL_PERSON_REQUEST = "CANCEL_PERSON_REQUEST"
export const CANCEL_PERSON_SUCCESS = "CANCEL_PERSON_SUCCESS"
export const CANCEL_PERSON_FAIL = "CANCEL_PERSON_FAIL"
export const CANCEL_PERSON_RESET = "CANCEL_PERSON_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"