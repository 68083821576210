export const GET_RENEW_PAYMENT_DETAILS_REQUEST = "GET_RENEW_PAYMENT_DETAILS_REQUEST"
export const GET_RENEW_PAYMENT_DETAILS_SUCCESS = "GET_RENEW_PAYMENT_DETAILS_SUCCESS"
export const GET_RENEW_PAYMENT_DETAILS_FAIL = "GET_RENEW_PAYMENT_DETAILS_FAIL"

export const GET_RENEW_ALL_PAYMENT_REQUEST = "GET_RENEW_ALL_PAYMENT_REQUEST"
export const GET_RENEW_ALL_PAYMENT_SUCCESS = "GET_RENEW_ALL_PAYMENT_SUCCESS"
export const GET_RENEW_ALL_PAYMENT_FAIL = "GET_RENEW_ALL_PAYMENT_FAIL"


export const GET_BOOKING_PAYMENT_REQUEST = "GET_BOOKING_PAYMENT_REQUEST"
export const GET_BOOKING_PAYMENT_SUCCESS = "GET_BOOKING_PAYMENT_SUCCESS"
export const GET_BOOKING_PAYMENT_FAIL = "GET_BOOKING_PAYMENT_FAIL"
export const GET_BOOKING_PAYMENT_RESET = "GET_BOOKING_PAYMENT_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"