import { useSelector } from 'react-redux';

import event  from '../../../images/icons/ic_event.png';
import news  from '../../../images/icons/news-177.svg';
import directory  from '../../../images/icons/new-directory.svg';
import team  from '../../../images/icons/team.svg';
import gallery  from '../../../images/icons/gallery.png';

// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={name} sx={{ width: 1, height: 1 }} />;

const navConfig = [];

const NavConfig = () => {
  const { loading, isAuthenticated,user, error } = useSelector((state) => state.users)
  console.log(user)

   const nav =  [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: icon('/assets/icons/navbar/ic_analytics.svg'),
    },
    {
      title: 'user',
      path: '/dashboard/user',
      icon: icon('/assets/icons/navbar/ic_user.svg'),
    },
    {
      title: 'Manage Events ',
      path: '/dashboard/events',
      icon: icon(event),
    },
    {
      title: 'Events',
      path: 'events-booking',
      icon: icon(event),
    },
    {
      title: 'Manage Gallery',
      path: 'gallery',
      icon: icon(gallery),
    },
  
      {
        title: 'News Manage',
        path: '/dashboard/blog',
        icon: icon(news),
        active:true
      },
  
    {
      title: 'Organization Directory',
      path: '/dashboard/organization',
      icon: icon(directory),
    },
    {
      title: 'Executive Committee',
      path: '/dashboard/team',
      icon: icon(team),
    },
  ]

  return (
    ''
  )
}
  
  
  // NavConfig()

export default navConfig;
