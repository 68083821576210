
// export {localX as default}
// export {localX as exportedX};

const port = window.location.hostname
// let host = 's'
// console.log(port)

if (port === 'portal.eoai.in') {
    exports.host = "https://api.eoai.in"
}
else if (port === 'dev.eoai.in') {
    exports.host = "https://api.eoai.in";
    // exports.host = "http://localhost:8090";
}
else if (port === 'localhost') {
    exports.host = "http://localhost:7050";
    // exports.host = "http://localhost:8090";
}
else if (port === 'www.localhost') {
    exports.host = "http://www.localhost:7050";
}
else if (port === '127.0.0.1') {
    exports.host = "http://localhost:7050";
}
else if (port === '62.72.31.157') {
    exports.host = "http://62.72.31.157:7050";
}
else if (port === '195.35.6.133') {
    exports.host = "http://195.35.6.133:7050";
    // exports.host = "http://195.35.6.133:8090";
}





//  host = "http://157.245.99.110:5409"
//   host = "http://portal.eoais.com"
//  host = "http://localhost:5409";

// export default host
console.log(this.host)

