import axios from "axios";
import {
    CREATE_ORG_DIR_REQUEST,
    CREATE_ORG_DIR_SUCCESS,
    CREATE_ORG_DIR_FAIL,

    GET_ORG_DIR_REQUEST,
    GET_ORG_DIR_SUCCESS,
    GET_ORG_DIR_FAIL,

    UPDATE_ORG_DIR_REQUEST,
    UPDATE_ORG_DIR_SUCCESS,
    UPDATE_ORG_DIR_FAIL,

    DELETE_ORG_DIR_REQUEST,
    DELETE_ORG_DIR_SUCCESS,
    DELETE_ORG_DIR_FAIL,

    RECOVER_ORG_DIR_REQUEST,
    RECOVER_ORG_DIR_SUCCESS,
    RECOVER_ORG_DIR_FAIL,
    CLEAR_ERROR,
    GET_MY_ORG_REQUEST,
    GET_MY_ORG_SUCCESS,
    GET_MY_ORG_FAIL,
    GET_ORG_REQUEST,
    GET_ORG_SUCCESS,
    GET_ORG_FAIL,

} from '../constants/orgDirConstant'

import {host} from "../../utils/config";

export const createOrgDir = (details,keyword,page) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: CREATE_ORG_DIR_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/web/api/v1/new/org`, details, config);

        dispatch({ type: CREATE_ORG_DIR_SUCCESS, payload: data.data.user });

    } catch (error) {
        dispatch({
            type: CREATE_ORG_DIR_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getMyOrg = (userId) => async (dispatch) => {
    try {
        dispatch({ type: GET_MY_ORG_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/my-org/${userId}`, config);

        dispatch({ type: GET_MY_ORG_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_MY_ORG_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getOrg = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ORG_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/org/${id}`, config);

        dispatch({ type: GET_ORG_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ORG_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getOrgDirs = (keyword, page) => async (dispatch) => {
    try {
        dispatch({ type: GET_ORG_DIR_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/orgs?keyword=${keyword}&page=${page}`, config);

        dispatch({ type: GET_ORG_DIR_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ORG_DIR_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateOrgDir = (details,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_ORG_DIR_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/update/org/${id}`, details, config);

        dispatch({ type: UPDATE_ORG_DIR_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_ORG_DIR_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const deleteOrgDir = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_ORG_DIR_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/delete/org/${id}`, {}, config);

        dispatch({ type: DELETE_ORG_DIR_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: DELETE_ORG_DIR_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const recoverOrgDir = (id) => async (dispatch) => {
    try {

        dispatch({ type: RECOVER_ORG_DIR_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/recover/org/${id}`,{}, config);

        dispatch({ type: RECOVER_ORG_DIR_SUCCESS, payload: data.success });

    } catch (error) {
        dispatch({
            type: RECOVER_ORG_DIR_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

