import {
    CREATE_EXE_COMMITTEE_REQUEST,
    CREATE_EXE_COMMITTEE_SUCCESS,
    CREATE_EXE_COMMITTEE_FAIL,
    CREATE_EXE_COMMITTEE_RESET,
    GET_EXE_COMMITTEE_REQUEST,
    GET_EXE_COMMITTEE_SUCCESS,
    GET_EXE_COMMITTEE_FAIL,
    UPDATE_EXE_COMMITTEE_REQUEST,
    UPDATE_EXE_COMMITTEE_SUCCESS,
    UPDATE_EXE_COMMITTEE_FAIL,
    UPDATE_EXE_COMMITTEE_RESET,
    DELETE_EXE_COMMITTEE_REQUEST,
    DELETE_EXE_COMMITTEE_SUCCESS,
    DELETE_EXE_COMMITTEE_FAIL,
    DELETE_EXE_COMMITTEE_RESET,
    RECOVER_EXE_COMMITTEE_REQUEST,
    RECOVER_EXE_COMMITTEE_SUCCESS,
    RECOVER_EXE_COMMITTEE_FAIL,
    RECOVER_EXE_COMMITTEE_RESET,
    CLEAR_ERROR,
} from '../constants/exeCommitteeConstant'


export const createExeCommattiee = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_EXE_COMMITTEE_REQUEST:
        case CREATE_EXE_COMMITTEE_REQUEST:
            case DELETE_EXE_COMMITTEE_REQUEST:
                case RECOVER_EXE_COMMITTEE_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_EXE_COMMITTEE_SUCCESS:
        case CREATE_EXE_COMMITTEE_SUCCESS:
        case DELETE_EXE_COMMITTEE_SUCCESS:
            case RECOVER_EXE_COMMITTEE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_EXE_COMMITTEE_FAIL:
        case CREATE_EXE_COMMITTEE_FAIL:
        case DELETE_EXE_COMMITTEE_FAIL:
            case RECOVER_EXE_COMMITTEE_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_EXE_COMMITTEE_RESET:
        case CREATE_EXE_COMMITTEE_RESET:
            case RECOVER_EXE_COMMITTEE_RESET:
                case DELETE_EXE_COMMITTEE_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getExeCommattiee = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_EXE_COMMITTEE_REQUEST:
        return {
          loading: true,
        };
  
        case GET_EXE_COMMITTEE_SUCCESS:
        return {
          ...state,
          loading: false,
          members: action.payload.members,
          
          totalBoardMembers: action.payload.totalBoardMembers,
          totalMember: action.payload.totalMember,
          totalSplInviteeMembers: action.payload.totalSplInviteeMembers,
          totalZonalMembers: action.payload.totalZonalMembers,
        };
  

        case GET_EXE_COMMITTEE_FAIL:
        return {
          ...state,
          loading: false,
          members: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

