import { Stack, Typography } from '@mui/material'
import React from 'react'
import Zero from '../images/image/zero.png'

const ZeroState = ({ title }) => {
    return (
        <div>
            <Stack direction={'row'} padding={4} alignItems={'center'} justifyContent={'center'} border={1} borderColor={'primary'} borderRadius={2} >
                <Stack width={'100%'} alignItems={'center'} borderRadius={2}   justifyContent={'center'} >
                    <img src={Zero} alt="Zero"  width={'60%'} />
                    <Typography variant="h4" textAlign={'center'} color="gray">{title}</Typography>
                </Stack>


            </Stack>
        </div>
    )
}

export default ZeroState