import {
  CANCEL_PERSON_FAIL, CANCEL_PERSON_REQUEST, CANCEL_PERSON_RESET, CANCEL_PERSON_SUCCESS, CLEAR_ERROR, CREATE_BOOKING_FAIL, CREATE_BOOKING_REQUEST, CREATE_BOOKING_RESET, CREATE_BOOKING_SUCCESS, CREATE_PAYMENT_FAIL, CREATE_PAYMENT_REQUEST, CREATE_PAYMENT_RESET, CREATE_PAYMENT_SUCCESS, GET_ALL_BOOKINGS_FAIL, GET_ALL_BOOKINGS_REQUEST, GET_ALL_BOOKINGS_SUCCESS, GET_BOOKING_DETAILS_FAIL, GET_BOOKING_DETAILS_REQUEST, GET_BOOKING_DETAILS_SUCCESS, GET_MY_BOOKINGS_FAIL, GET_MY_BOOKINGS_REQUEST, GET_MY_BOOKINGS_SUCCESS
} from '../constants/bookingConstant'


export const createBooking = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case CREATE_BOOKING_REQUEST:
        case CREATE_PAYMENT_REQUEST:
          case CANCEL_PERSON_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case CREATE_BOOKING_SUCCESS:
        case CREATE_PAYMENT_SUCCESS:
        case CANCEL_PERSON_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          message: action.payload.message,
          id: action.payload.id,
          
        };
  

      case CREATE_BOOKING_FAIL:
        case CREATE_PAYMENT_FAIL:
        case CANCEL_PERSON_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case CREATE_BOOKING_RESET:
        case CREATE_PAYMENT_RESET:
            case CANCEL_PERSON_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getBookings = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_ALL_BOOKINGS_REQUEST:
      case GET_MY_BOOKINGS_REQUEST:
        case GET_BOOKING_DETAILS_REQUEST:
        return {
          loading: true,
        };
  
        case GET_ALL_BOOKINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          bookings: action.payload.bookings, 

          totalBookings: action.payload.totalBookings, 
          totalPaidBookings: action.payload.totalPaidBookings, 
          totalNonPaidBookings: action.payload.totalNonPaidBookings, 
          totalCancelBookings: action.payload.totalCancelBookings, 
         
        };
  
        case GET_BOOKING_DETAILS_SUCCESS:
          return {
            ...state,
            loading: false,
            booking: action.payload.booking, 
            persons: action.payload.persons, 
            // totalFee : action.payload.totalFee
          
           
          };

          case GET_MY_BOOKINGS_SUCCESS:
            return {
              ...state,
              loading: false,
              bookings: action.payload.bookings, 
              // totalFee : action.payload.totalFee
            
             
            };

        case GET_ALL_BOOKINGS_FAIL:
        case GET_MY_BOOKINGS_FAIL:
          case GET_BOOKING_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          bookings: null,
          booking: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

