import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Container, Grid, Typography, Divider, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEvent } from '../redux/actions/eventsAction';
import './viewperson.css'

const initialFormData = {
    id:'',
    name: '',
    amount: 0,
    category: '',
};

const App = ({ data, setData, eventId }) => {
    const [formData, setFormData] = useState(initialFormData);
    // const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
  

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEdit) {
            // Handle update operation
            const updatedData = data.map((item) => (item.id === formData.id ? formData : item));
            setData(updatedData);
            setIsEdit(false);
        } else {
            // Handle create operation
            setData([...data, { ...formData, id: new Date().getTime() }]);
        }

        setFormData(initialFormData);
    };

    const handleEdit = (id) => {
        const editItem = data.find((item) => item.id === id);
        setFormData(editItem);
        setIsEdit(true);
    };

    const handleDelete = (id) => {
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
    };


    // called  events 
    const dispatch = useDispatch();

    const { event } = useSelector((state) => state.getEvents)

    useEffect(() => {
        if (eventId) dispatch(getEvent(eventId))

    }, [eventId])

// const {memberPrice} = event
    useEffect(() => {
        let value = 0
        const name = "amount"
        if(formData.category === 'Member'){
            value = event?.memberPrice
        }else if(formData.category === 'Spouse'){
            value = event?.spouse

        }
        else if(formData.category === 'Child'){
            value = Number(event?.child1Price)

        }
        else if(formData.category === 'Member Scientist'){
            value = event?.memberScientist
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    }, [formData.category])
    
    
    // console.log('data')
    return (
        <Container sx={{ backgroundColor: 'primary' }}>
            <Typography variant="h5" >Add Persons</Typography> <br />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} >
                    <Grid item xs={4}>
                    {/* <TextField
                            label="Id"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                            fullWidth
                            required
                        /> */}
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth required>
                            <InputLabel>Select Persons</InputLabel>
                            <Select
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={'Member'}>Member</MenuItem>
                                <MenuItem value={'Spouse'}>Spouse</MenuItem>
                                <MenuItem value={'Child'}>Child</MenuItem>
                                <MenuItem value={'Member Scientist'}>Member Scientist</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth required>
                            <InputLabel>Auto Select Amount</InputLabel>
                            <Select
                                name="amount"
                                value={formData.amount}
                                // value={category === 'Member'}
                                onChange={handleChange}
                                disabled
                            >
                                <MenuItem value={formData.amount}><em>Rs {formData.amount}</em></MenuItem>
                                
                            </Select>
                            {/* {formData.amount} */}
                        </FormControl>

                    </Grid>

                    <Stack  item sx={{width:'100%',marginTop:1}} direction={'row'}  justifyContent={'flex-end'}>
                        <Button type="submit" variant="contained" color="primary">
                            {isEdit ? 'Update' : 'Add'}
                        </Button>
                    </Stack>
                </Grid>
            </form>
            <Divider sx={{ margin: '2vmax 0' }} />

            <div className='ol'>
                {data && data.map((item, i) => (
                    <div key={item.id} className='viewperson' >


                        <div>
                            <Typography color="initial">{i + 1}</Typography>

                            <Typography color="initial">Name : {item.name}</Typography>
                            <Typography color="initial">Category : {item.category}</Typography>
                            <Typography color="initial">Amount : Rs {item.amount}</Typography>
                        </div>

                        <div>

                            <Button onClick={() => handleEdit(item.id)} color="primary">
                                Edit
                            </Button>
                            <Button onClick={() => handleDelete(item.id)} color="error">
                                Delete
                            </Button>
                        </div>

                    </div>
                ))}
            </div>
            {/* <Divider sx={{ margin: '2vmax 0' }} /> */}

         
        </Container>
    );
};

export default App;
