import axios from "axios";
import {
    CREATE_EXE_COMMITTEE_REQUEST,
    CREATE_EXE_COMMITTEE_SUCCESS,
    CREATE_EXE_COMMITTEE_FAIL,

    GET_EXE_COMMITTEE_REQUEST,
    GET_EXE_COMMITTEE_SUCCESS,
    GET_EXE_COMMITTEE_FAIL,
    UPDATE_EXE_COMMITTEE_REQUEST,
    UPDATE_EXE_COMMITTEE_SUCCESS,
    UPDATE_EXE_COMMITTEE_FAIL,

    DELETE_EXE_COMMITTEE_REQUEST,
    DELETE_EXE_COMMITTEE_SUCCESS,
    DELETE_EXE_COMMITTEE_FAIL,

    RECOVER_EXE_COMMITTEE_REQUEST,
    RECOVER_EXE_COMMITTEE_SUCCESS,
    RECOVER_EXE_COMMITTEE_FAIL,
    CLEAR_ERROR,

} from '../constants/exeCommitteeConstant'

import {host} from "../../utils/config";



export const createExeMember = (details) => async (dispatch) => {

    try {
        console.log("create member")

        dispatch({ type: CREATE_EXE_COMMITTEE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/web/api/v1/new/member`, details, config);

        dispatch({ type: CREATE_EXE_COMMITTEE_SUCCESS, payload: data.data.user });

    } catch (error) {
        dispatch({
            type: CREATE_EXE_COMMITTEE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getExeMembers = (keyword, page) => async (dispatch) => {
    try {
        dispatch({ type: GET_EXE_COMMITTEE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/members?keyword=${keyword}&page=${page}`, config);

        dispatch({ type: GET_EXE_COMMITTEE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_EXE_COMMITTEE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateExeMember = (memberDetails,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_EXE_COMMITTEE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/update/member/${id}`, memberDetails, config);

        dispatch({ type: UPDATE_EXE_COMMITTEE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_EXE_COMMITTEE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const deleteExeMember = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_EXE_COMMITTEE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/delete/member/${id}`, config);

        dispatch({ type: DELETE_EXE_COMMITTEE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: DELETE_EXE_COMMITTEE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const recoverExeMember = (id) => async (dispatch) => {
    try {

        dispatch({ type: RECOVER_EXE_COMMITTEE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/recover/member/${id}`, config);

        dispatch({ type: RECOVER_EXE_COMMITTEE_SUCCESS, payload: data.success });

    } catch (error) {
        dispatch({
            type: RECOVER_EXE_COMMITTEE_FAIL,
            payload: error.response.data.message,
        });
    }
};




export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

