import {
    CREATE_WORKSHOP_REQUEST,
    CREATE_WORKSHOP_SUCCESS,
    CREATE_WORKSHOP_FAIL,
    CREATE_WORKSHOP_RESET,
    GET_WORKSHOPS_REQUEST,
    GET_WORKSHOPS_SUCCESS,
    GET_WORKSHOPS_FAIL,
    UPDATE_WORKSHOP_REQUEST,
    UPDATE_WORKSHOP_SUCCESS,
    UPDATE_WORKSHOP_FAIL,
    UPDATE_WORKSHOP_RESET,
    HIDE_WORKSHOP_FOR_WEB_REQUEST,
    HIDE_WORKSHOP_FOR_WEB_SUCCESS,
    HIDE_WORKSHOP_FOR_WEB_FAIL,
    HIDE_WORKSHOP_FOR_WEB_RESET,
    
    HIDE_WORKSHOP_FOR_PORTAL_REQUEST,
    HIDE_WORKSHOP_FOR_PORTAL_SUCCESS,
    HIDE_WORKSHOP_FOR_PORTAL_FAIL,
    HIDE_WORKSHOP_FOR_PORTAL_RESET,

    CLEAR_ERROR,

    GET_WORKSHOP_REQUEST,
    GET_WORKSHOP_SUCCESS,
    GET_WORKSHOP_FAIL,
    GET_WORKSHOPS_PORTAL_REQUEST,
    GET_WORKSHOPS_PORTAL_FAIL,
    GET_WORKSHOPS_PORTAL_SUCCESS,
    CREATE_WORKSHOP_BOOKING_REQUEST,
    CREATE_WORKSHOP_BOOKING_SUCCESS,
    CREATE_WORKSHOP_BOOKING_FAIL,
    CREATE_WORKSHOP_BOOKING_RESET,
    GET_WORKSHOP_BOOKINGS_FAIL,
    GET_WORKSHOP_BOOKING_FAIL,
    GET_WORKSHOP_MYBOOKINGS_FAIL,
    GET_WORKSHOP_BOOKINGS_REQUEST,
    GET_WORKSHOP_MYBOOKINGS_REQUEST,
    GET_WORKSHOP_BOOKING_REQUEST,
    GET_WORKSHOP_BOOKINGS_SUCCESS,
    GET_WORKSHOP_BOOKING_SUCCESS,
    GET_WORKSHOP_MYBOOKINGS_SUCCESS,
} from '../constants/workshopConstant'


export const createWorkshop = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_WORKSHOP_REQUEST:
        case CREATE_WORKSHOP_REQUEST:
            case HIDE_WORKSHOP_FOR_PORTAL_REQUEST:
                case HIDE_WORKSHOP_FOR_WEB_REQUEST:
                  case CREATE_WORKSHOP_BOOKING_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_WORKSHOP_SUCCESS:
        case HIDE_WORKSHOP_FOR_WEB_SUCCESS:
            case HIDE_WORKSHOP_FOR_PORTAL_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
        case CREATE_WORKSHOP_SUCCESS:
          return {
            ...state,
            loading: false,
            success: true,
          };
          case CREATE_WORKSHOP_BOOKING_SUCCESS:
            return {
              ...state,
              loading: false,
              success: true,
              html:action.payload.html
            };
      case UPDATE_WORKSHOP_FAIL:
        case CREATE_WORKSHOP_FAIL:
        case HIDE_WORKSHOP_FOR_PORTAL_FAIL:
            case HIDE_WORKSHOP_FOR_WEB_FAIL:
              case  CREATE_WORKSHOP_BOOKING_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_WORKSHOP_RESET:
        case CREATE_WORKSHOP_RESET:
            case HIDE_WORKSHOP_FOR_PORTAL_RESET:
                case HIDE_WORKSHOP_FOR_WEB_RESET:
              case  CREATE_WORKSHOP_BOOKING_RESET:

            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getWorkshop = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_WORKSHOP_REQUEST:
      case GET_WORKSHOPS_REQUEST:
        case GET_WORKSHOPS_PORTAL_REQUEST:
        return {
          loading: true,
        };
  
        case GET_WORKSHOPS_SUCCESS:
        return {
          ...state,
          loading: false,
          workshops: action.payload.workshops, 
          totalWorkshops: action.payload.totalWorkshops,
        };
  
        case GET_WORKSHOP_SUCCESS:
          return {
            ...state,
            loading: false,
            workshop: action.payload.workshop, 
            // totalFee : action.payload.totalFee
          
           
          };

          case GET_WORKSHOPS_PORTAL_SUCCESS:
            return {
              ...state,
              loading: false,
              workshops: action.payload.workshops, 
              // totalFee : action.payload.totalFee
            
             
            };

        case GET_WORKSHOPS_FAIL:
        case GET_WORKSHOP_FAIL:
          case GET_WORKSHOPS_PORTAL_FAIL:
        return {
          ...state,
          loading: false,
          workshops: null,
          workshop: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getWorkshopBooking = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_WORKSHOP_BOOKINGS_REQUEST:
      case GET_WORKSHOP_BOOKING_REQUEST:
        case GET_WORKSHOP_MYBOOKINGS_REQUEST:
        return {
          loading: true,
        };
  
        case GET_WORKSHOP_BOOKINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          bookings: action.payload.bookings, 
        };
  
        case GET_WORKSHOP_BOOKING_SUCCESS:
          return {
            ...state,
            loading: false,
            booking: action.payload.booking, 
          
           
          };

          case GET_WORKSHOP_MYBOOKINGS_SUCCESS:
            return {
              ...state,
              loading: false,
              bookings: action.payload.bookings, 
              // totalFee : action.payload.totalFee
            
             
            };

            case GET_WORKSHOP_BOOKINGS_FAIL:
              case GET_WORKSHOP_BOOKING_FAIL:
                case GET_WORKSHOP_MYBOOKINGS_FAIL:
        return {
          ...state,
          loading: false,
          workshops: null,
          workshop: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };