import {
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAIL,
    CREATE_EVENT_RESET,
    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAIL,
    UPDATE_EVENT_REQUEST,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    UPDATE_EVENT_RESET,
    HIDE_EVENT_FOR_WEB_REQUEST,
    HIDE_EVENT_FOR_WEB_SUCCESS,
    HIDE_EVENT_FOR_WEB_FAIL,
    HIDE_EVENT_FOR_WEB_RESET,
    
    HIDE_EVENT_FOR_PORTAL_REQUEST,
    HIDE_EVENT_FOR_PORTAL_SUCCESS,
    HIDE_EVENT_FOR_PORTAL_FAIL,
    HIDE_EVENT_FOR_PORTAL_RESET,

    CLEAR_ERROR,

    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_FAIL,
    GET_EVENTS_PORTAL_REQUEST,
    GET_EVENTS_PORTAL_FAIL,
    GET_EVENTS_PORTAL_SUCCESS,
} from '../constants/eventsConstant'


export const createEvent = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_EVENT_REQUEST:
        case CREATE_EVENT_REQUEST:
            case HIDE_EVENT_FOR_PORTAL_REQUEST:
                case HIDE_EVENT_FOR_WEB_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_EVENT_SUCCESS:
        case CREATE_EVENT_SUCCESS:
        case HIDE_EVENT_FOR_WEB_SUCCESS:
            case HIDE_EVENT_FOR_PORTAL_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_EVENT_FAIL:
        case CREATE_EVENT_FAIL:
        case HIDE_EVENT_FOR_PORTAL_FAIL:
            case HIDE_EVENT_FOR_WEB_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_EVENT_RESET:
        case CREATE_EVENT_RESET:
            case HIDE_EVENT_FOR_PORTAL_RESET:
                case HIDE_EVENT_FOR_WEB_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getEvent = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_EVENT_REQUEST:
      case GET_EVENTS_REQUEST:
        case GET_EVENTS_PORTAL_REQUEST:
        return {
          loading: true,
        };
  
        case GET_EVENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          events: action.payload.events, 
          totalEvents: action.payload.totalEvents,
         
        };
  
        case GET_EVENT_SUCCESS:
          return {
            ...state,
            loading: false,
            event: action.payload.event, 
            // totalFee : action.payload.totalFee
          
           
          };

          case GET_EVENTS_PORTAL_SUCCESS:
            return {
              ...state,
              loading: false,
              events: action.payload.events, 
              // totalFee : action.payload.totalFee
            
             
            };

        case GET_EVENTS_FAIL:
        case GET_EVENT_FAIL:
          case GET_EVENTS_PORTAL_FAIL:
        return {
          ...state,
          loading: false,
          events: null,
          event: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

