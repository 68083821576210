import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, CircularProgress, Box, Typography, InputAdornment, IconButton, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from '@mui/material/Modal';

import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
// components
import { clearError, forgetPassword, loadUser, login, memberFirstRegistration } from '../redux/actions/userAction';
import { FORGOT_PASSWORD_RESET, MEMBER_REGISTRATION_RESET } from '../redux/constants/userConstant';
import Iconify from './iconify';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function LoginForm({ setUser, user: user1 }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [name, setname] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setemail] = useState('null')
    const [password, setpassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)

    const { loading, isAuthenticated, error } = useSelector((state) => state.users)
    const { loading: regloading, user, success, isAuthenticated: isAuthenticated1 } = useSelector((state) => state.registration)

    useEffect(() => {
        dispatch(loadUser())
    }, [dispatch])

    useEffect(() => {

        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
        if (isAuthenticated) {
            // toast.success('Log in')
            navigate('/dashboard/app')
        }
        if (success) {
            toast.success('Registration Success.')
            dispatch({ type: MEMBER_REGISTRATION_RESET })
            setUser(user)
        }
        setemail(user1?.email)

    }, [error, isAuthenticated, success, navigate, dispatch, user])

    const regSubmitHandler = (e) => {
        e.preventDefault()

        if (password === confirmPassword) {
            dispatch(memberFirstRegistration({ email, password, name }))
        } else {
            toast.error('Passwords not match')
        }
    }

    // modal box 
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { loading: loadingForPass, message, error: errorForPass } = useSelector((state) => state.forgetPassword)

    useEffect(() => {
        if (errorForPass) {
            toast.error(errorForPass)
            dispatch(clearError())
        }
        if (message) {
            toast.success(message)
            dispatch({ type: FORGOT_PASSWORD_RESET })

            handleClose();
        }

    }, [message, errorForPass])

    const fogetPasswordSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(forgetPassword(email))

    }

    return (
        <>
            <form action="" onSubmit={regSubmitHandler}>
                <Stack spacing={3}>
          {/* <FormControl> */}
                    <TextField name="email"
                        value={email}
                        disabled
                        onChange={e => setemail(e.target.value)}
                        required label="" />
                    <TextField name="name"
                        onChange={e => setname(e.target.value)}
                        required label="Name" />
                    <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={e => setpassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        name="password"
                        label="Confirm Password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={e => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                {/* </FormControl> */}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember"label="Remember me" />  */}
                    {/* <Link variant="subtitle2" underline="hover" onClick={handleOpen}>
                        Forgot password?
                    </Link> */}
                </Stack>

                {regloading ?

                    (<LoadingButton fullWidth size="large" variant="text" >
                        <CircularProgress color='success' />
                    </LoadingButton>)
                    :

                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                        Login
                    </LoadingButton>)

                }
            </form>


            {/* modal code  */}
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Forget Password Request
                        </Typography>
                        <form action="" onSubmit={fogetPasswordSubmitHandler}>
                            <Stack spacing={3} margin='1.5vmax 0'>

                                <TextField name="email"
                                    onChange={e => setemail(e.target.value)}
                                    required label="Email address" />
                            </Stack>

                            {loadingForPass ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                    Request
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>
        </>
    );
}
