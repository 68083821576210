import React, { useEffect } from 'react'
// import './workshopBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getWorkshopsForPortal } from '../../redux/actions/workshopAction';

const WorkshopsBooking = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getWorkshopsForPortal())
    }, [dispatch])

    const { loading: getLoading, workshops, error: getErr } = useSelector((state) => state.getWorkshops)
    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>Workshops</Typography>

            {
                workshops && workshops.map((item,i) => (
                    <div className='eventListBox'>
                        <div className="imgBox">
                            <img src={item.banner?.url} alt="" />
                        </div>
                        <div className='sponsersBox'>
                            <h3>{item.name}</h3>

                            {/* <h3></h3> */}
                            <div>
                                <h4> <b>Sponsors</b>  : {item.sponsors}</h4>
                            </div>

                            {/* <Typography variant="h5" sx={{ mb: 1 }}>Workshops Resigistaion</Typography> */}

                            <Stack direction='row' spacing={2}>

                                {
                                    Date.now() <= new Date(workshops[i]?.bookingEndDate) ?
                                        // <Link to={`/dashboard/workshop-registration/${item._id}`}>
                                        <Link to={`/workshop-registration/${item._id}`}>
                                            <Button variant="contained" style={{ color: 'white' }} color='success'>
                                                Book Now
                                            </Button>
                                        </Link>
                                        : <Button variant="contained" color='success' disabled={'true'} style={{ color: 'white' }} >
                                            Booking Closed
                                        </Button>
                                }

                            </Stack>
                        </div>



                    </div>
                ))
            }
        </>

    )
}

export default WorkshopsBooking