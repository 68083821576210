import {
    CREATE_NEWS_LETTER_REQUEST,
    CREATE_NEWS_LETTER_SUCCESS,
    CREATE_NEWS_LETTER_FAIL,
    CREATE_NEWS_LETTER_RESET,
   
    UPDATE_NEWS_LETTER_REQUEST,
    UPDATE_NEWS_LETTER_SUCCESS,
    UPDATE_NEWS_LETTER_FAIL,
    UPDATE_NEWS_LETTER_RESET,
   
    CLEAR_ERROR,
    GET_ALL_NEWS_LETTER_SUCCESS,
    GET_ALL_NEWS_LETTER_FAIL,
    GET_ALL_NEWS_LETTER_REQUEST,
    GET_ALL_MEMBER_NL_REQUEST,
    GET_NEWS_LETTER_REQUEST,
    GET_NEWS_LETTER_FAIL,
    GET_ALL_MEMBER_NL_FAIL,
    GET_NEWS_LETTER_SUCCESS,
    GET_ALL_MEMBER_NL_SUCCESS,
    MAGAZINE_NOTIFICATION_REQUEST,
    MAGAZINE_NOTIFICATION_SUCCESS,
    MAGAZINE_NOTIFICATION_FAIL,
    MAGAZINE_NOTIFICATION_RESET,
    WELCOME_NOTIFICATION_REQUEST,
    WELCOME_NOTIFICATION_SUCCESS,
    WELCOME_NOTIFICATION_FAIL,
    WELCOME_NOTIFICATION_RESET,
} from '../constants/newsLetterConstant'


export const createNewsLetter = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_NEWS_LETTER_REQUEST:
        case CREATE_NEWS_LETTER_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_NEWS_LETTER_SUCCESS:
        case CREATE_NEWS_LETTER_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_NEWS_LETTER_FAIL:
        case CREATE_NEWS_LETTER_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_NEWS_LETTER_RESET:
        case CREATE_NEWS_LETTER_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };



  export const getNewsLetter = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_ALL_NEWS_LETTER_REQUEST:
        case GET_ALL_MEMBER_NL_REQUEST:
            case GET_NEWS_LETTER_REQUEST:
        return {
          loading: true,
        };
  
        case GET_ALL_NEWS_LETTER_SUCCESS:
            case GET_ALL_MEMBER_NL_SUCCESS:
        return {
          ...state,
          loading: false,
          newsLetters: action.payload.newsLetters,
        };
  
        case GET_NEWS_LETTER_SUCCESS:
            return {
              ...state,
              loading: false,
              newsLetter: action.payload.newsLetter,
            };
      

        case GET_ALL_NEWS_LETTER_FAIL:
        case GET_ALL_MEMBER_NL_FAIL:
            case GET_NEWS_LETTER_FAIL:
        return {
          ...state,
          loading: false,
          members: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const createNewsMail = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case MAGAZINE_NOTIFICATION_REQUEST:
        case WELCOME_NOTIFICATION_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case MAGAZINE_NOTIFICATION_SUCCESS:
        case WELCOME_NOTIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case MAGAZINE_NOTIFICATION_FAIL:
        case WELCOME_NOTIFICATION_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case MAGAZINE_NOTIFICATION_RESET:
          case WELCOME_NOTIFICATION_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
