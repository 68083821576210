export const CREATE_GALLERY_REQUEST = "CREATE_GALLERY_REQUEST"
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS"
export const CREATE_GALLERY_FAIL = "CREATE_GALLERY_FAIL"
export const CREATE_GALLERY_RESET = "CREATE_GALLERY_RESET"

export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST"
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL"

export const GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST"
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS"
export const GET_IMAGES_FAIL = "GET_IMAGES_FAIL"

export const UPDATE_GALLERY_REQUEST = "UPDATE_GALLERY_REQUEST"
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS"
export const UPDATE_GALLERY_FAIL = "UPDATE_GALLERY_FAIL"
export const UPDATE_GALLERY_RESET = "UPDATE_GALLERY_RESET"


export const DELETE_GALLERY_REQUEST = "DELETE_GALLERY_REQUEST"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"
export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL"
export const DELETE_GALLERY_RESET = "DELETE_GALLERY_RESET"

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST"
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS"
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL"
export const DELETE_IMAGE_RESET = "DELETE_IMAGE_RESET"


export const RECOVER_GALLERY_REQUEST = "RECOVER_GALLERY_REQUEST"
export const RECOVER_GALLERY_SUCCESS = "RECOVER_GALLERY_SUCCESS"
export const RECOVER_GALLERY_FAIL = "RECOVER_GALLERY_FAIL"
export const RECOVER_GALLERY_RESET = "RECOVER_GALLERY_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"