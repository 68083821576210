import {
    CREATE_ORG_DIR_REQUEST,
    CREATE_ORG_DIR_SUCCESS,
    CREATE_ORG_DIR_FAIL,
    CREATE_ORG_DIR_RESET,
    GET_ORG_DIR_REQUEST,
    GET_ORG_DIR_SUCCESS,
    GET_ORG_DIR_FAIL,
    UPDATE_ORG_DIR_REQUEST,
    UPDATE_ORG_DIR_SUCCESS,
    UPDATE_ORG_DIR_FAIL,
    UPDATE_ORG_DIR_RESET,
    DELETE_ORG_DIR_REQUEST,
    DELETE_ORG_DIR_SUCCESS,
    DELETE_ORG_DIR_FAIL,
    DELETE_ORG_DIR_RESET,
    RECOVER_ORG_DIR_REQUEST,
    RECOVER_ORG_DIR_SUCCESS,
    RECOVER_ORG_DIR_FAIL,
    RECOVER_ORG_DIR_RESET,
    CLEAR_ERROR,
    GET_MY_ORG_REQUEST,
    GET_MY_ORG_SUCCESS,
    GET_MY_ORG_FAIL,
    GET_ORG_REQUEST,
    GET_ORG_SUCCESS,
    GET_ORG_FAIL,
} from '../constants/orgDirConstant'


export const createOrgDir = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_ORG_DIR_REQUEST:
        case CREATE_ORG_DIR_REQUEST:
            case DELETE_ORG_DIR_REQUEST:
                case RECOVER_ORG_DIR_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_ORG_DIR_SUCCESS:
        case CREATE_ORG_DIR_SUCCESS:
        case DELETE_ORG_DIR_SUCCESS:
            case RECOVER_ORG_DIR_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_ORG_DIR_FAIL:
        case CREATE_ORG_DIR_FAIL:
        case DELETE_ORG_DIR_FAIL:
            case RECOVER_ORG_DIR_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_ORG_DIR_RESET:
        case CREATE_ORG_DIR_RESET:
            case RECOVER_ORG_DIR_RESET:
                case DELETE_ORG_DIR_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getOrgDir = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_ORG_DIR_REQUEST:
        case GET_MY_ORG_REQUEST:
        return {
          loading: true,
        };
  
        case GET_ORG_DIR_SUCCESS:
        return {
          ...state,
          loading: false,
          orgs: action.payload.orgs,
          totalOrgs: action.payload.totalOrgs,
          resultPerPage: action.payload.resultPerPage,
        };
        case GET_MY_ORG_SUCCESS:
          return {
            ...state,
            loading: false,
            orgs: action.payload.orgs,
            totalOrgs: action.payload.totalOrgs,
          };
    

        case GET_ORG_DIR_FAIL:
          case GET_MY_ORG_FAIL:
        return {
          ...state,
          loading: false,
          orgs: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getMyOrg = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_MY_ORG_REQUEST:
          case GET_ORG_REQUEST:
        return {
          loading: true,
        };
  
       
        case GET_MY_ORG_SUCCESS:
          case GET_ORG_SUCCESS:
          return {
            ...state,
            loading: false,
            org: action.payload.org,
          };
    

          case GET_MY_ORG_FAIL:
            case GET_ORG_FAIL:
        return {
          ...state,
          loading: false,
          org: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };