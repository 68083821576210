import React, { useEffect } from 'react'
import './eventBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ZeroState from '../components/ZeroState';
import { getDateTime } from '../utils/getDate';
import { getMyBooking } from '../redux/actions/bookingActions';

const MyBooking = () => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.users)

    const { loading: getLoading, bookings, error: getErr } = useSelector((state) => state.getBookings)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr)
        }
        dispatch(getMyBooking(user?._id))
    }, [dispatch])


    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>My Bookings</Typography>



            {
                getLoading ? 'loading' :
                    <div className='bookingCantainer'>
                        {
                            bookings?.length === 0 ? <ZeroState title={"Event's Booking Not Found!"}/> :
                                <>
                                    {
                                        bookings && bookings.map((item) => (
                                            <div className='bookingItem'>

                                                <div>
                                                    <Typography variant="h6" color={'secondary'} sx={{ mb: 0, }}>{item?.eventName}</Typography>
                                                    <h3>{item?.eventDate}</h3>
                                                    <Typography variant='p'>{getDateTime(item?.createAt)}</Typography>
                                                </div>

                                                <div>
                                                    <Link to={`/dashboard/bookingDetails/${item._id}`}>
                                                        <Button variant="contained" >
                                                            view
                                                        </Button>
                                                    </Link>
                                                </div>

                                                {/*

                    <Stack direction='row' spacing={2}>
                        <Link to={`/dashboard/member-registration/${item._id}`}>
                            <Button variant="contained" color='success'>
                                Indian Delegates
                            </Button>
                        </Link>
                        <Button variant="contained" >
                            Forien Delegates
                        </Button>
                    </Stack> */}
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
            }
        </>

    )
}

export default MyBooking