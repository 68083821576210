import { Helmet } from 'react-helmet-async';
import './contactForm.css'
import { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
// @mui
import {
  Card,
  Stack,
  Container,
  TextField,
  Grid,
  CircularProgress,

} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

// components
import { getDate } from '../../utils/getDate';
import { getContactAction, clearError } from '../../redux/actions/contactAction';

import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';


// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  // height: '90vh',
  overflow: 'auto',
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function UserPage() {

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');


  const { loading, contacts, resultPerPage, totalContacts, error } = useSelector((state) => state.contacts)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(totalUsers, resultPerPage, users)

  useEffect(() => {

    dispatch(getContactAction(page, keyword))
  }, [dispatch, page])

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
  }, [error, navigate, dispatch])

  // useEffect(() => {
  //   setPage(1)
  //   toast.success(page)
  // }, [keyword])


  const setKeywordHandle = (e)=>{
    // console.log(e.target.value)
    setKeyword(e.target.value)
    setPage(1)


  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, },
    { field: 'name', headerName: 'Name', width: 180 },
    { field: 'email', headerName: 'Email', width: 270 },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 130,
    },



    { field: 'subject', headerName: 'Subject', width: 170 },
    { field: 'message', headerName: 'Message', width: 170 },
    { field: 'createAt', headerName: 'createdAt', width: 170 },


  ];
  const rows = [];


  contacts?.forEach((item, i) => {
    const date = new Date(item?.createAt)

    rows.push({
      id: i + 1,
      id_: item._id,
      name: item.name,
      message: item.message,
      email: item.email,
      subject: item.subject,
      phone: item.phone,
      createAt: getDate(item?.createAt),

    });
  });

  // searchHandler
  const searchHandler = (e) => {
    e.preventDefault();
    dispatch(getContactAction(page, keyword))

  }
  // const handlePage = (event, value) => {
  //   // console.log(value)
  //   setPage(value);
  // };


// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------



  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div className='totalComponents'>
            <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="Total Request" total={totalContacts} icon={'ph:users-fill'} />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="InActive Users" total={1} color="info" icon={'ph:users-fill'} />
            </Grid> */}
          </div>

          {/* <Stack direction={'row'} spacing={2}>
     
          </Stack> */}
        </Stack>

        <Card>
          <div className='tableTop'>
            <form onSubmit={searchHandler}>
              <TextField type='text' onChange={e => setKeywordHandle(e)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
            </form>

            <div hidden={!loading ? 'true' : ''}>
              <CircularProgress color="primary" />
            </div>
          </div>
          <div style={{ height: 'auto', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // checkboxSelection
              pagination={false}
              sx={
                {
                  // padding:'0 1vmax'
                }
              }
            />
          </div>

          <div className='paginationBox' >
            {
              totalContacts > resultPerPage &&
              <Pagination
                activePage={page}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={totalContacts}
                pageRangeDisplayed={5}
                onChange={e => setPage(e)}
              />
            }
          </div>
        </Card>
      </Container>




    </>
  );
}
