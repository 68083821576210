import { Helmet } from 'react-helmet-async';
import './orgDir.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel, FormControl, FormLabel, FormHelperText,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'
import { FaEye } from 'react-icons/fa';
import Pagination from 'react-js-pagination';

// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createOrgDir, deleteOrgDir, getOrgDirs, recoverOrgDir, updateOrgDir, clearError } from '../../redux/actions/orgDirAction';
import { CREATE_ORG_DIR_RESET } from '../../redux/constants/orgDirConstant';
import { allUsers } from '../../redux/actions/userAction';
import { getDate } from '../../utils/getDate';
// import { round } from 'lodash';


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function OrgDir() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrgDirs(keyword, page))
    }, [dispatch, page])

    const { loading: getmemLoading, orgs, totalOrgs, resultPerPage, error: getErr } = useSelector((state) => state.getOrgDir)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getOrgDirs(keyword, page))
    }
    const handlePage = (event, value) => {
        setPage(value);
    };


    // -----------------------------new Member Modal Code --------------------------------------

    //   users call code -------------------------

    const { loading: usersLoading, users, error: userErr } = useSelector((state) => state.allUsersForExc)
    useEffect(() => {
        dispatch(allUsers())
    }, [])

    useEffect(() => {
        if (userErr) {
            toast.error(userErr)
            dispatch(clearError())
        }
    }, [userErr, navigate, dispatch])

    //   users call code end ------------------------------------------

    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.createOrgDir)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            deleteMemberModalHandleClose()
            recoverMemberModalHandleClose()


            // data clear 
            setUpdateOwnerPic(null)
            setUpdateOrgLogo(null)

            dispatch({ type: CREATE_ORG_DIR_RESET });
            dispatch(getOrgDirs(keyword, page))

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [orgName, setOrgName] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [orgWebsite, setOrgWebsite] = useState('');
    const [orgCountry, setorgCountry] = useState('');
    const [products, setProducts] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [orgDescription, setOrgDescription] = useState('');
    const [userId, setUserId] = useState('');

    const [telephone, setTelephone] = useState('');
    const [GSTIN, setGSTIN] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [status, setStatus] = useState('');


    const [orgLogoFile, setOrgLogo] = useState('');
    // owner details 
    const [ownerPic, setOwnerPic] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerPhone, setOwnerPhone] = useState('');
    const [LinkedIn, setLinkedIn] = useState('');


    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newMemberSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createOrgDir({ userId, orgName, products, orgCountry, orgDescription, orgEmail, orgWebsite, orgLogoFile, businessType, ownerName, ownerEmail, ownerPhone, ownerPic, LinkedIn,telephone,GSTIN,status,membershipId }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setOrgLogo(e.target.result);

                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setOrgLogo(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setOrgLogo(null);
            alert("Please select an profile picture.");
        }

    };

    const handleOwnerPicChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setOwnerPic(e.target.result);

                }
            } else {
                event.target.value = null;
                setOwnerPic(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setOwnerPic(null);
            alert("Please select an profile picture.");
        }

    };


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();
    const [UpdateorgName, setUpdateOrgName] = useState('');
    const [UpdateorgEmail, setUpdateOrgEmail] = useState('');
    const [UpdateorgWebsite, setUpdateOrgWebsite] = useState('');
    const [UpdateorgCountry, setUpdateorgCountry] = useState('');
    const [Updateproducts, setUpdateProducts] = useState('');
    const [UpdatebusinessType, setUpdateBusinessType] = useState('');
    const [UpdateorgDescription, setUpdateOrgDescription] = useState('');

    const [updatetelephone, setupdateTelephone] = useState('');
    const [updateGSTIN, setupdateGSTIN] = useState('');
    const [updatemembershipId, setupdateMembershipId] = useState('');
    const [updatestatus, setupdateStatus] = useState('');

    const [UpdateorgLogoFile, setUpdateOrgLogo] = useState('');
    const [updateOrgLogoPreview, setUpdateOrgLogoPreview] = useState('');

    // owner details 
    const [UpdateOwnerPic, setUpdateOwnerPic] = useState('');
    const [UpdateOwnerPicPreview, setUpdateOwnerPicPreview] = useState('');

    const [UpdateOwnerName, setUpdateOwnerName] = useState('');
    const [UpdateOwnerEmail, setUpdateOwnerEmail] = useState('');
    const [UpdateOwnerPhone, setUpdateOwnerPhone] = useState('');
    const [UpdateLinkedIn, setUpdateLinkedIn] = useState('');


    //  modal 
    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateOrgSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { orgName: UpdateorgName, orgCountry: UpdateorgCountry, orgDescription: UpdateorgDescription, orgEmail: UpdateorgEmail, orgLogoFile: UpdateorgLogoFile, orgWebsite: UpdateorgWebsite, businessType: UpdatebusinessType, products: Updateproducts, ownerName: UpdateOwnerName, ownerEmail: UpdateOwnerEmail, ownerPhone: UpdateOwnerPhone, ownerPic: UpdateOwnerPic, LinkedIn: UpdateLinkedIn , telephone:updatetelephone, status:updatestatus,GSTIN:updateGSTIN, membershipId:updatemembershipId }
        dispatch(updateOrgDir(memberDetails, updateItem?._id))
    }

    const updateHandleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateOrgLogo(e.target.result);
                    setUpdateOrgLogoPreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateOrgLogo(null);
                setUpdateOrgLogoPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateOrgLogo(null);
            setUpdateOrgLogoPreview(null);
            alert("Please select an profile picture.");
        }

    };
    const updateHandleOwnerPicChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 1 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdateOwnerPic(e.target.result);
                    setUpdateOwnerPicPreview(e.target.result);

                }
            } else {
                event.target.value = null;
                setUpdateOwnerPic(null);
                setUpdateOwnerPicPreview(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdateOwnerPic(null);
            setUpdateOwnerPicPreview(null);
            alert("Please select an profile picture.");
        }

    };

    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateOrgName(item.orgName)
        setUpdateOrgEmail(item.orgEmail)
        setUpdateOrgWebsite(item.orgWebsite)
        setUpdateProducts(item.products)
        setUpdateorgCountry(item.orgCountry)
        setUpdateBusinessType(item.businessType)
        setUpdateOrgLogoPreview(item.orgLogo)

        setUpdateOrgDescription(item.orgDescription)
        setupdateTelephone(item.telephone)
        setupdateMembershipId(item.membershipId)
        setupdateStatus(item.status)
        setupdateGSTIN(item.GSTIN)

        setUpdateOwnerName(item.ownerName)
        setUpdateOwnerPicPreview(item.ownerProfile)
        setUpdateOwnerPhone(item.ownerPhone)
        setUpdateOwnerEmail(item.ownerEmail)
        setUpdateLinkedIn(item.LinkedIn)
        // setUpdateProfile(item.profileUrl)

        updateMemberModalHandleOpen()
    }

    // delete memeber - modal code  ---------------------------
    const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);

    const deleteMemberModalHandleOpen = () => {
        setDeleteMemberModalOpen(true)
    };

    const deleteMemberModalHandleClose = () => {
        setDeleteMemberModalOpen(false);
    }

    const deleteSubmitHandler = () => {
        dispatch(deleteOrgDir(updateItem?._id));
    }
    const deleteHandle = (item) => {
        setUpdateItem(item)
        deleteMemberModalHandleOpen()
    }
    // Recover member - modal code ---------------------------------
    const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);

    const recoverMemberModalHandleOpen = () => {
        setRecoverMemberModalOpen(true)
    };

    const recoverMemberModalHandleClose = () => {
        setRecoverMemberModalOpen(false);
    }

    const recoverSubmitHandler = () => {
        dispatch(recoverOrgDir(updateItem?._id));
    }

    const recoverHandle = (item) => {
        setUpdateItem(item)
        recoverMemberModalHandleOpen()
    }
    
// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------

    return (
        <>
            <Helmet>
                <title> Organization Directory - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Organization" total={totalOrgs} icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* <Link to={'/user/new'}> */}
                    <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Organization
                    </Button>
                    {/* </Link> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>Organization Directory</div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => { setKeyword(e.target.value); setPage(1) }} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            orgs && orgs.map((item) => (

                                <div className="member-Box">
                                    <div className="imgBoxLogo">
                                        {
                                            console.log(item.orgLogo)
                                        }
                                        <img src={item.orgLogo ? item?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div>

                                    <div className="namePositionBoxx">
                                        <h5>{item?.orgName}</h5>
                                        {/* <p>{item?.orgEmail}</p> */}
                                        <h5>{item?.orgCountry}</h5>
                                        {/* <p>{item?.orgWebsite}</p>
                                        <p>{item?.businessType}</p>
                                        <p>{item?.orgDescription}</p>
                                        <p>{item?.products}</p> */}
                                    </div>

                                    <div className="actionBox">
                                        <p className='center'> Active  :  <div className={item?.deleteAt ? 'unlive' : 'live'}> </div></p>
                                        <div className="dateBox">
                                            <p>Create : {getDate(item?.createAt)}</p>
                                        </div>
                                        <div className="icon-Box">

                                            {
                                                item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                                                    <MdSettingsBackupRestore />
                                                </button>) : (<>
                                                    <button onClick={e => editHandle(item)}>
                                                        <MdModeEdit />
                                                    </button>
                                                    <button onClick={e => deleteHandle(item)}>
                                                        <MdDelete />
                                                    </button>
                                                    <Link to={`/dashboard/organization/${item._id}`}>
                                                        <button onClick={e => deleteHandle(item)}>
                                                            <FaEye />
                                                        </button>
                                                    </Link>
                                                </>)
                                            }


                                        </div>

                                    </div>

                                </div>
                            ))
                        }


                    </div>
                    <div className='paginationBox' >
                        {
                            totalOrgs > resultPerPage &&
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={totalOrgs}
                                pageRangeDisplayed={5}
                                onChange={e => setPage(e)}
                            />
                        }
                    </div>
                </Card>
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                New Organization Directory
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newMemberSubmitHandler}>
                            <Stack alignItems={'flex-start'} spacing={1} margin='1vmax 0'>
                                <Typography color="initial">Select User</Typography>

                                <FormControl fullWidth >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size='small'
                                        fullWidth
                                        required
                                        value={userId}
                                        onChange={e => setUserId(e.target.value)}
                                        label="Select User"
                                    >
                                        {/* <MenuItem value=''>Select User</MenuItem> */}

                                        {
                                            users && users.map(item => (
                                                <MenuItem value={item?._id}>{item?.name}</MenuItem>

                                            ))
                                        }
                                    </Select>
                                </FormControl>


                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={orgName}
                                    onChange={e => setOrgName(e.target.value)}
                                    required label="Organization Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={orgEmail}
                                    onChange={e => setOrgEmail(e.target.value)}
                                    required label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    value={orgCountry}
                                    onChange={e => setorgCountry(e.target.value)}
                                    required label="Location" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={orgWebsite}
                                    onChange={e => setOrgWebsite(e.target.value)}
                                    required label="Website" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="companyName"
                                    size='small'
                                    value={orgDescription}
                                    onChange={e => setOrgDescription(e.target.value)}
                                    label="Description" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={businessType}
                                    onChange={e => setBusinessType(e.target.value)}
                                    required label="Nature of Business" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={products}
                                    onChange={e => setProducts(e.target.value)}
                                    required label="Key Products" />
                            </Stack>

                            {/* optional */}
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={telephone}
                                    onChange={e => setTelephone(e.target.value)}
                                    label="Contact" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={GSTIN}
                                    onChange={e => setGSTIN(e.target.value)}
                                    label="GSTIN" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={membershipId}
                                    onChange={e => setMembershipId(e.target.value)}
                                    label="Membership Id" />
                            </Stack>
                            {/* <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={status}
                                    onChange={e => setStatus(e.target.value)}
                                    label="Status" />
                            </Stack> */}

                            {/* <Stack spacing={3} margin='1vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-member'}>Board Member</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}

                            <p>Select Organization Logo</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    required
                                    type="file"
                                    // accept=""
                                    onChange={handleFileChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    orgLogoFile &&
                                    <img src={orgLogoFile} width={100} style={{ borderRadius: '0.4rem' }} alt="" />
                                }
                            </Stack>
                            <Divider sx={{ margin: '1vmax 0' }} />
                            <Typography color="initial">Contact Person (Optional)</Typography>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={ownerName}
                                    onChange={e => setOwnerName(e.target.value)}
                                    label=" Name" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={ownerEmail}
                                    onChange={e => setOwnerEmail(e.target.value)}
                                    label="Email" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    type='number'
                                    value={ownerPhone}
                                    onChange={e => setOwnerPhone(e.target.value)}
                                    label=" Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={LinkedIn}
                                    onChange={e => setLinkedIn(e.target.value)}
                                    label="LinkedIn Url" />
                            </Stack>

                            <p>Select  Owner Profile </p>
                            <Stack spacing={3} direction={'row'} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"

                                    type="file"
                                    // accept=""
                                    onChange={handleOwnerPicChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    ownerPic &&
                                    <img src={ownerPic} width={100} style={{ borderRadius: '0.4rem' }} alt="" />
                                }
                            </Stack>


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update  Organization Directory
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateOrgSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={UpdateorgName}
                                    onChange={e => setUpdateOrgName(e.target.value)}
                                    required label="Org Name" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={UpdateorgEmail}
                                    onChange={e => setUpdateOrgEmail(e.target.value)}
                                    label="Email address" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="phone"
                                    size='small'
                                    value={UpdateorgCountry}
                                    onChange={e => setUpdateorgCountry(e.target.value)}
                                    label="Location" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="position"
                                    size='small'
                                    value={UpdateorgWebsite}
                                    onChange={e => setUpdateOrgWebsite(e.target.value)}
                                    label="Website" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="companyName"
                                    size='small'
                                    value={UpdateorgDescription}
                                    onChange={e => setUpdateOrgDescription(e.target.value)}
                                     label="Description" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdatebusinessType}
                                    onChange={e => setUpdateBusinessType(e.target.value)}
                                     label="Nature of Business" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={Updateproducts}
                                    onChange={e => setUpdateProducts(e.target.value)}
                                    label="Key Products" />
                            </Stack>

                            {/* optional */}
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatetelephone}
                                    onChange={e => setupdateTelephone(e.target.value)}
                                    label="Contact" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updateGSTIN}
                                    onChange={e => setupdateGSTIN(e.target.value)}
                                    label="GSTIN" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatemembershipId}
                                    onChange={e => setupdateMembershipId(e.target.value)}
                                    label="Membership Id" />
                            </Stack>
                            {/* <Stack spacing={3} margin='1vmax 0'>
                                <TextField
                                    size='small'
                                    value={updatestatus}
                                    onChange={e => setupdateStatus(e.target.value)}
                                    label="Status" />
                            </Stack> */}


                            {/* <Stack spacing={3} margin='1vmax 0' >
                                <InputLabel id="demo-controlled-open-select-label">Select Container</InputLabel>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={flag}
                                    // label="Age"
                                    displayEmpty
                                    onChange={e => setFlag(e.target.value)}
                                    required
                                    size='small'
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'board-member'}>Board Member</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'special-invitee'}>Special invitee</MenuItem>
                                    <MenuItem value={'zonal'}>Zonal</MenuItem>
                                </Select>
                            </Stack> */}

                            <p>Select Logo</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    // required
                                    type="file"
                                    // accept=""
                                    onChange={updateHandleFileChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    updateOrgLogoPreview &&
                                    <img src={updateOrgLogoPreview} width={100} height={68} style={{ borderRadius: '0.4rem' }} alt="" />
                                }
                            </Stack>
                            <Divider sx={{ margin: '1vmax 0' }} />
                            <Typography color="initial">Contact person (Optional)</Typography>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdateOwnerName}
                                    onChange={e => setUpdateOwnerName(e.target.value)}
                                    label="Name" />
                            </Stack>

                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdateOwnerEmail}
                                    onChange={e => setUpdateOwnerEmail(e.target.value)}
                                    label="Email" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    type='number'
                                    value={UpdateOwnerPhone}
                                    onChange={e => setUpdateOwnerPhone(e.target.value)}
                                    label="Phone" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="address"
                                    size='small'
                                    value={UpdateLinkedIn}
                                    onChange={e => setUpdateLinkedIn(e.target.value)}
                                    label="LinkedIn Url" />
                            </Stack>

                            <p>Select  Owner profile pic</p>
                            <Stack direction={'row'} spacing={3} margin='1vmax 0'>
                                <input
                                    className="inpProfileBtn"
                                    type="file"
                                    // accept=""
                                    onChange={updateHandleOwnerPicChange}
                                    name="profile"
                                    placeholder="Logo"
                                />
                                {
                                    UpdateOwnerPicPreview &&
                                    <img src={UpdateOwnerPicPreview} width={100} height={68} style={{ borderRadius: '0.4rem' }} alt="" />
                                }
                            </Stack>

                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    Update
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>



            {/* delete Member  modal code  */}
            <div>
                <Modal
                    open={deleteMemberModalOpen}
                    onClose={deleteMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style1} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Delete Organization
                            </Typography>
                            <Button onClick={deleteMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure delete the {updateItem?.orgName} ?</h3>

                            <div className='actionBtnBox'>
                                {createMemberLoading ? (
                                    <LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>
                                ) : (
                                    <Button variant={'contained'} onClick={deleteSubmitHandler} size='small' >
                                        yes
                                    </Button>
                                )
                                }
                                <Button variant={'outlined'} onClick={deleteMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>

            {/* Recover Member  modal code  */}
            <div>
                <Modal
                    open={recoverMemberModalOpen}
                    onClose={recoverMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style1} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Recover Executive Committee
                            </Typography>
                            <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <Stack spacing={3} direction='column' justifyContent={'center'}>
                            <h3>Are your sure Recover the {updateItem?.name} ?</h3>

                            <div className='actionBtnBox'>
                                {
                                    createMemberLoading ? (
                                        <LoadingButton fullWidth size="large" variant="text" >
                                            <CircularProgress color='success' />
                                        </LoadingButton>
                                    ) : (
                                        <Button variant={'contained'} onClick={recoverSubmitHandler} size='small' >
                                            yes
                                        </Button>
                                    )
                                }
                                <Button variant={'outlined'} onClick={recoverMemberModalHandleClose} color='secondary' size='small' >
                                    cancel
                                </Button>

                            </div>

                        </Stack>
                    </Box>
                </Modal>
            </div>




        </>
    );
}
