import axios from "axios";
import { CANCEL_PERSON_FAIL, CANCEL_PERSON_REQUEST, CANCEL_PERSON_SUCCESS, CLEAR_ERROR, CREATE_BOOKING_FAIL, CREATE_BOOKING_REQUEST, CREATE_BOOKING_SUCCESS, CREATE_PAYMENT_FAIL, CREATE_PAYMENT_REQUEST, CREATE_PAYMENT_SUCCESS, GET_ALL_BOOKINGS_FAIL, GET_ALL_BOOKINGS_REQUEST, GET_ALL_BOOKINGS_SUCCESS, GET_BOOKING_DETAILS_FAIL, GET_BOOKING_DETAILS_REQUEST, GET_BOOKING_DETAILS_SUCCESS, GET_MY_BOOKINGS_FAIL, GET_MY_BOOKINGS_REQUEST, GET_MY_BOOKINGS_SUCCESS } from '../constants/bookingConstant'

import {host} from "../../utils/config";



export const createBooking = (details) => async (dispatch) => {

    try {
        // console.log("create Event")

        dispatch({ type: CREATE_BOOKING_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.post(`${host}/events/api/v1/booking/new`, details, config);

        dispatch({ type: CREATE_BOOKING_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CREATE_BOOKING_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const createPayment = (persons, id) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PAYMENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/payment/${id}`, persons,config);

        dispatch({ type: CREATE_PAYMENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CREATE_PAYMENT_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getAllBookings = (eventId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_BOOKINGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/bookings/${eventId}`, config);

        dispatch({ type: GET_ALL_BOOKINGS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_BOOKINGS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getBookingDetails = (id,userId,role) => async (dispatch) => {
    // console.log(id)
    try {
        dispatch({ type: GET_BOOKING_DETAILS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/booking/${id}?userId=${userId}&role=${role}`, config);

        dispatch({ type: GET_BOOKING_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_BOOKING_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getMyBooking = (userId) => async (dispatch) => {
    // console.log(id)
    try {
        dispatch({ type: GET_MY_BOOKINGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/events/api/v1/my-bookings/${userId}`, config);

        dispatch({ type: GET_MY_BOOKINGS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_MY_BOOKINGS_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const cancelPerson = (details,personId,bookingId) => async (dispatch) => {
    // console.log(id)
    try {
        dispatch({ type: CANCEL_PERSON_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/events/api/v1/booking/cancel/${personId}?bookingId=${bookingId}`,details, config);

        dispatch({ type: CANCEL_PERSON_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CANCEL_PERSON_FAIL,
            payload: error.response.data.message,
        });
    }
};






export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

