import { Helmet } from 'react-helmet-async';
import './UserNew.css'
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, IconButton, InputAdornment, TextField, CircularProgress, Box, MenuItem, Select, OutlinedInput, useTheme, InputLabel } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


// components
import { clearError, createUser, inviteUser, loadUser, login } from '../../redux/actions/userAction';
import logo from '../../images/icons/logo.png'
import inviteUserImg from '../../images/image/invite-user.avif'
import Iconify from '../../components/iconify';
// ----------------------------------------------------------------------
import useResponsive from '../../hooks/useResponsive';
import { CREATE_USER_RESET } from '../../redux/constants/userConstant';

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');

    // ----------------------------------------------------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {token} = useParams();

    const [name, setName] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)

    const [showPassword, setShowPassword] = useState(false)

    const { loading, success, error } = useSelector((state) => state.createUser)

    useEffect(() => {
        if (success) {
            toast.success('invite Send successfully');
            dispatch({type:CREATE_USER_RESET});
            navigate('/')

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }

    }, [success, error, navigate, dispatch])


    const inviteUserSubmitHandler = (e) => {
        e.preventDefault();
        if(password !== confirmPassword){
            toast.error('Confirm password not machted')
        }else{

            dispatch(inviteUser({ name, password,confirmPassword },token))
        }
    }
   


    return (
        <>
            <Helmet>
                <title>  EOAI - Invite User Registration </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    <img src={logo} width={30} alt="EOAI LOGO" />
                </div>
                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Welcome New User
                        </Typography>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                        Registration to EOAI Dashboard
                        </Typography>


                        {/* new user Form  */}
                        <>
                            <form action="" onSubmit={inviteUserSubmitHandler}>
                                <Stack spacing={3} paddingY={3}>

                                    <TextField name="name"
                                        onChange={e => setName(e.target.value)}
                                        required label="Name *" />

                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        name="Confirm password"
                                        label="Confirm Password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />


                                </Stack>


                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        Register
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
