import { Helmet } from 'react-helmet-async';
// import './contactForm.css'
import { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
// @mui
import {
    Card,
    Stack,
    Container,
    TextField,
    Grid,
    CircularProgress,

} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

// components
import { getDate, getDateTime } from '../../utils/getDate';
import { getContactAction, clearError, getTracksAction } from '../../redux/actions/contactAction';

import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
import { getAllBookings } from '../../redux/actions/bookingActions';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');


    const { loading, bookings, totalNonPaidBookings, totalPaidBookings, totalBookings,totalCancelBookings, error } = useSelector((state) => state.getBookings)
    const { eventId } = useParams()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // console.log(totalUsers, resultPerPage, users)

    useEffect(() => {
        dispatch(getAllBookings(eventId))
    }, [dispatch, page])

    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
        if (keyword === '') {
            dispatch(getTracksAction(page, keyword))
        }
    }, [error, navigate, dispatch, keyword])





    const columns = [
        { field: 'id', headerName: 'ID', width: 90, },
        { field: 'name', headerName: 'Name', width: 180 },
        { field: 'email', headerName: 'Email', width: 270 },
        // {
        //     field: 'role',
        //     headerName: 'Role',
        //     width: 130,
        // },
        { field: 'persons', headerName: 'Person Count', width: 90, },


        { field: 'status', headerName: 'Status', width: 170 },
        { field: 'createAt', headerName: 'createdAt', width: 200 },

        {
            field: '',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="actionBoxe">

                        <Link to={`/dashboard/bookingDetails/${params.row?.id_}`}>
                            <Iconify icon="akar-icons:eye" />
                        </Link>
                    </div>
                );
            },
        }


    ];
    const rows = [];


    bookings?.forEach((item, i) => {
        // const date = new Date(item?.createAt)
        rows.push({
            id: i + 1,
            id_: item._id,
            name: item.registrationInfo.FullName,
            email: item.registrationInfo.Email,
            role: item.userRole,
            status: item.isPaid ? "Payment successful " : "Payment Pending",
            persons: item.persons.length,
            createAt: getDateTime(item?.createAt),
        });

    });

    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getTracksAction(page, keyword))
    }
    // const handlePage = (event, value) => {
    //   // console.log(value)
    //   setPage(value);
    // };



    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)

    if (user) {
        if (user.role !== 'admin') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------



    return (
        <>
            <Helmet>
                <title> Users </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Bookings" total={totalBookings} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Paid Bookings" total={totalPaidBookings} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Non Paid Bookings" total={totalNonPaidBookings} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Bookings Canceled" total={totalCancelBookings} color="info" icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* <Stack direction={'row'} spacing={2}>
     
          </Stack> */}
                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!loading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <div style={{ height: 'auto', width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            // checkboxSelection
                            pagination={false}
                            sx={
                                {
                                    // padding:'0 1vmax'
                                }
                            }
                        />
                    </div>

                    <div className='paginationBox' >
                        {/* 
                        {
                            totalTracks > resultPerPage &&
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={totalTracks}
                                pageRangeDisplayed={5}
                                onChange={e => setPage(e)}
                            />
                        } 
                        */}
                    </div>
                </Card>
            </Container>




        </>
    );
}
