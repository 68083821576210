import axios from "axios";
import {
    CREATE_NEWS_LETTER_REQUEST,
    CREATE_NEWS_LETTER_SUCCESS,
    CREATE_NEWS_LETTER_FAIL,

    
    GET_ALL_NEWS_LETTER_REQUEST,
    GET_ALL_NEWS_LETTER_SUCCESS,
    GET_ALL_NEWS_LETTER_FAIL,

    UPDATE_NEWS_LETTER_REQUEST,
    UPDATE_NEWS_LETTER_SUCCESS,
    UPDATE_NEWS_LETTER_FAIL,

    CLEAR_ERROR,

    GET_ALL_MEMBER_NL_REQUEST,
    GET_ALL_MEMBER_NL_SUCCESS,
    GET_ALL_MEMBER_NL_FAIL,
    
    GET_NEWS_LETTER_REQUEST,
    GET_NEWS_LETTER_SUCCESS,
    GET_NEWS_LETTER_FAIL,
    MAGAZINE_NOTIFICATION_REQUEST,
    MAGAZINE_NOTIFICATION_SUCCESS,
    MAGAZINE_NOTIFICATION_FAIL,

} from '../constants/newsLetterConstant'

import {host} from "../../utils/config";



export const createNewsLetter = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: CREATE_NEWS_LETTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/web/api/v1/new/news-letter`, details, config);

        dispatch({ type: CREATE_NEWS_LETTER_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_NEWS_LETTER_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const newsLetterMail = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: MAGAZINE_NOTIFICATION_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/user/api/v1/magazine/notification`, details, config);

        dispatch({ type: MAGAZINE_NOTIFICATION_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: MAGAZINE_NOTIFICATION_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const getAllNewsLetter = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_NEWS_LETTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/news-letter`, config);

        dispatch({ type: GET_ALL_NEWS_LETTER_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_NEWS_LETTER_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getNewsLetter = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_NEWS_LETTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/news-letter/${id}`, config);

        dispatch({ type: GET_NEWS_LETTER_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_NEWS_LETTER_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getAllMemberNewsLetter = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MEMBER_NL_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/member/news-letter`, config);

        dispatch({ type: GET_ALL_MEMBER_NL_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_MEMBER_NL_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateNewsLetter = (details,id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_NEWS_LETTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/web/api/v1/update/news-letter/${id}`, details, config);

        dispatch({ type: UPDATE_NEWS_LETTER_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_NEWS_LETTER_FAIL,
            payload: error.response.data.message,
        });
    }
};






export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

