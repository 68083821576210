import axios from "axios";
import {  } from '../constants/bookingConstant'

import {host} from "../../utils/config";
import { CLEAR_ERROR, GET_BOOKING_PAYMENT_FAIL, GET_BOOKING_PAYMENT_REQUEST, GET_BOOKING_PAYMENT_SUCCESS, GET_RENEW_ALL_PAYMENT_FAIL, GET_RENEW_ALL_PAYMENT_REQUEST, GET_RENEW_ALL_PAYMENT_SUCCESS, GET_RENEW_PAYMENT_DETAILS_FAIL, GET_RENEW_PAYMENT_DETAILS_REQUEST, GET_RENEW_PAYMENT_DETAILS_SUCCESS } from "../constants/paymentConstant";



export const getRenewPaymentAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_RENEW_PAYMENT_DETAILS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
        const {data} = await axios.get(`${host}/user/api/v1/renew/payment/${id}`, config);
        dispatch({ type: GET_RENEW_PAYMENT_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_RENEW_PAYMENT_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getRenewAllPaymentsAction = (userId) => async (dispatch) => {
    try {
        dispatch({ type: GET_RENEW_ALL_PAYMENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
        const {data} = await axios.get(`${host}/user/api/v1/renew/payments/${userId}`,  config);
        dispatch({ type: GET_RENEW_ALL_PAYMENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_RENEW_ALL_PAYMENT_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const bookingPayment = (bookingId,details) => async (dispatch) => {
    console.log(bookingId, details)
    try {
        dispatch({ type: GET_BOOKING_PAYMENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
        const {data} = await axios.put(`${host}/events/api/v1/payment/request/${bookingId}`,details, config);
        dispatch({ type: GET_BOOKING_PAYMENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_BOOKING_PAYMENT_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};