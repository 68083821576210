import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';
import { FaCheckCircle, FaHome } from 'react-icons/fa';
import { MdCancel, MdOutlineErrorOutline } from 'react-icons/md';

import { clearError, getRenewAllPaymentsAction } from '../redux/actions/paymentAction';
import ZeroState from '../components/ZeroState';
// import { useState } from 'react';

const PaymentStatusPage = () => {
    // const [paymentDetails, setPaymentDetails] = useState(null)
    let paymentDetails;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, payments, error } = useSelector((state) => state.payment)
    const { user } = useSelector((state) => state.users)

    useEffect(() => {
        dispatch(getRenewAllPaymentsAction(user?._id))
    }, [dispatch, user?._id])

    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
    }, [error, navigate, dispatch])

    //   console.log(paymentDetails)

    // useEffect(() => {
    //     if (payment?.paymentDetails) {
    //         const data = JSON.parse(payment?.paymentDetails)
    //         setPaymentDetails(data)
    //     }
    // }, [payment])

    return (
        <>
            <Stack width={'100%'} spacing={2} alignItems={'center'} padding={10} paddingLeft={25} paddingRight={25}>
                <Typography variant="h4" color="">Payment History</Typography>
                {
                    payments?.length === 0 ? <ZeroState title={"No Transaction"} /> :
                        <>
                            {
                                payments?.map((payment) => (
                                    <Link width={'100%'} to={`/payment/${payment?._id}`} style={{ textDecoration: 'none', width: '100%' }}>
                                        <Stack width={'100%'} spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'} borderRadius={2} padding={2} border={1} paddingLeft={5} paddingRight={5}>
                                            <Stack>
                                                <Typography variant="p" textAlign={'left'} fontWeight={900} color="">Amount :  {JSON.parse(payment?.paymentDetails).amount}</Typography>
                                                <Typography variant="p" textAlign={'left'} color="">Date :{JSON.parse(payment?.paymentDetails).trans_date} </Typography>
                                            </Stack>
                                            {
                                                JSON.parse(payment?.paymentDetails).order_status === 'Success' ?
                                                    <Typography variant="p" textAlign={'left'} color="green" fontWeight={900} >{JSON.parse(payment?.paymentDetails).status_message}</Typography>
                                                    :
                                                    <Typography variant="p" textAlign={'left'} color="red" fontWeight={900} >{JSON.parse(payment?.paymentDetails).status_message}</Typography>
                                            }
                                        </Stack></Link>
                                ))
                            }
                        </>
                }
            </Stack>
        </>



    )
}

export default PaymentStatusPage