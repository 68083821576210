import { Helmet } from 'react-helmet-async';
// import './ExeCommitee.css'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    Switch,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MdDelete, MdFileDownload, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'
// components
import { LoadingButton } from '@mui/lab';
// import SendIcon from '@mui/icons-material/Send';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';

// sections
import { CREATE_EXE_COMMITTEE_RESET } from '../../redux/constants/exeCommitteeConstant';
import { CREATE_NEWS_LETTER_RESET, MAGAZINE_NOTIFICATION_RESET } from '../../redux/constants/newsLetterConstant';
import { createExeMember, deleteExeMember, getExeMembers, recoverExeMember, updateExeMember, clearError } from '../../redux/actions/exeCommitteeAction';
import { getAllNewsLetter, createNewsLetter, updateNewsLetter, newsLetterMail } from '../../redux/actions/newsLetterAction';
import { getDate } from '../../utils/getDate';


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function NewsLetterPage() {

    // const [page, setPage] = useState(1);
    // const [keyword, setKeyword] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllNewsLetter())
    }, [dispatch])

    const { loading: getmemLoading, newsLetters, error: getErr } = useSelector((state) => state.getNewsLetter)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])


    // searchHandler
    // const searchHandler = (e) => {
    //     e.preventDefault();
    //     dispatch(getExeMembers(keyword, page))
    // }
    // const handlePage = (event, value) => {
    //     setPage(value);
    // };



    // -----------------------------new Member Modal Code --------------------------------------

    const { loading: createMemberLoading, success: createMemberSuccess, error } = useSelector((state) => state.createNewsLetter)

    useEffect(() => {
        if (createMemberSuccess) {
            toast.success('updated success');
            MemberModalHandleClose()
            updateMemberModalHandleClose()
            // deleteMemberModalHandleClose()
            recoverMemberModalHandleClose()
            dispatch({ type: CREATE_NEWS_LETTER_RESET });
            dispatch(getAllNewsLetter())

        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, createMemberSuccess, dispatch])


    const [fileName, setFileName] = useState('');
    const [heading, setHeading] = useState('');
    const [activeMember, setActiveMember] = useState(false);
    const [activeNonMember, setActiveNonMember] = useState(false);

    const [pdFile, setPdFile] = useState('');

    const [memberModalOpen, setMemberModalOpen] = useState(false);

    const MemberModalHandleOpen = () => {
        setMemberModalOpen(true)
    };
    const MemberModalHandleClose = () => {
        setMemberModalOpen(false);
    }

    const newMemberSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createNewsLetter({ fileName, heading, pdfFile: pdFile, activeMember, activeNonMember }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setfileName(file?.name)

        if (file) {
            if (file.size <= 5 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setPdFile(e.target.result);


                }
            } else {
                event.target.value = null;
                setPdFile(null);

                alert("File size exceeds the limit of 5 MB.");
            }
        } else {
            event.target.value = null;
            setPdFile(null);

            alert("Please select an profile picture.");
        }

    };


    //  -------------------  update modal code  -----------------------------------
    const [updateItem, setUpdateItem] = useState();


    const [updateFileName, setUpdateFileName] = useState('');
    const [updateHeading, setUpdateHeading] = useState('');
    const [updateActiveMember, setUpdateActiveMember] = useState('');
    const [updateActiveNonMember, setUpdateActiveNonMember] = useState('');


    const [updatePdfFile, setUpdatePdfFile] = useState('');

    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);

    const updateMemberModalHandleOpen = () => {
        setUpdateMemberModalOpen(true)
    };
    const updateMemberModalHandleClose = () => {
        setUpdateMemberModalOpen(false);
    }

    const updateMemberSubmitHandler = (e) => {
        e.preventDefault()
        const memberDetails = { updateFileName, updateHeading, updateActiveMember, updateActiveNonMember, updatePdfFile }
        dispatch(updateNewsLetter(memberDetails, updateItem?._id))
    }

    const updateHandleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size <= 20 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setUpdatePdfFile(e.target.result);
                }
            } else {
                event.target.value = null;
                setUpdatePdfFile(null);
                alert("File size exceeds the limit of 1 MB.");
            }
        } else {
            event.target.value = null;
            setUpdatePdfFile(null);
            alert("Please select an profile picture.");
        }

    };

    const editHandle = (item) => {
        setUpdateItem(item)

        setUpdateFileName(item.fileName)
        setUpdateHeading(item.heading)
        setUpdateActiveMember(item.activeMember)
        setUpdateActiveNonMember(item.activeNonMember)
        // setUpdateProfile(item.profileUrl)
        updateMemberModalHandleOpen()
    }

    // // delete memeber - modal code  ---------------------------
    // const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);

    // const deleteMemberModalHandleOpen = () => {
    //     setDeleteMemberModalOpen(true)
    // };

    // const deleteMemberModalHandleClose = () => {
    //     setDeleteMemberModalOpen(false);
    // }

    // const deleteSubmitHandler = () => {
    //     dispatch(deleteExeMember(updateItem?._id));
    // }
    // const deleteHandle = (item) => {
    //     setUpdateItem(item)
    //     deleteMemberModalHandleOpen()
    // }

    
    // send mail - modal code ---------------------------------
    const [recoverMemberModalOpen, setRecoverMemberModalOpen] = useState(false);
    const [role, setRole] = useState('');
    const [emails, setEmails] = useState('');

    // let emailItem = {}

    const recoverMemberModalHandleOpen = () => {
        setRecoverMemberModalOpen(true)
    };

    const recoverMemberModalHandleClose = () => {
        setRecoverMemberModalOpen(false);
    }


    const mailSubmitHandler = (e) => {
        e.preventDefault()
        const emailData = { role, fileName: updateItem.fileName, heading: updateItem.heading, pdfUrl: updateItem.pdfUrl, emails }
        // console.log(emailData)
        dispatch(newsLetterMail(emailData));
    }

    const mailHandle = async (item) => {
       setUpdateItem(item)
        recoverMemberModalHandleOpen()

    }

    const { loading: mailLoading, success: mailSuccess, error: mailErr } = useSelector((state) => state.notification)

    useEffect(() => {
        if (mailSuccess) {
            toast.success('Mail send  SuccessFully');
            recoverMemberModalHandleClose()
            dispatch({ type: MAGAZINE_NOTIFICATION_RESET });


        }
        if (mailErr) {
            toast.error(mailErr);
            dispatch(clearError());
        }
    }, [mailErr, mailSuccess, dispatch])

// ----------------- Admin Authentication -----------------------------------------
const { user } = useSelector((state) => state.users)
if (user) {
  if(user.role !== 'admin'){
    navigate('/dashboard/app')
  }
}else{
  navigate('/')
}

// ----------------- Admin Authentication end -----------------------------------------
    return (
        <>
            <Helmet>
                <title> News Letter Mange - eoai </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            {/* <AppWidget title="Total" total={newsLetters?.length === 0 ? '0' : newsLetters?.length} icon={'ph:users-fill'} /> */}
                        </Grid>
                    </div>


                    <Button variant="contained" onClick={MemberModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        News Letter
                    </Button>

                </Stack>

                <Card>
                    <div className='tableTop'>
                        <div hidden={!getmemLoading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div>All News Letters</div>

                        {/* <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}


                    </div>
                    <Divider />

                    <div className="teamContainers">
                        {
                            newsLetters && newsLetters.map((item) => (
                                <div className="member-Box">
                                    {/* <div className="imgBoxl">
                                        <img src={item.profileUrl ? item.profileUrl : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" />
                                    </div> */}

                                    <div className="namePositionBoxx">
                                        <h5><b>File name </b> : {item?.fileName}</h5>
                                        <p><b>Heading </b> : {item?.heading}</p>
                                        <a href={item?.pdfUrl} download rel="noreferrer" target='_blank'>
                                            <Button variant="outlined" startIcon={<MdFileDownload />}>
                                                File
                                            </Button>
                                        </a>
                                        {item?.pdfUr}
                                    </div>

                                    <div className="actionBox">
                                        <div className="dateBox">
                                            <p>Create : {getDate(item?.createAt)}</p>
                                        </div>
                                        {/* <Stack direction={'row'} alignItems={'center'} spacing={2} margin={'0vmax 0'} > */}
                                        {/* <p className='center'>Active on website
                                            <div className={item?.deleteAt ? 'unlive' : 'live'}> </div>
                                        </p> */}
                                        {/* </Stack> */}
                                        <div className="icon-Box">

                                            {
                                                item?.deleteAt ? (<button >
                                                    <MdSettingsBackupRestore />
                                                </button>) : (<>
                                                    <button onClick={e => editHandle(item)}>
                                                        <MdModeEdit />
                                                    </button>
                                                </>)
                                            }




                                        </div>

                                        <Stack direction={'row'} justifyContent={'center'} className=''>
                                            <Button onClick={e => mailHandle(item)}>send mail</Button>
                                        </Stack>


                                    </div>

                                </div>
                            ))
                        }


                    </div>
                    {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                </Card>
            </Container>


            {/* New Member  modal code  */}
            <div>
                <Modal
                    open={memberModalOpen}
                    onClose={MemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Create News Letter
                            </Typography>
                            <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={newMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={heading}
                                    onChange={e => setHeading(e.target.value)}
                                    required label="Heading" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={fileName}
                                    onChange={e => setFileName(e.target.value)}
                                    required label="File Name" />
                            </Stack>
                            <Stack spacing={1} margin='1vmax 0' direction={'column'} alignItems={'flex-start'} >
                                <Typography color="initial">Select News Latter Pdf</Typography>
                                <input
                                    className="inpProfileBtn"
                                    required
                                    type="file"
                                    // accept=""
                                    onChange={handleFileChange}
                                    name="profile"
                                    placeholder="Pdf"
                                />
                                {/* <img src={pdFile} alt="" /> */}
                            </Stack>

                            <Stack spacing={0} margin='2vmax 0' direction={'column'} justifyContent={'flex-start'}>
                                <Stack spacing={3} direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
                                    <Typography color="initial" textAlign={'right'} >Active for Members</Typography>
                                    <Switch
                                        checked={activeMember}
                                        onChange={(event) => setActiveMember(event.target.checked)}
                                    />
                                </Stack>

                                <Stack spacing={3} direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
                                    <Typography color="initial">Active for Non-Members</Typography>
                                    <Switch
                                        checked={activeNonMember}
                                        onChange={(event) => setActiveNonMember(event.target.checked)}
                                    />
                                </Stack>
                            </Stack>


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    create
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>


            {/* update Member  modal code  */}
            <div>
                <Modal
                    open={updateMemberModalOpen}
                    onClose={updateMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Update Executive Committee
                            </Typography>
                            <Button onClick={updateMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={updateMemberSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="name"
                                    size='small'
                                    value={updateHeading}
                                    onChange={e => setUpdateHeading(e.target.value)}
                                    required label="Heading" />
                            </Stack>
                            <Stack spacing={3} margin='1vmax 0'>
                                <TextField name="email"
                                    size='small'
                                    value={updateFileName}
                                    onChange={e => setUpdateFileName(e.target.value)}
                                    required label="File Name" />
                            </Stack>
                            <Stack spacing={1} margin='1vmax 0' direction={'column'} alignItems={'flex-start'} >
                                <Typography color="initial">Select News Latter Pdf</Typography>
                                <input
                                    className="inpProfileBtn"

                                    type="file"
                                    // accept=""
                                    onChange={updateHandleFileChange}
                                    name="profile"
                                    placeholder="Pdf"
                                />
                                {/* <img src={pdFile} alt="" /> */}
                            </Stack>

                            <Stack spacing={0} margin='2vmax 0' direction={'column'} justifyContent={'flex-start'}>
                                <Stack spacing={3} direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
                                    <Typography color="initial" textAlign={'right'} >Active for Members</Typography>
                                    <Switch
                                        checked={updateActiveMember}
                                        onChange={(event) => setUpdateActiveMember(event.target.checked)}
                                    />
                                </Stack>

                                <Stack spacing={3} direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
                                    <Typography color="initial">Active for Non-Members</Typography>
                                    <Switch
                                        checked={updateActiveNonMember}
                                        onChange={(event) => setUpdateActiveNonMember(event.target.checked)}
                                    />
                                </Stack>
                            </Stack>


                            {createMemberLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                    Save
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal>
            </div>





            {/* send mail  modal code  */}
            <div>
                <Modal
                    open={recoverMemberModalOpen}
                    onClose={recoverMemberModalHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Send mail notification
                            </Typography>
                            <Button onClick={recoverMemberModalHandleClose} style={{ color: 'gray' }} variant="text" >Hide</Button>
                        </Stack>

                        <Divider />

                        <form action="" onSubmit={mailSubmitHandler}>
                            <Stack spacing={3} margin='1vmax 0'>
                                <InputLabel id="demo-simple-select-label">Select Users to send mail</InputLabel>
                                <Select
                                    // labelId="demo-simple-select-label"
                                    // id="demo-simple-select"
                                    // value={role}
                                    required
                                    label="Select"
                                    onChange={e => setRole(e.target.value)}
                                >
                                    <MenuItem value={''}><em>none</em></MenuItem>
                                    <MenuItem value={'custom'}>Custom</MenuItem>
                                    <MenuItem value={'member'}>Member</MenuItem>
                                    <MenuItem value={'non-member'}>Non Member</MenuItem>
                                    <MenuItem value={'all'}>All</MenuItem>
                                </Select>
                            </Stack>

                            {
                                role === 'custom' ?
                                    <TextField
                                        fullWidth
                                        id=""
                                        sx={{ margin: '0.5vmax 0' }}
                                        label="Type Emails"
                                        required
                                        value={emails}
                                        onChange={e => setEmails(e.target.value)}

                                    /> : ''
                            }





                            {mailLoading ?

                                (<LoadingButton fullWidth size="large" variant="text" >
                                    <CircularProgress color='success' />
                                </LoadingButton>)
                                :

                                (<LoadingButton m={2} fullWidth size='medium' type="submit" variant="contained" >
                                    Send mail
                                </LoadingButton>)

                            }
                        </form>
                    </Box>
                </Modal >
            </div > 




        </>
    );
}
