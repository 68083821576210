import { GET_ALL_BOOKINGS_REQUEST } from '../constants/bookingConstant';
import {
    CREATE_NEWS_REQUEST,
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_FAIL,
    CREATE_NEWS_RESET,
   
    UPDATE_NEWS_REQUEST,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_FAIL,
    UPDATE_NEWS_RESET,
    DELETE_NEWS_REQUEST,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAIL,
    DELETE_NEWS_RESET,
    RECOVER_NEWS_REQUEST,
    RECOVER_NEWS_SUCCESS,
    RECOVER_NEWS_FAIL,
    RECOVER_NEWS_RESET,
    CLEAR_ERROR,
    GET_ALL_NEWS_SUCCESS,
    GET_ALL_NEWS_FAIL,
} from '../constants/newsConstant'


export const createNews = (state = { data: {} }, action) => {
    switch (action.type) {
        
      case UPDATE_NEWS_REQUEST:
        case CREATE_NEWS_REQUEST:
            case DELETE_NEWS_REQUEST:
                case RECOVER_NEWS_REQUEST:
        return {
          loading: true,
          success: false,
        };
  
      case UPDATE_NEWS_SUCCESS:
        case CREATE_NEWS_SUCCESS:
        case DELETE_NEWS_SUCCESS:
            case RECOVER_NEWS_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true
        };
  

      case UPDATE_NEWS_FAIL:
        case CREATE_NEWS_FAIL:
        case DELETE_NEWS_FAIL:
            case RECOVER_NEWS_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };

        case UPDATE_NEWS_RESET:
        case CREATE_NEWS_RESET:
            case RECOVER_NEWS_RESET:
                case DELETE_NEWS_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const getNews = (state = { data: {} }, action) => {
    switch (action.type) {
      case GET_ALL_BOOKINGS_REQUEST:
        return {
          loading: true,
        };
  
        case GET_ALL_NEWS_SUCCESS:
        return {
          ...state,
          loading: false,
          allNews: action.payload.allNews,
        };
  

        case GET_ALL_NEWS_FAIL:
        return {
          ...state,
          loading: false,
          members: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

