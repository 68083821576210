import axios from "axios";
import { CREATE_BOOKING_FAIL } from '../constants/bookingConstant'

import {host} from "../../utils/config";
import { CLEAR_ERROR, CREATE_QUERY_FAIL, CREATE_QUERY_REQUEST, CREATE_QUERY_SUCCESS, GET_CONTACTS_FAIL, GET_CONTACTS_REQUEST, GET_CONTACTS_SUCCESS, GET_TRACK_FAIL, GET_TRACK_REQUEST, GET_TRACK_SUCCESS } from "../constants/contactConstant";



export const getContactAction = (page,keyword="") => async (dispatch) => {

    try {
        // console.log("create Event")

        dispatch({ type: GET_CONTACTS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/web/api/v1/contacts?page=${page}&keyword=${keyword}`,  config);

        dispatch({ type: GET_CONTACTS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_CONTACTS_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getTracksAction = (page,keyword="") => async (dispatch) => {

    try {
        // console.log("create Event")

        dispatch({ type: GET_TRACK_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/user/api/v1/tracks?page=${page}&keyword=${keyword}`,  config);

        dispatch({ type: GET_TRACK_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_TRACK_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const queryAction = (details) => async (dispatch) => {

    try {
        // console.log("create Event")

        dispatch({ type: CREATE_QUERY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.post(`${host}/web/api/v1/query`, details, config);

        dispatch({ type: CREATE_QUERY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CREATE_QUERY_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};