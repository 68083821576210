import React, { useEffect, useState } from 'react'
import './eventBooking.css';
// import Typography from 'src/theme/overrides/Typography';
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ZeroState from '../components/ZeroState';
import { getEventsForPortal } from '../redux/actions/eventsAction';

const EventsBooking = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEventsForPortal())
    }, [dispatch])

    const { loading: getLoading, events, error: getErr } = useSelector((state) => state.getEvents)


    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>Events</Typography>

            {
                events?.length === 0 ? <ZeroState title={"No Events"}/> : 
                <>
                {
                events && events.map((item, i) => (
                    <div className='eventListBox'>
                        <div className="imgBox">
                            <img src={item.banner?.url} alt="" />
                        </div>
                        <div className='sponsersBox'>
                            <h3 className='eventName'>{item.event_name}</h3>

                            <h3>Sponsors : {item.sponsers}</h3>
                            {/* <div>
                                
                            </div> */}

                            {/* <Typography variant="h5" sx={{ mb: 1 }}>Events Resigistaion</Typography> */}


                            <Stack direction='row' spacing={2}>
                                {
                                    Date.now() <= new Date(events[i]?.booking_end_date) ?
                                        <Link to={`/dashboard/member-registration/${item._id}`} >
                                            <Button variant="contained" color='success' style={{ color: 'white' }} >
                                                Book Now
                                            </Button>
                                        </Link>
                                        : <Button variant="contained" color='success' disabled={'true'} style={{ color: 'white' }} >
                                            Booking Closed
                                        </Button>
                                }
                                {/* <Button variant="contained" >
                                    Forien Delegates
                                </Button> */}
                            </Stack>
                        </div>



                    </div>
                ))
            }
                </>
            }
        </>

    )
}

export default EventsBooking