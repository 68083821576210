import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';
//
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearError, loadUser } from '../../redux/actions/userAction';

import Header from './header';
import Nav from './nav';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, user, isAuthenticated, error } = useSelector((state) => state.users)

  useEffect(() => {
    dispatch(loadUser())
  }, [dispatch])

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }

    
    if(!loading){
      if (isAuthenticated === false ) { 
          navigate('/')
        }
    }
  }, [isAuthenticated, loading, navigate, dispatch, error])

  const opened = true;

  useEffect(() => {
    // ---------------------------
    const currentDate = new Date();
    const memberShipExpired = new Date(user?.memberShipExpired);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = memberShipExpired - currentDate;
    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Update the state with the difference in days
    // setDifferenceInDays(differenceInDays);
    if (user?.role !== 'admin') {

      if (differenceInDays <= 5) {
        navigate('/dashboard/renew-or-certificate')
        toast.warn(`Your Membership is expired in ${differenceInDays} days. Please Renew your Membership`)
      }
    }


  }, [user])

  return (
    <>
      {
        loading ? (<Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={opened}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>) : (
          <StyledRoot>

            <Header onOpenNav={() => setOpen(true)} />

            <Nav openNav={open} onCloseNav={() => setOpen(false)} />

            <Main>
              <Outlet />
            </Main>
          </StyledRoot>

        )
      }
    </>
  );
}
