import { Helmet } from 'react-helmet-async';
import './orgDir.css'
import { useEffect, useState } from 'react';
// @mui
import './myOrg.css'
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,


} from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';


import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdDelete, MdModeEdit, MdSettingsBackupRestore } from 'react-icons/md'

// components
import { LoadingButton } from '@mui/lab';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
// import { clearError } from '../../redux/actions/userAction';
import { createOrgDir, deleteOrgDir, getOrgDirs, recoverOrgDir, updateOrgDir, clearError, getMyOrg, getOrg } from '../../redux/actions/orgDirAction';
import { CREATE_ORG_DIR_RESET } from '../../redux/constants/orgDirConstant';
import { allUsers } from '../../redux/actions/userAction';
// import { round } from 'lodash';


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function OrgDir() {





    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { id } = useParams();

    const { loading, org: orgs, error } = useSelector((state) => state.getMyOrg)


    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }

    }, [error, navigate, dispatch])

    useEffect(() => {
        dispatch(getOrg(id))
        // console.log(orgs)

    }, [dispatch, id])

    return (
        <>
            <Helmet>
                <title> My Organization - eoai </title>
            </Helmet>

            <Container>

                <Card>
                    {
                        // orgs &&
                        <>
                            <div className='tableTop'>
                                <div hidden={!loading ? 'true' : ''}>
                                    <CircularProgress color="primary" />
                                </div>
                                <div>Organization Details</div>
                            </div>
                            <Divider />


                            <div className="orgBox">
                                <Stack direction={'row'} padding={6}>
                                    {orgs?.ownerProfile &&
                                        <div className="imgBoxLogoSide">
                                            {/* <img src={orgs?.orgLogo ? orgs?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} alt="Member" /> */}

                                            <img src={orgs?.ownerProfile} alt="Member" />
                                        </div>
                                    }
                                    <div className="orgContentBox">
                                        <div>
                                            <img src={orgs?.orgLogo ? orgs?.orgLogo : 'https://cdn.icon-icons.com/icons2/827/PNG/512/user_icon-icons.com_66546.png'} width={70} alt="LOGO" />

                                            <h5> {orgs?.orgName}</h5> <br />
                                        </div>
                                        <br />
                                        <div>
                                            <p>Membership ID  </p>
                                            <p> {orgs?.membershipId ? orgs?.membershipId : '---'}</p>

                                        </div>
                                        <div>
                                            <p>Organization Name</p>
                                            <p> {orgs?.orgName ? orgs?.orgName : '---'}</p>

                                        </div>

                                        {
                                            orgs?.orgDescription &&
                                            <div>
                                                <p>Description  </p>
                                                <p> {orgs?.orgDescription ? orgs?.orgDescription : '---'}</p>

                                            </div>
                                        }
 {
                                            orgs?.businessType &&
                                            <div>
                                                <p>Nature of Business  </p>
                                                <p> {orgs?.businessType}</p>
                                            </div>
                                        }
                                        {
                                            orgs?.products &&
                                            <div>
                                                <p>Key Products  </p>
                                                <p> {orgs?.products}</p>
                                            </div>
                                        }
                                        {
                                            orgs?.orgCountry &&
                                            <div>
                                                <p> Location  </p>
                                                <p> {orgs?.orgCountry}</p>
                                            </div>
                                        }
 {
                                            orgs?.orgWebsite &&
                                            <div>
                                                <p>Website  </p>
                                                <p> {orgs?.orgWebsite}</p>
                                            </div>
                                        }
                                        {
                                            orgs?.orgEmail &&
                                            <div>
                                                <p>Email  </p>
                                                <p> {orgs?.orgEmail ? orgs?.orgEmail : '-'}</p>
                                            </div>
                                        }
                                       
                                       

                                        
                                        {/* <Divider /> */}


                                        {
                                            orgs?.telephone &&
                                            <div>
                                                <p>Contact  </p>
                                                <p> {orgs?.telephone ? orgs?.telephone : '---'}</p>

                                            </div>
                                        }

                                        {
                                            orgs?.GSTIN &&
                                            <div>
                                                <p>GSTIN  </p>
                                                <p> {orgs?.GSTIN ? orgs?.GSTIN : '---'}</p>

                                            </div>
                                        }
                                        {/* {
                                            orgs?.status &&
                                            <div>
                                                <p>Status  </p>
                                                <p> {orgs?.status ? orgs?.status : '---'}</p>

                                            </div>
                                        } */}

                                       


                                        <br />
                                        <Typography color="initial">Contact Person</Typography>

                                        {
                                            orgs?.ownerName &&
                                            <div>
                                                <p>Name  </p>
                                                <p> {orgs?.ownerName ? orgs?.ownerName : '---'}</p>
                                            </div>
                                        }
                                        {
                                            orgs?.ownerEmail &&
                                            <div>
                                                <p>Email  </p>
                                                <p> {orgs?.ownerEmail ? orgs?.ownerEmail : '---'}</p>
                                            </div>
                                        }
                                        {
                                            orgs?.ownerPhone &&
                                            <div>
                                                <p>Phone  </p>
                                                <p> {orgs?.ownerPhone ? orgs?.ownerPhone : '---'}</p>

                                            </div>
                                        }
                                        {
                                            orgs?.LinkedIn &&
                                            <div>
                                                <p>LinkedIn  </p>
                                                <p> {orgs?.LinkedIn ? orgs?.LinkedIn : '---'}</p>

                                            </div>
                                        }


                                    </div>
                                </Stack>

                                {/* <div className="actionBox"> */}
                                {/* <p className='center'> Active  :  <div className={orgs?.deleteAt ? 'unlive' : 'live'}> </div></p> */}
                                {/* <div className="dateBox">
                                        <p>Create : {orgs?.createAt}</p>
                                    </div> */}


                                {/* </div> */}

                            </div>
                        </>

                    }
                </Card>
            </Container>







        </>
    );
}
