import { InputLabel, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './EventDetails.css'
import { HiChevronLeft } from 'react-icons/hi'

import { Link, useNavigate, useParams } from 'react-router-dom'

import { getEvent } from '../redux/actions/eventsAction'


const EventDetail = ({event}) => {


  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getEvent(id))
  // }, [dispatch, id])

  // const { loading: getLoading, event, error: getErr } = useSelector((state) => state.getEvents)

  return (
    <div className='bgColor'>
      {/* <Stack direction='row' padding={1} className='BackBox' >
        <Link to={-1} className='backBtn'><HiChevronLeft /></Link>
      </Stack> */}


      <Stack className='eventHeading' alignItems={'center'} padding={3}>
        <Typography variant="h3" color="initial"> Event Details</Typography>
        <Typography variant="h5" color="primary"> {event?.event_name}</Typography>
      </Stack>

      <div className="imgBanner">
        <img src={event?.banner.url} alt="Event" />
      </div>

      <Stack direction={'row'} padding={'1vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'}>
        <Stack spacing={2} padding={'1vmax'} direction={'column'} alignItems={'top'} justifyContent={'top'}>
          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Event Date</Typography>
            <Typography variant="h6" color="primary">{event?.event_date}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Booking End (Close) Date</Typography>
            <Typography variant="h6" color="primary">{event?.booking_end_date}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">active For Member</Typography>
            <Typography variant="h6" color="primary">{event?.activeForMember.toString()}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">active For Website</Typography>
            <Typography variant="h6" color="primary">{event?.activeForWebsite.toString()}</Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} padding={'1vmax '} direction={'column'} alignItems={'top'} justifyContent={'top'}>
          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">MarketingStatus</Typography>
            <Typography variant="h6" color="primary">{event?.MarketingStatus}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Sponsors</Typography>
            <Typography variant="h6" color="primary">{event?.sponsers}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Total Seats</Typography>
            <Typography variant="h6" color="primary">{event?.totalSeats}</Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} padding={'1vmax'} direction={'column'} alignItems={'top'} justifyContent={'top'}>
        <InputLabel id="demo-controlled-open-select-label">Indian Delegates</InputLabel>
          
          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Member Price</Typography>
            <Typography variant="h6" color="primary"> ₹ {event?.memberPrice}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Non Member Price</Typography>
            <Typography variant="h6" color="primary"> ₹ {event?.nonMemberPrice}</Typography>
          </Stack>

          {
            event?.spouse && 
            <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Spouse</Typography>
            <Typography variant="h6" color="primary"> ₹ {event?.spouse}</Typography>
          </Stack>

          }
         

         {
          event?.child2Price && 
          <Stack direction={'row'} spacing={2}>
          <Typography variant="h6" color="initial">child1Price</Typography>
          <Typography variant="h6" color="primary"> ₹ {event?.child1Price}</Typography>
        </Stack>

         }
          {
            event?.child2Price &&
            <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">child2Price</Typography>
            <Typography variant="h6" color="primary"> ₹ {event?.child2Price}</Typography>
          </Stack>
          }

          
        </Stack>

        <Stack>
        <InputLabel id="demo-controlled-open-select-label">Member Scientist</InputLabel>
        <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Member Scientist</Typography>
            <Typography variant="h6" color="primary"> ₹ {event?.memberScientist}</Typography>
          </Stack>

          <InputLabel id="demo-controlled-open-select-label">Foreign Delegates</InputLabel>
          
          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6" color="initial">Foreign Price</Typography>
            <Typography variant="h6" color="primary">{event?.ForeignPrice} $</Typography>
          </Stack>
        </Stack>
      </Stack>

    {/* <Stack direction={'row'} padding={'4vmax 6vmax'} spacing={4} alignItems={'top'} justifyContent={'center'} margin={'6vmax'} borderRadius={2} border={1}>
          <Typography variant="h3" color="initial">Currently No Booking</Typography>
    </Stack> */}



    </div>
  )
}

export default EventDetail