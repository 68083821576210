
import { CLEAR_ERROR, GET_BOOKING_PAYMENT_FAIL, GET_BOOKING_PAYMENT_REQUEST, GET_BOOKING_PAYMENT_RESET, GET_BOOKING_PAYMENT_SUCCESS, GET_RENEW_ALL_PAYMENT_FAIL, GET_RENEW_ALL_PAYMENT_REQUEST, GET_RENEW_ALL_PAYMENT_SUCCESS, GET_RENEW_PAYMENT_DETAILS_FAIL, GET_RENEW_PAYMENT_DETAILS_REQUEST, GET_RENEW_PAYMENT_DETAILS_SUCCESS } from '../constants/paymentConstant';


export const getPaymentReducers = (state = { data: {} }, action) => {
    switch (action.type) {

        case GET_RENEW_PAYMENT_DETAILS_REQUEST:
        case GET_RENEW_ALL_PAYMENT_REQUEST:
            return {
                loading: true,
            };

        case GET_RENEW_PAYMENT_DETAILS_SUCCESS:

            return {
                ...state,
                loading: false,
                payment: action.payload.payment,

            };
        case GET_RENEW_ALL_PAYMENT_SUCCESS:

            return {
                ...state,
                loading: false,
                payments: action.payload.payments,
            };


        case GET_RENEW_PAYMENT_DETAILS_FAIL:
        case GET_RENEW_ALL_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const bookingPaymentReducers = (state = { data: {} }, action) => {
    switch (action.type) {

        case GET_BOOKING_PAYMENT_REQUEST:
            return {
                loading: true,
                success: false,

            };


        case GET_BOOKING_PAYMENT_SUCCESS:

            return {
                ...state,
                loading: false,
                success: true,
                formData: action.payload.formData,
            };


        case GET_BOOKING_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,

                error: action.payload,
            };

        case GET_BOOKING_PAYMENT_RESET:

            return {
                ...state,
                loading: false,
                success: false,
                formData: null,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
